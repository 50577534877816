import produce from "immer";
import { ADD_API_MESSAGE, REMOVE_ITEM } from "./types";

const initialState = {
  items: [],
};

const messageBarReducer = (state = initialState, action) => {
  const { type, payload } = action;

  return produce(state, (draft) => {
    switch (type) {
      case ADD_API_MESSAGE:
        draft.items.push(payload);
        return draft;
      case REMOVE_ITEM:
        draft.items = draft.items.filter((item) => item.key !== payload);
        return draft;
      default:
        return draft;
    }
  });
};

export default messageBarReducer;

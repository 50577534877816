import styled from "styled-components";

export const InputsContainer = styled.div`
  display: grid;
  grid-row-gap: 20px;
`;

export const Form = styled.form`
  width: 100%;
`;

import React, { useEffect } from "react";
import * as Styled from "./styled";
import PropTypes from "prop-types";
import { CalendarField, TextFieldSet, Button } from "@6tamp/uikit";
import { useDispatch, useSelector } from "react-redux";
import {
  filterClients,
  prefilterClients,
} from "../../../store/clients/actions";
import { clearFields, setField } from "../../../store/filter/actions";
import { useTranslation } from "react-i18next";
import i18n from "../../../localization/i18n";

const FilterTypes = Object.freeze({
  DATE: Symbol("date"),
  COUNT: Symbol("count"),
});

const FilterBar = ({ isOpen, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fields = useSelector(({ filter }) => filter.fields);
  const count = useSelector(({ clients }) => clients.prefilteredClients.length);

  const filters = [
    {
      label: t("views.clients.filters_titles.first_visit"),
      type: FilterTypes.DATE,
      fieldName: "firstVisit",
    },
    {
      label: t("views.clients.filters_titles.last_visit"),
      type: FilterTypes.DATE,
      fieldName: "lastVisit",
    },
    {
      label: t("views.clients.filters_titles.cur_gifts"),
      type: FilterTypes.COUNT,
      fieldName: "curGifts",
    },
    {
      label: t("views.clients.filters_titles.received_gifts"),
      type: FilterTypes.COUNT,
      fieldName: "receivedGifts",
    },
    {
      label: t("views.clients.filters_titles.total_stamps"),
      type: FilterTypes.COUNT,
      fieldName: "totalStamps",
    },
  ];

  useEffect(() => {
    dispatch(prefilterClients(fields));
  }, [fields]);

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    dispatch(filterClients());
    close();
  };

  const handleReset = () => dispatch(clearFields());

  return (
    <Styled.FilterBar isOpen={isOpen} onClickOutside={close}>
      <Styled.Form onSubmit={handleSubmitFilter} onReset={handleReset}>
        <div>
          <Styled.ContentHeader>
            <Styled.HeaderTitle>
              {t("views.clients.filters_header")}
            </Styled.HeaderTitle>
            <Button
              type={"reset"}
              view={"soft"}
              hasBorder={false}
              text={t("views.clients.btn_clear_filters")}
              disabled={Object.values(fields).reduce(
                (acc, cur) => acc && !cur.range[0] && !cur.range[1],
                true
              )}
            />
          </Styled.ContentHeader>

          <Styled.ContentBody>
            {filters.map(({ type, label, fieldName }, index) => (
              <Styled.FilterWrapper key={`${label}_${index}`}>
                <Styled.FilterTitle>{label}</Styled.FilterTitle>
                <Styled.FilterInputWrapper>
                  {(() => {
                    const { COUNT, DATE } = FilterTypes;
                    switch (type) {
                      case COUNT:
                        return (
                          <TextFieldSet
                            width={"full"}
                            values={fields[fieldName].range}
                            setValues={(values) =>
                              dispatch(setField(fieldName, values))
                            }
                            leftLabel={t("core.prepositions.from")}
                            rightLabel={t("core.prepositions.to")}
                          />
                        );
                      case DATE:
                        return (
                          <CalendarField
                            width={"full"}
                            dates={fields[fieldName].range}
                            placeholder={t(
                              "core.inputs_defs.date.placeholder_doubled"
                            )}
                            setDates={(dates) =>
                              dispatch(setField(fieldName, dates))
                            }
                            locale={i18n.language}
                          />
                        );
                      default:
                        throw new TypeError();
                    }
                  })()}
                </Styled.FilterInputWrapper>
              </Styled.FilterWrapper>
            ))}
          </Styled.ContentBody>
        </div>
        <div>
          <Button
            text={`${t("views.clients.btn_filter_clients")}: ${count}`}
            round={true}
            type={"submit"}
          />
        </div>
      </Styled.Form>
    </Styled.FilterBar>
  );
};

export default FilterBar;

FilterBar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

import styled from "styled-components";
import * as PerStyled from "../styled";

export const ContentTop = styled.div`
  margin-bottom: 34px;
  width: 100%;
  height: auto;
`;
export const TableBlock = styled.div`
  margin: 20px 0 0;

  width: 100%;
`;

export const ContentBottom = styled.div``;

export const Tabs = styled(PerStyled.Tabs)`
  margin: 0 0 40px;
`;

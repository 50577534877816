import React from "react";
import { setConfiguration } from "react-grid-system";
import * as Styled from "./styled";
import Sidebar from "../Sidebar";
import * as PerStyled from "../styled";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

setConfiguration({ defaultScreenClass: "sm", gridColumns: 12 });

const PageWrapper = ({ children }) => {
  const items = useSelector(({ messageBar }) => messageBar.items);

  return (
    <Styled.PageWrapper>
      <Styled.PageRow>
        <Sidebar />
        <Styled.Workspace>{children}</Styled.Workspace>
        <PerStyled.MessageBar items={items} />
      </Styled.PageRow>
    </Styled.PageWrapper>
  );
};

export default PageWrapper;

PageWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

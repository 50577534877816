import {
  LOAD_USERS,
  RESET_FILTERED_LIST,
  SET_FILTERED_LIST,
  SET_LOADING,
  SET_SKELETON,
  SET_WORKER,
} from "./types";
import { apiService, getRoleNameById } from "../../utils";
import i18n from "../../localization/i18n";

const setSkeleton = (isSkeleton, mode) => {
  return { type: SET_SKELETON, payload: { mode, value: isSkeleton } };
};

const setLoading = (isLoading) => {
  return { type: SET_LOADING, payload: isLoading };
};

export const search = (value, mode) => {
  return (dispatch, getState) => {
    if (value) {
      const { loadedUsers, searchIndex } = getState().workers[mode];

      const indexVariants = Object.keys(searchIndex)
        .filter((v) => v.includes(value.toLowerCase()))
        .map((filteredIndex) => searchIndex[filteredIndex]);
      const uniqueIndexes = [...new Set(indexVariants)];

      dispatch({
        type: SET_FILTERED_LIST,
        payload: {
          mode,
          data: uniqueIndexes.map((index) => loadedUsers[index]),
        },
      });
    } else {
      dispatch({ type: RESET_FILTERED_LIST, payload: { mode } });
    }
  };
};

export const createUser = (fields) => async (dispatch) => {
  try {
    fields.confirmed = true;
    const location = fields.location;
    delete fields.location;
    dispatch(setLoading(true));
    const { data } = await apiService.post("/user/signup", fields);
    await apiService.post(`/location/${location}/bindUser`, {
      userId: data.id,
    });
    dispatch(setLoading(false));
    return { result: true, msg: i18n.t("core.actions_msgs.worker_created") };
  } catch (e) {
    dispatch(setLoading(false));
    if (e.isAxiosError) {
      switch (e.response?.status) {
        case 400:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.email_already_exists"),
          };
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const updateUser = (userId, fields) => async (dispatch) => {
  try {
    const location = fields.location;
    delete fields.location;
    dispatch(setLoading(true));
    await apiService.put(`/user/${userId}`, fields);
    await apiService.post(`/location/${location}/bindUser`, {
      userId,
    });
    dispatch(setLoading(false));
    return { result: true, msg: i18n.t("core.actions_msgs.worker_updated") };
  } catch (e) {
    dispatch(setLoading(false));
    if (e.isAxiosError) {
      switch (e.response?.status) {
        case 400:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.email_already_exists"),
          };
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await apiService.delete(`/user/${userId}`);
    dispatch(setLoading(false));
    return { result: true };
  } catch (e) {
    dispatch(setLoading(false));
    if (e.isAxiosError) {
      switch (e.response?.status) {
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const loadUsers = (token) => async (dispatch) => {
  try {
    dispatch(setSkeleton(true, "active"));
    dispatch(setSkeleton(true, "trash"));
    dispatch(setLoading(true));

    await Promise.all([
      apiService
        .get("/user", {
          cancelToken: token,
        })
        .then(({ data }) => {
          data.forEach((v) => (v.type = getRoleNameById(v.type, i18n.t)));
          dispatch({
            type: LOAD_USERS,
            payload: {
              mode: "active",
              data,
            },
          });
          dispatch(setSkeleton(false, "active"));
        }),
      apiService
        .get("/user?zombie=true", {
          cancelToken: token,
        })
        .then(({ data }) => {
          data.forEach((v) => (v.type = getRoleNameById(v.type, i18n.t)));
          dispatch({
            type: LOAD_USERS,
            payload: {
              mode: "trash",
              data,
            },
          });
          dispatch(setSkeleton(false, "trash"));
        }),
    ]);

    dispatch(setLoading(false));
    return { result: true };
  } catch (e) {
    dispatch(setSkeleton(false, "active"));
    dispatch(setSkeleton(false, "trash"));
    dispatch(setLoading(false));

    if (e?.constructor.name === "Cancel") {
      return { result: false, msg: e.message };
    } else if (e.isAxiosError) {
      switch (e.response?.status) {
        case 400:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.workers_fetch_error"),
          };
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const restoreUsers = (userIds) => async (dispatch) => {
  const success =
    userIds.length === 1
      ? i18n.t("core.actions_msgs.worker_restored")
      : i18n.t("core.actions_msgs.workers_restored");
  try {
    dispatch(setLoading(true));
    const { data } = await apiService.post("/user/trash/restore", { userIds });
    dispatch(setLoading(false));
    return {
      result: data.result,
      msg: data.result ? success : i18n.t("core.actions_msgs.server_error"),
    };
  } catch (e) {
    dispatch(setLoading(false));
    return { result: false, msg: i18n.t("core.actions_msgs.server_error") };
  }
};

export const setWorker = (worker) => (dispatch) => {
  dispatch({
    type: SET_WORKER,
    payload: worker,
  });
};

export const getWorkerStat = (id, from, to) => async (dispatch) => {
  if (!from || !to) {
    return [{}, {}];
  }
  try {
    dispatch(setLoading(true));
    dispatch(setSkeleton(true, "worker"));

    const stampsActionsResponse = await apiService.get(
      `/user/${id}/actions/sorted?dateFrom=${from}&dateTo=${to}&type=0`
    );
    const giftsActionsResponse = await apiService.get(
      `/user/${id}/actions/sorted?dateFrom=${from}&dateTo=${to}&type=1`
    );

    dispatch(setSkeleton(false, "worker"));
    dispatch(setLoading(false));

    return [stampsActionsResponse.data, giftsActionsResponse.data];
  } catch (e) {
    dispatch(setSkeleton(false, "worker"));
    dispatch(setLoading(false));
  }
};

export const getWorkerInfo = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(setSkeleton(true, "worker"));

    const { data } = await apiService.get(`/user/info/${id}`);
    dispatch(setWorker({ id, name: data.name, lastName: data.lastName }));

    dispatch(setLoading(false));
    dispatch(setSkeleton(false, "worker"));
  } catch (e) {
    dispatch(setLoading(false));
    dispatch(setSkeleton(false, "worker"));
  }
};

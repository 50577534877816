import styled from "styled-components";

export const PeriodSelect = styled.div`
  width: auto;
  height: 32px;

  display: flex;
  align-items: center;
  gap: 20px;
`;

export const DatePick = styled.div`
  position: relative;
`;

export const PresetsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const OR = styled.div`
  height: 24px;

  font-weight: normal;
  font-size: 16px;
  line-height: 150%;

  text-align: center;

  color: ${({ theme }) => theme.typo.ghost};
`;

export const CalendarPeriod = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  text-align: center;

  color: ${({ theme }) => theme.main["100%"]};
  &:hover {
    color: ${({ theme }) => theme.main.hover};
  }
  span {
    border-bottom: 1px dashed ${({ theme }) => theme.main["100%"]};
  }

  white-space: nowrap;

  cursor: pointer;
`;

export const CalendarWrapper = styled.div`
  position: absolute;
  right: 100px;
  top: 30px;
`;

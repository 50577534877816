import React, { useEffect, useState } from "react";
import * as Styled from "./styled";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../../hooks";
import { useTranslation } from "react-i18next";
import i18n from "../../../localization/i18n";
import { LanguageSelector } from "../../index";
import { IETFDictionary, resources } from "../../../localization/i18n";

const selectorValues = Object.keys(resources).map((tag, index) => ({
  id: index + 1,
  label: IETFDictionary[tag],
  tag,
}));

function getSelectorObject(tag) {
  return selectorValues.find((el) => el.tag === tag) || selectorValues[0];
}

function useLangSelectorState() {
  const [currentLang, setCurrentLang] = useState(
    getSelectorObject(i18n.language)
  );
  return [currentLang, ({ value }) => setCurrentLang(value), selectorValues];
}

const Background = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [lang, setLang, items] = useLangSelectorState();
  const onClickLogo = () => history.push(routePaths.default);

  useEffect(() => {
    i18n.changeLanguage(lang.tag);
  }, [lang]);

  return (
    <Styled.Background>
      <Styled.LeftSide>
        <Styled.Logo onClick={onClickLogo} />

        <Styled.BlueFigure />
        <Styled.OrangeFigure />
        <Styled.VioletFigure />
        <Styled.YellowFigure />

        <Styled.Welcome>
          <h1>{t("views.auth_wrapper.text_title")}</h1>
          <h3>{t("views.auth_wrapper.text_description")}</h3>
        </Styled.Welcome>
      </Styled.LeftSide>
      <Styled.RightSide>
        <Styled.RightSideInner>
          <Styled.LanguageSelectorWrapper>
            <LanguageSelector
              lang={lang}
              setLang={setLang}
              items={items}
              placeholder={t("core.inputs_defs.language.label")}
            />
          </Styled.LanguageSelectorWrapper>
        </Styled.RightSideInner>
      </Styled.RightSide>
    </Styled.Background>
  );
};

export default Background;

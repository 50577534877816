import React, { useCallback, useEffect, useState } from "react";
import * as Styled from "./styled";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import axios from "axios";
import { getCardData } from "../../../store/settings/actions";
import Icon from "../Icon";
import { useTranslation } from "react-i18next";

const PushDisplay = ({ companyName, text, maxSymbols }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [iconBase64, setIconBase64] = useState("");

  const limit = useCallback(
    (string) =>
      string.length > maxSymbols
        ? `${string.substring(0, maxSymbols)}...`
        : string,
    [maxSymbols]
  );

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetch = async () => {
      const cardData = await dispatch(getCardData(source.token));
      if (cardData?.fields?.template?.images?.icon) {
        setIconBase64(cardData.fields.template.images.icon);
      }
    };
    fetch();
    return () => {
      source.cancel();
    };
  }, []);

  return (
    <Styled.PushDisplay>
      <Styled.Header>
        <Styled.LeftSideWrapper>
          <Icon base64={iconBase64} />
          <Styled.Title>6TAMP | WALLET</Styled.Title>
        </Styled.LeftSideWrapper>
        <div>{t("views.clients.push_display_now")}</div>
      </Styled.Header>

      <Styled.PushText>
        {!!text ? `${companyName}: ${limit(text)}` : ""}
      </Styled.PushText>
    </Styled.PushDisplay>
  );
};

export default PushDisplay;

PushDisplay.defaultProps = {
  text: "",
  companyName: "6tamp",
  maxSymbols: 65,
};

PushDisplay.propTypes = {
  text: PropTypes.string,
  companyName: PropTypes.string,
  maxSymbols: PropTypes.number,
};

import styled from "styled-components";

export const Price = styled.span`
  margin: 0 5px;
  color: ${({ theme }) => theme.typo.ghost};
`;
export const Discount = styled.span`
  color: ${({ theme }) => theme.success};
`;

export const Link = styled.a`
  display: inline-flex;

  width: 100%;
  max-width: 240px;
  height: 40px;

  border-radius: 20px;

  background-color: ${({ theme }) => theme.accent.default};
  color: ${({ theme }) => theme.white.primary};
  text-decoration: none;

  &:hover {
    background-color: ${({ theme }) => theme.accent.hover};
  }

  div {
    margin: auto;
  }
`;

export const Form = styled.form`
  width: 100%;
`;

import styled from "styled-components";
import { TextField } from "@consta/uikit/TextField";

export const Input = styled(TextField).attrs(({ width }) => ({
  width: width || "full",
}))`
  background-color: ${(props) => props.theme.white.primary};

  &:hover {
    border-color: ${(props) => props.theme.border.hover} !important;
  }

  &:focus-within {
    border-color: ${(props) => props.theme.main["100%"]} !important;
  }

  ${({ width }) =>
    width && width !== "full"
      ? `
      width: ${width}%;
      margin-left: auto; 
      margin-right: auto;
      :first-child{
        margin-left: initial;
      }
      :last-child{
        margin-right: initial;
      }
      `
      : null}

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const ErrorMessage = styled.div`
  margin: 4px 11px 0;

  font-size: 12px;
  line-height: 12px;
  font-weight: 400;

  color: ${(props) => props.theme.error};
`;

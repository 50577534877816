import { produce } from "immer";
import { SET_PRESET, SET_FIELD, CLEAR_FIELDS } from "./types";

export const fieldTypes = Object.freeze({
  Value: Symbol("value"),
  Date: Symbol("date"),
});

const initialState = {
  fields: {
    firstVisit: {
      type: fieldTypes.Date,
      range: [null, null],
    },
    lastVisit: {
      type: fieldTypes.Date,
      range: [null, null],
    },
    curGifts: {
      type: fieldTypes.Value,
      range: [null, null],
    },
    receivedGifts: {
      type: fieldTypes.Value,
      range: [null, null],
    },
    totalStamps: {
      type: fieldTypes.Value,
      range: [null, null],
    },
  },
  isPreset: false,
};

const filterReducer = (state = initialState, action) => {
  const { type, payload } = action;
  return produce(state, (draft) => {
    switch (type) {
      case SET_PRESET:
        draft.fields = payload;
        draft.isPreset = true;
        return draft;

      case SET_FIELD:
        draft.fields[payload.name].range = payload.values;
        draft.isPreset = false;
        return draft;

      case CLEAR_FIELDS:
        draft = initialState;
        return draft;
    }
  });
};

export default filterReducer;

import styled from "styled-components";

export const Content = styled.div`
  width: auto;
  height: auto;

  display: flex;

  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;

  align-items: center;

  color: ${({ theme }) => theme.white.secondary};
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  text-align: center;

  color: ${({ theme }) => theme.typo.primary};
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: ${({ align }) => align};

  color: ${({ theme }) => theme.typo.secondary};
`;

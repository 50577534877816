import React from 'react';
import PropTypes from 'prop-types';

import * as AuthStyled from '../styled';
import AuthorizationBackground from '../AuthorizationBackground';

const AuthorizationPageWrapper = ({ children }) => {
  return (
    <div>
      <AuthorizationBackground />
      <AuthStyled.FormWrapper>{children}</AuthStyled.FormWrapper>
    </div>
  );
};

AuthorizationPageWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthorizationPageWrapper;

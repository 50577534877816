import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  SignIn,
  SignUp,
  Recovery,
  SignUpVerification,
  Clients,
  Workers,
  Locations,
  Settings,
  WorkerStat,
  Statistics,
} from "../containers";
import { useSelector } from "react-redux";
import { accountTypes } from "../utils";

export const routePaths = {
  default: "/",
  signIn: "/sign_in",
  signUp: "/sign_up",
  recovery: "/recovery",
  signUpVerification: "/sign_up/verification",
  statistics: "/statistics",
  clients: "/clients",
  workers: "/workers",
  workerStat: "/workers/:id",
  cardSettings: "/card_settings",
  locations: "/locations",
  accountSettings: "/account_settings",
  logout: "/logout",
};

const arrRoutes = {
  forUnauthorized: [
    {
      path: routePaths.signIn,
      component: SignIn,
      default: true,
    },
    {
      path: routePaths.signUp,
      component: SignUp,
    },
    {
      path: routePaths.signUpVerification,
      component: SignUpVerification,
    },
    {
      path: routePaths.recovery,
      component: Recovery,
    },
  ],
  forAuthorized: [
    {
      path: routePaths.statistics,
      component: Statistics,
      default: true,
      availableFor: [accountTypes.MANAGER, accountTypes.OWNER],
    },
    {
      path: routePaths.clients,
      component: Clients,
      availableFor: [accountTypes.MANAGER, accountTypes.OWNER],
    },
    {
      path: routePaths.workers,
      component: Workers,
      availableFor: [accountTypes.MANAGER, accountTypes.OWNER],
    },
    {
      path: routePaths.workerStat,
      component: WorkerStat,
      availableFor: [accountTypes.MANAGER, accountTypes.OWNER],
    },
    {
      path: routePaths.accountSettings,
      component: Settings,
      availableFor: [accountTypes.OWNER],
    },
    {
      path: routePaths.locations,
      component: Locations,
      availableFor: [accountTypes.OWNER, accountTypes.MANAGER],
    },
  ],
};

const Routes = ({ curRoutes, defaultRoute }) => (
  <Switch>
    {curRoutes.map((v, k) => (
      <Route
        key={`${k}_${v.path}`}
        path={v.path}
        component={v.component}
        exact
      />
    ))}
    {defaultRoute ? <Redirect to={defaultRoute.path} /> : null}
  </Switch>
);

const useRoutes = () => {
  const [routes, setRoutes] = useState(null);
  const [isAuthorized, accountType] = useSelector(({ auth }) => [
    auth.jwt && auth.confirmed,
    auth.accountType,
  ]);

  useEffect(() => {
    const curRoutes = isAuthorized
      ? arrRoutes.forAuthorized.filter(({ availableFor }) =>
          availableFor.includes(accountType)
        )
      : arrRoutes.forUnauthorized;
    const defaultRoute = curRoutes.find((v) => v.default);
    setRoutes(<Routes curRoutes={curRoutes} defaultRoute={defaultRoute} />);
  }, [isAuthorized, accountType]);

  return routes;
};

export default useRoutes;

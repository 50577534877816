import React, { useEffect, useState } from "react";
import * as Styled from "../../components/Personal/Statistics/styled";
import * as PerStyled from "../../components/Personal/styled";
import { PageWrapper, PeriodSelect } from "../../components";
import { Chart, EmptyPage, InfoCard } from "@6tamp/uikit";
import { format, isValid, parse, sub } from "date-fns";
import { loadLocations } from "../../store/locations/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getClientStat } from "../../store/clients/actions";
import { accountTypes } from "../../utils";
import { useTranslation } from "react-i18next";

const form = (date) => {
  return isValid(date) ? format(date, "yyyy-MM-dd") : "";
};

const wrapData = (fetchedData, key) => {
  const wrappedData = [];
  for (let x in fetchedData) {
    const day = {};
    day.date = format(parse(x, "yyyy-MM-dd", new Date()), "dd.MM");
    day[key] = fetchedData[x];
    wrappedData.push(day);
  }
  return wrappedData;
};

const Statistics = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const periodPresets = [
    {
      id: "week",
      label: t("views.statistics.presets_labels.week"),
      dateFrom: form(sub(new Date(), { days: 7 })),
    },
    {
      id: "month",
      label: t("views.statistics.presets_labels.month"),
      dateFrom: form(sub(new Date(), { days: 30 })),
    },
    {
      id: "threeMonths",
      label: t("views.statistics.presets_labels.threeMonths"),
      dateFrom: form(sub(new Date(), { days: 90 })),
    },
  ];

  const navStates = Object.freeze({
    Stamps: {
      id: "stamps",
      description: t("views.statistics.navbar_labels.stamps"),
    },
    Gifts: {
      id: "gifts",
      description: t("views.statistics.navbar_labels.gifts"),
    },
    Clients: {
      id: "clients",
      description: t("views.statistics.navbar_labels.clients"),
    },
  });

  const statModes = [
    {
      id: "stamps",
      title: t("views.statistics.chart_modes_defs.stamps.title"),
      type: 0,
      valueDesc: t("views.statistics.chart_modes_defs.stamps.desc"),
    },
    {
      id: "gifts",
      title: t("views.statistics.chart_modes_defs.gifts.title"),
      type: 1,
      valueDesc: t("views.statistics.chart_modes_defs.gifts.desc"),
    },
    {
      id: "clients",
      title: t("views.statistics.chart_modes_defs.clients.title"),
      type: 3,
      valueDesc: t("views.statistics.chart_modes_defs.clients.desc"),
    },
  ];

  const [nav, setNav] = useState(navStates.Stamps);
  const [selectedPreset, setSelectedPreset] = useState(periodPresets[0]);
  const [calendarDates, setCalendarDates] = useState([null, null]);
  const [statMode, setStatMode] = useState(statModes[0]);
  const [statCount, setStatCount] = useState(0);
  const [statData, setStatData] = useState([]);
  const [location, setLocation] = useState({
    label: t("views.statistics.dropdown_init_label"),
  });

  const locations = useSelector(({ locations }) =>
    [{ label: t("views.statistics.dropdown_init_label") }].concat(
      locations.active.loadedLocations.map((l) => ({
        id: l._id,
        label: l.title,
      }))
    )
  );
  const isSkeleton = useSelector(
    ({ locations, clients }) => locations.isLoading || clients.isSkeleton
  );
  const accountType = useSelector(({ auth }) => auth.accountType);

  useEffect(() => {
    setStatMode(statModes.find((obj) => obj.id === nav.id));
  }, [nav]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetch = async () => {
      await dispatch(loadLocations(source.token));
      const data = !!Object.keys(selectedPreset).length
        ? await dispatch(
            getClientStat(
              selectedPreset.dateFrom,
              form(new Date()),
              statMode.type,
              location?.id
            )
          )
        : !!calendarDates[1]
        ? await dispatch(
            getClientStat(
              form(calendarDates[0]),
              form(calendarDates[1]),
              statMode.type,
              location?.id
            )
          )
        : {};
      setStatData(wrapData(data, statMode.id));
      setStatCount(
        Object.values(!!data ? data : {}).reduce((acc, cur) => acc + cur, 0)
      );
    };
    fetch();
    return () => {
      source.cancel();
    };
  }, [selectedPreset, statMode, location, calendarDates]);

  return (
    <PageWrapper>
      <PerStyled.Workspace>
        <Styled.Header>{t("views.statistics.text_header")}</Styled.Header>
        {accountType !== accountTypes.MANAGER && (
          <Styled.LocationSelectWrapper>
            <PerStyled.Select
              value={location}
              onChange={({ value }) => setLocation(value)}
              placeholder={t("views.statistics.dropdown_placeholder")}
              items={locations}
            />
          </Styled.LocationSelectWrapper>
        )}
        <Styled.PeriodWrapper>
          <PeriodSelect
            presets={periodPresets}
            selectedPreset={selectedPreset}
            setSelectedPreset={setSelectedPreset}
            dates={calendarDates}
            setDates={setCalendarDates}
          />
        </Styled.PeriodWrapper>
        <Styled.Content>
          <Styled.TabsWrapper>
            <PerStyled.Tabs
              value={nav}
              onChange={({ value }) => setNav(value)}
              items={Object.values(navStates)}
              getLabel={(item) => item.description}
              role={"secondary"}
              fitMode={"scroll"}
            />
          </Styled.TabsWrapper>
          {!!statData.length || isSkeleton ? (
            <>
              <Styled.InfoCardsWrapper>
                <InfoCard
                  title={statMode.title}
                  value={statCount}
                  isSkeleton={isSkeleton}
                />
              </Styled.InfoCardsWrapper>
              <Chart
                data={statData}
                xKey={"date"}
                yKey={statMode.id}
                header={statMode.title}
                valueDesc={statMode.valueDesc}
                type={"monotone"}
                width={"100%"}
                skeletonWidth={709}
                isSkeleton={isSkeleton}
              />
            </>
          ) : (
            <Styled.EmptyPageWrapper>
              <EmptyPage
                variant={"NotFound"}
                header={t("views.statistics.empty_page_header")}
                description={t("views.statistics.empty_page_desc")}
              />
            </Styled.EmptyPageWrapper>
          )}
        </Styled.Content>
      </PerStyled.Workspace>
    </PageWrapper>
  );
};

export default Statistics;

import React, { useEffect, useState } from "react";
import * as Styled from "../../components/Personal/CardSettings/styled";
import * as GStyled from "../../components/styled";
import * as SettingsStyled from "../../components/Personal/Settings/styled";
import { Display, TagIconQuestion, TourWrapper } from "../../components";
import { Skeleton, ColorField, Button, UploadField } from "@6tamp/uikit";
import { updateCardData } from "../../store/settings/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { addApiMessage } from "../../store/messageBar/actions";
import { getDimensions } from "../../utils";
import { validation } from "../../utils";
import { dataURLtoFile, toBase64 } from "../../utils";
import * as PerStyled from "../../components/Personal/styled";
import { stepsIds } from "../../components/Personal/Onboarding/Onboarding";
import { useTranslation } from "react-i18next";

const CardSettings = ({ data, updateData, maxStamps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [miniLogoWidth, setMiniLogoWidth] = useState(null);

  const isSkeleton = useSelector(({ settings }) => settings.card.isSkeleton);

  const uploadFields = [
    {
      label: "pushImage",
      description: t("views.settings.fields_labels.push_image"),
      hintText: t("views.settings.hints.push_image"),
      validWidth: 114,
      validHeight: 114,
      extensions: [".png"],
      maxWidth: 114,
      maxHeight: 114,
    },
    {
      label: "logoImage",
      description: t("views.settings.fields_labels.logo_image"),
      hintText: t("views.settings.hints.logo_image"),
      validWidth: [100, 320],
      validHeight: 100,
      extensions: [".png"],
    },
    {
      label: "mainImage",
      description: t("views.settings.fields_labels.main_image"),
      hintText: t("views.settings.hints.main_image"),
      validWidth: 624,
      validHeight: 246,
      extensions: [".png"],
    },
  ];

  const colorFields = [
    {
      label: "bgColor",
      description: t("views.settings.fields_labels.bg_color"),
    },
    {
      label: "textColor",
      description: t("views.settings.fields_labels.text_color"),
    },
  ];

  const formik = useFormik({
    initialValues: {
      pushImage: null,
      logoImage: null,
      mainImage: null,
      textColor: "",
      bgColor: "",
    },
    validationSchema: Yup.object({
      pushImage: Yup.mixed()
        .nullable()
        .required(t("core.validation.card.push_required")),
      textColor: Yup.string()
        .nullable()
        .matches(
          /^#([0-9a-f]{6})$/i,
          t("core.validation.card.hex_color_format")
        )
        .required(t("core.validation.is_required")),
      bgColor: Yup.string()
        .nullable()
        .matches(
          /^#([0-9a-f]{6})$/i,
          t("core.validation.card.hex_color_format")
        )
        .required(t("core.validation.is_required")),
    }),
    onSubmit: async (values) => {
      const newData = {};
      setLoading(true);
      newData.pushIcon = values.pushImage
        ? await toBase64(values.pushImage)
        : "";
      newData.logo = values.logoImage ? await toBase64(values.logoImage) : "";
      newData.cover = values.mainImage ? await toBase64(values.mainImage) : "";
      if (!!values.textColor) newData.textColor = values.textColor;
      if (!!values.bgColor) newData.backgroundColor = values.bgColor;

      const data = await updateCardData(newData);
      setLoading(false);
      data.result
        ? dispatch(addApiMessage("success", data.msg))
        : dispatch(addApiMessage("alert", data.msg));
      await updateData();
    },
  });

  useEffect(() => {
    const setData = async () => {
      data?.template?.images?.icon &&
        (await formik.setFieldValue(
          "pushImage",
          await dataURLtoFile(
            `data:image/png;base64,${data.template.images.icon}`,
            "icon.png"
          ),
          false
        ));

      data?.template?.images?.logo &&
        (await formik.setFieldValue(
          "logoImage",
          await dataURLtoFile(
            `data:image/png;base64,${data.template.images.logo}`,
            "logo.png"
          ),
          false
        ));

      data?.template?.images?.strip &&
        (await formik.setFieldValue(
          "mainImage",
          await dataURLtoFile(
            `data:image/png;base64,${data.template.images.strip}`,
            "cover.png"
          ),
          false
        ));

      data?.template?.colors?.label &&
        (await formik.setFieldValue(
          "textColor",
          data.template.colors.label,
          false
        ));

      data?.template?.colors?.background &&
        (await formik.setFieldValue(
          "bgColor",
          data.template.colors.background,
          false
        ));
    };
    setData();
  }, [data]);

  useEffect(() => {
    const getLogoWidth = async () => {
      const logoDimensions =
        formik.values.logoImage &&
        (await getDimensions(formik.values.logoImage));
      formik.values.logoImage && setMiniLogoWidth(~~(logoDimensions[0] / 2));
    };
    getLogoWidth();
  }, [formik.values.logoImage]);

  useEffect(() => {
    setDisabled(!formik.dirty);
  }, [formik.dirty]);

  const [isBgPickerVisible, setIsBgPickerVisible] = useState(false);
  const [isTextPickerVisible, setIsTextPickerVisible] = useState(false);

  useEffect(() => {
    if (isBgPickerVisible && isTextPickerVisible) setIsTextPickerVisible(false);
  }, [isBgPickerVisible]);

  useEffect(() => {
    if (isBgPickerVisible && isTextPickerVisible) setIsBgPickerVisible(false);
  }, [isTextPickerVisible]);

  return (
    <>
      <PerStyled.WorkspaceTitle>
        <h2>{t("views.settings.navbar_labels.card_settings")}</h2>
      </PerStyled.WorkspaceTitle>
      <Styled.CardSettings>
        <Styled.DisplayBlock>
          <Display
            isSkeleton={isSkeleton}
            companyName={
              data?.template?.values &&
              data.template.values[3] &&
              data.template.values[3]?.value
            }
            logo={
              formik.values.logoImage
                ? URL.createObjectURL(formik.values.logoImage)
                : ""
            }
            logoWidth={miniLogoWidth}
            main={
              formik.values.mainImage
                ? URL.createObjectURL(formik.values.mainImage)
                : ""
            }
            maxStamps={maxStamps}
            textColor={
              formik.values.textColor &&
              formik.values.textColor.length === 7 &&
              formik.values.textColor
            }
            bgColor={
              formik.values.bgColor &&
              formik.values.bgColor.length === 7 &&
              formik.values.bgColor
            }
          />
        </Styled.DisplayBlock>
        <Styled.EditBlock onSubmit={formik.handleSubmit}>
          <TourWrapper tourId={stepsIds[2]}>
            {uploadFields.map(
              (
                {
                  label,
                  description,
                  validWidth,
                  validHeight,
                  maxWidth,
                  maxHeight,
                  hintText,
                  extensions,
                },
                index
              ) => (
                <Styled.ParameterBlock key={`${label}_${index}`}>
                  <Styled.ParameterBlockHeader>
                    <Styled.ParameterBlockTitle>
                      {description}
                    </Styled.ParameterBlockTitle>
                    <TagIconQuestion hintText={hintText} role={"secondary"} />
                  </Styled.ParameterBlockHeader>
                  <Styled.ParameterBlockContent>
                    {isSkeleton ? (
                      <Skeleton
                        w={maxWidth ? maxWidth : 264}
                        h={100}
                        rx={10}
                        ry={10}
                      />
                    ) : (
                      <UploadField
                        image={formik.values[label]}
                        setImage={(value) =>
                          formik.setFieldValue(label, value, true)
                        }
                        state={
                          formik.errors[label] ===
                            t("core.validation.card.upload") ||
                          validation.isError(formik, label)
                            ? "alert"
                            : ""
                        }
                        setError={() =>
                          formik.setFieldError(
                            label,
                            t("core.validation.card.upload")
                          )
                        }
                        validWidth={validWidth}
                        validHeight={validHeight}
                        extensions={extensions}
                        maxWidth={maxWidth}
                        maxHeight={maxHeight}
                        local={{
                          prepositions: {
                            or: t("core.prepositions.or"),
                            from: t("core.prepositions.from"),
                            to: t("core.prepositions.to"),
                          },
                          drag: t("views.settings.zone_drag"),
                          upload: t("views.settings.zone_upload"),
                          formats: t("views.settings.zone_formats"),
                        }}
                      />
                    )}
                  </Styled.ParameterBlockContent>
                  {(formik.errors[label] === t("core.validation.card.upload") ||
                    validation.isError(formik, label)) && (
                    <GStyled.ErrorMessage>
                      {formik.errors[label]}
                    </GStyled.ErrorMessage>
                  )}
                </Styled.ParameterBlock>
              )
            )}
            {colorFields.map((field, i) => (
              <Styled.ParameterBlock key={`${field.label}_${i}`}>
                <Styled.ParameterBlockHeader>
                  <Styled.ParameterBlockTitle>
                    {field.description}
                  </Styled.ParameterBlockTitle>
                </Styled.ParameterBlockHeader>
                <Styled.ParameterBlockContent>
                  <ColorField
                    name={field.label}
                    id={field.label}
                    placeholder={field.description}
                    width={"full"}
                    height={40}
                    state={
                      validation.isError(formik, field.label) ? "error" : ""
                    }
                    label={t("views.settings.btn_select")}
                    color={formik.values[field.label]}
                    setColor={(color) =>
                      formik.setFieldValue(field.label, color, true)
                    }
                    errorText={formik.errors[field.label]}
                  />
                </Styled.ParameterBlockContent>
              </Styled.ParameterBlock>
            ))}
            <SettingsStyled.ButtonWrapper>
              <Button
                text={t("views.settings.btn_submit")}
                round={true}
                width={"full"}
                type={"submit"}
                isLoading={isLoading}
                disabled={disabled}
              />
            </SettingsStyled.ButtonWrapper>
          </TourWrapper>
        </Styled.EditBlock>
      </Styled.CardSettings>
    </>
  );
};

export default CardSettings;

CardSettings.propTypes = {
  data: PropTypes.object,
  updateData: PropTypes.func.isRequired,
  maxStamps: PropTypes.number,
};

import styled from "styled-components";
import { Row as GridRow, Col as GridCol } from "react-grid-system";
import { Select as UISelect } from "@consta/uikit/Select";
import { Tooltip as UITooltip } from "@consta/uikit/Tooltip";
import { Tabs as UITabs } from "@consta/uikit/Tabs";
import { SnackBar as UIMessageBar } from "@consta/uikit/SnackBar";
import { Checkbox as UICheckbox } from "@consta/uikit/__internal__/src/components/Checkbox/Checkbox";

export const Row = styled(GridRow)`
  margin-left: 0 !important;
  margin-right: 0 !important;
`;

export const Col = styled(GridCol)`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

const contentWidth = "350px";
const contentHeight = `${266 + 28.8 + 48 + 8 + 24}px`;
export const BackgroundContent = styled.div`
  width: ${contentWidth};
  min-height: ${contentHeight};

  position: absolute;
  top: calc((99% - ${contentHeight}) / 2);
  left: calc((100% - ${contentWidth}) / 2);

  h2 {
    margin: 24px auto 8px;

    max-width: ${contentWidth};
    text-align: center;

    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28.8px;

    color: ${({ theme }) => theme.typo.primary};
  }
  h3 {
    margin: 8px auto 0;

    max-width: ${contentWidth};
    text-align: center;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: ${({ theme }) => theme.typo.secondary};
  }
`;

export const Workspace = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);

  position: relative;

  box-sizing: border-box;
`;

export const WorkspaceTitle = styled.div`
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    height: 29px;

    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
  }
`;

export const TagIcon = styled.div`
  display: flex;
  align-items: center;

  .Icon {
    color: ${({ theme, role }) => {
      switch (role) {
        case "primary":
          return theme.main["50%"];
        case "secondary":
          return theme.typo.ghost;

        default:
          return theme.main["50%"];
      }
    }};

    &:hover {
      color: ${({ theme, role }) => {
        switch (role) {
          case "primary":
            return theme.main["100%"];
          case "secondary":
            return theme.typo.secondary;

          default:
            return theme.main["100%"];
        }
      }};

      cursor: pointer;
    }
  }
`;

export const Tooltip = styled(UITooltip).attrs({
  size: "s",
})`
  cursor: default;

  .Tooltip-Background {
    background-color: ${({ theme }) => theme.white.primary} !important;
    opacity: 1;

    // Тень
    -webkit-box-shadow: 0 0 12px 1px rgba(34, 60, 80, 0.24);
    -moz-box-shadow: 0 0 12px 1px rgba(34, 60, 80, 0.24);
    box-shadow: 0 0 12px 1px rgba(34, 60, 80, 0.24);
  }

  .Tooltip-Arrow_direction_upLeft,
  .Tooltip-Arrow_direction_upCenter,
  .Tooltip-Arrow_direction_upRight,
  .Tooltip-Arrow_direction_upStartLeft,
  .Tooltip-Arrow_direction_upStartRight {
    border-top-color: ${({ theme }) => theme.white.primary} !important;
    opacity: 1;
  }

  .Tooltip-Arrow_direction_downLeft,
  .Tooltip-Arrow_direction_downCenter,
  .Tooltip-Arrow_direction_downRight,
  .Tooltip-Arrow_direction_downStartLeft,
  .Tooltip-Arrow_direction_downStartRight {
    border-bottom-color: ${({ theme }) => theme.white.primary} !important;
    opacity: 1;
  }

  .Tooltip-Content {
    padding: 8px;
  }

  .Text {
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;

    color: ${({ theme }) => theme.typo.primary} !important;
  }
`;

export const Select = styled(UISelect)`
  background-color: ${({ theme, role }) =>
    role === "secondary" ? "inherit" : theme.white.primary};

  &:hover {
    border-color: ${(props) => props.theme.border.hover} !important;
  }

  &.Select_focused .Select-Control {
    border-color: ${(props) => props.theme.main["100%"]} !important;
  }

  .Select-Control {
    ${({ state, theme }) =>
      state === "alert" ? `border-color: ${theme.error} !important;` : null}
  }
`;

export const ModalButtonWrapper = styled.div`
  margin: 30px auto 0;
  width: 65%;
  max-width: 240px;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Tabs = styled(UITabs)`
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;

  border-bottom: ${({ theme, role }) =>
    role === "secondary" ? `1px solid ${theme.border.default}` : "none"};

  ${({ role }) => {
    switch (role) {
      case "secondary":
        return ".TabsFitModeDropdownWrapper {\n    background-color: inherit !important;\n  }\n\n  .TabsFitModeDropdownWrapper-Tabs {\n    padding: 0 !important;\n  }";
      default:
        return "";
    }
  }}

  .TabsTab::before {
    content: none;
  }

  .TabsLine_position_bottom,
  .TabsLine_position_top {
    border-radius: 4px 4px 0 0;
    height: 4px;
    background-color: ${({ theme }) => theme.main["100%"]};
  }

  .TabsTab {
    height: 60px;
  }

  .TabsFitModeDropdownWrapper-Tabs {
    padding: 0 24px 0;
  }

  .TabsTab,
  .TabsTab_size_m,
  .MixFocus,
  .MixFocus_before {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 16.75px 0 0;
  }

  &:focus {
    box-shadow: none !important;
  }

  .TabsFitModeDropdownWrapper {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.white.primary};
  }

  .TabsTab_checked,
  .TabsTab:hover {
    color: ${({ theme }) => theme.typo.primary};
  }

  .TabsTab_checked {
    font-weight: 500;
  }

  .TabsLine_type_border {
    background-color: inherit;
`;

export const MessageBar = styled(UIMessageBar)`
  position: fixed;
  right: 10px;
  bottom: -5px;

  width: 300px;
  height: auto;

  z-index: 4;
`;

export const Checkbox = styled(UICheckbox).attrs({
  size: "l",
})`
  input:focus {
    box-shadow: none !important;
  }

  // Из-за зависимости центрирования иконки галочки от размера шрифта - для всех строк таблицы это свойство должно быть одинаковым
  .Checkbox-Input {
    font-size: 14px;

    &:checked {
      background-color: ${({ theme }) => theme.main["100%"]};
      border-color: ${({ theme }) => theme.main["100%"]};

      &:hover {
        background-color: ${({ theme }) => theme.main.hover};
        border-color: ${({ theme }) => theme.main.hover};
      }
    }
  }
`;

export const LocationInputsContainer = styled.div`
  display: grid;
  grid-row-gap: 20px;
`;

export const DoubledInputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
`;

import React from "react";
import * as Styled from "./styled";
import PropTypes from "prop-types";

const IconCount = ({ count }) => {
  return (
    <Styled.IconCount>
      <Styled.Count>{count}</Styled.Count>
    </Styled.IconCount>
  );
};

export default IconCount;

IconCount.propTypes = {
  count: PropTypes.number.isRequired,
};

import React, { useEffect, useState } from "react";
import * as PerStyled from "../styled";
import { useFormik } from "formik";
import * as Yup from "yup";
import { apiService } from "../../../utils";
import { createBill } from "../../../store/locations/actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import PropTypes from "prop-types";
import { cn } from "@consta/uikit/__internal__/cjs-src/utils/bem";
import * as Styled from "./styled";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "@6tamp/uikit";

const PaymentLocationModal = ({ isOpen, close, locationId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLoading = useSelector(({ locations }) => locations.isLoading);

  const [paymentVariants, setPaymentVariants] = useState([]);
  const [paymentUrl, setPaymentUrl] = useState("");

  const loadVariants = async (token) => {
    try {
      const { data } = await apiService.get("/billing/variants", {
        cancelToken: token,
      });
      setPaymentVariants(
        data.map(
          ({ variant, priceWithDiscount, priceWithoutDiscount, discount }) => ({
            label: `${variant} ${t(
              "views.locations.payment_location_modal_month"
            )}.`,
            id: variant,
            priceWithDiscount,
            priceWithoutDiscount,
            discount,
          })
        )
      );
    } catch (e) {}
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    loadVariants(source.token);

    return () => {
      source.cancel();
    };
  }, []);

  const onClose = () => {
    close();
    setPaymentUrl("");
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      variant: Yup.object({})
        .typeError("Укажите вариант оплаты")
        .required("Укажите вариант оплаты"),
    }),
    onSubmit: async ({ variant }) => {
      const { paymentUrl } = await dispatch(createBill(locationId, variant.id));
      setPaymentUrl(paymentUrl);
    },
  });

  useEffect(() => {
    setPaymentUrl("");
  }, [formik.values.variant]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("views.locations.payment_location_modal_title")}
      description={t("views.locations.payment_location_modal_desc")}
    >
      <Styled.Form
        onSubmit={formik.handleSubmit}
        onChange={formik.handleChange}
      >
        <PerStyled.Select
          value={formik.values.variant}
          id="variant"
          name="variant"
          placeholder={t("views.locations.modal_fields_placeholders.variant")}
          items={paymentVariants}
          state={formik.errors.variant && formik.touched.variant && "alert"}
          onChange={({ value }) => formik.setFieldValue("variant", value)}
          renderItem={({ item, active, hovered, onClick, onMouseEnter }) => (
            <div
              className={cn("SelectItem")({
                active,
                hovered,
                size: "m",
                indent: "normal",
              })}
              role="option"
              aria-selected={active}
              onMouseEnter={onMouseEnter}
              onClick={onClick}
            >
              {item.label}
              <Styled.Price>
                {item.discount > 0 && <s>{item.priceWithoutDiscount}</s>}
                {` ${item.priceWithDiscount} ${t("core.currency.short")}.`}
              </Styled.Price>
              {item.discount > 0 && (
                <Styled.Discount>-{item.discount}%</Styled.Discount>
              )}
            </div>
          )}
        />
        <PerStyled.ModalButtonWrapper>
          {!!paymentUrl ? (
            <Styled.Link href={paymentUrl} target={"_blank"} onClick={onClose}>
              <div>{`${t("views.locations.btn_go_to_payment")} ${
                formik.values.variant.priceWithDiscount
              } ${t("core.currency.short")}.`}</div>
            </Styled.Link>
          ) : (
            <Button
              type={"submit"}
              width={"full"}
              round={true}
              text={t("views.locations.btn_get_payment_link")}
              isLoading={isLoading}
              disabled={!formik.values.variant}
            />
          )}
        </PerStyled.ModalButtonWrapper>
      </Styled.Form>
    </Modal>
  );
};

export default PaymentLocationModal;

PaymentLocationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  locationId: PropTypes.string,
};

import React from "react";
import * as Styled from "./styled";
import PropTypes from "prop-types";
import { Skeleton } from "@6tamp/uikit";
import { useTranslation } from "react-i18next";

const Display = ({
  isSkeleton,
  companyName,
  logo,
  logoWidth,
  main,
  maxStamps,
  textColor,
  bgColor,
}) => {
  const { t } = useTranslation();
  return (
    <Styled.Display>
      <Styled.PhoneForm>
        {isSkeleton ? (
          <Styled.SkeletonCardWrapper>
            <Skeleton w={300} h={465} rx={10} ry={10} bgColor={"#dedede"} />
          </Styled.SkeletonCardWrapper>
        ) : (
          <Styled.Card textColor={textColor} bgColor={bgColor}>
            <Styled.Header>
              <Styled.LogoPicture logo={logo} width={logoWidth} height={50} />
              {companyName && (
                <Styled.Name>
                  <div>{t("views.settings.display_company_label")}</div>
                  <span>{companyName}</span>
                </Styled.Name>
              )}
            </Styled.Header>
            <Styled.MainPicture main={main} height={100} />
            <Styled.StampsNGiftsBlock>
              <Styled.StampsNGifts>
                <Styled.StampsNGiftsTitle>
                  {t("views.settings.display_stamps_label")}
                </Styled.StampsNGiftsTitle>
                <Styled.StampsNGiftsCount>
                  1/{maxStamps}
                </Styled.StampsNGiftsCount>
              </Styled.StampsNGifts>
              <Styled.StampsNGifts>
                <Styled.StampsNGiftsTitle>
                  {t("views.settings.display_gifts_label")}
                </Styled.StampsNGiftsTitle>
                <Styled.StampsNGiftsCount>2</Styled.StampsNGiftsCount>
              </Styled.StampsNGifts>
            </Styled.StampsNGiftsBlock>
            <Styled.QrCode />
          </Styled.Card>
        )}
      </Styled.PhoneForm>
    </Styled.Display>
  );
};

export default Display;

Display.propTypes = {
  bgColor: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  textColor: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  push: PropTypes.string,
  logo: PropTypes.string,
  logoDimensions: PropTypes.arrayOf(PropTypes.number),
  main: PropTypes.string,
  maxStamps: PropTypes.number,
  isSkeleton: PropTypes.bool,
};

Display.defaultProps = {
  push: "",
  logo: "",
  logoWidth: 50,
  main: "",
  maxStamps: 6,
  textColor: "#FFFFFF",
  bgColor: "#2F3AA3",
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Styled from "./styled";
import * as PerStyled from "../styled";
import * as GStyled from "../../styled";
import { useDispatch, useSelector } from "react-redux";
import { addApiMessage } from "../../../store/messageBar/actions";
import { sendPush } from "../../../store/clients/actions";
import { PushDisplay } from "../../index";
import { getChainData } from "../../../store/settings/actions";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useMetrika } from "yandex-metrika-react";
import { Button, Modal } from "@6tamp/uikit";

const PushModal = ({ data, isOpen, close, clearSelected }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ym = useMetrika();

  const [pushText, setPushText] = useState("");
  const [companyName, setCompanyName] = useState("");

  const isLoading = useSelector(({ clients }) => clients.isLoading);
  const userId = useSelector(({ auth }) => auth?.id);

  const handlePushSubmit = async () => {
    const { result, msg } = await dispatch(sendPush(data, pushText));
    setPushText("");
    close();
    clearSelected();
    dispatch(addApiMessage(result ? "success" : "alert", msg));
    ym("reachGoal", "sendPush", { userId });
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetch = async () => {
      const chainData = await getChainData(source.token);
      setCompanyName(chainData?.fields?.title);
    };
    fetch();

    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      ym("reachGoal", "pushPopup", { userId });
    }
  }, [isOpen]);

  useEffect(() => {}, [pushText]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      title={t("views.clients.push_modal_title")}
      description={t("views.clients.push_modal_desc")}
    >
      <GStyled.Input
        type={"textarea"}
        rows={4}
        placeholder={t("views.clients.textarea_placeholder")}
        value={pushText}
        onChange={(e) => setPushText(e.value)}
      />
      <Styled.PushDisplayWrapper>
        <PushDisplay text={pushText} companyName={companyName} />
      </Styled.PushDisplayWrapper>
      <PerStyled.ModalButtonWrapper>
        <Button
          disabled={!pushText}
          width={"full"}
          text={isLoading ? "" : t("views.clients.btn_send_push")}
          onClick={handlePushSubmit}
          isLoading={isLoading}
          round={true}
        />
      </PerStyled.ModalButtonWrapper>
    </Modal>
  );
};

export default PushModal;

PushModal.propTypes = {
  data: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

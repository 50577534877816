import produce from "immer";
import {
  RESET_FILTERED_LIST,
  SET_FILTERED_LIST,
  LOAD_USERS,
  SET_LOADING,
  SET_SKELETON,
  SET_WORKER,
} from "./types";

const getSearchIndex = (data) => {
  const result = {};
  for (let key in data) {
    const { name, lastName } = data[key];
    result[`${name.toLowerCase()} ${lastName.toLowerCase()}`] = key;
    result[`${lastName.toLowerCase()} ${name.toLowerCase()}`] = key;
  }
  return result;
};

const initialState = {
  isLoading: false,
  active: {
    isSkeleton: false,
    loadedUsers: [],
    filteredUsers: [],
    searchIndex: getSearchIndex([]),
  },
  trash: {
    isSkeleton: false,
    loadedUsers: [],
    filteredUsers: [],
    searchIndex: getSearchIndex([]),
  },
  worker: {
    isSkeleton: false,
    id: "",
    name: "",
    lastName: "",
  },
};

const workersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  return produce(state, (draft) => {
    switch (type) {
      case SET_FILTERED_LIST:
        draft[payload.mode].filteredUsers = payload.data;
        return draft;

      case RESET_FILTERED_LIST:
        draft[payload.mode].filteredUsers = state[payload.mode].loadedUsers;
        return draft;

      case LOAD_USERS:
        draft[payload.mode].loadedUsers = payload.data;
        draft[payload.mode].filteredUsers = payload.data;
        draft[payload.mode].searchIndex = getSearchIndex(payload.data);
        return draft;

      case SET_LOADING:
        draft.isLoading = payload;
        return draft;

      case SET_SKELETON:
        draft[payload.mode].isSkeleton = payload.value;
        return draft;

      case SET_WORKER:
        for (let x in payload) draft.worker[x] = payload[x];
        return draft;

      default:
        return state;
    }
  });
};

export default workersReducer;

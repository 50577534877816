import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  CreateLocationModal,
  LocationQrCodeModal,
  PaymentLocationModal,
  SearchBar,
  UpdateLocationModal,
  TourWrapper,
} from "../../components";
import * as LocationsStyled from "../../components/Personal/Locations/styled";
import * as PerStyled from "../../components/Personal/styled";
import { accountTypes } from "../../utils";
import {
  deleteLocation,
  loadLocations,
  search,
} from "../../store/locations/actions";
import { EmptyPage, Table, Button } from "@6tamp/uikit";
import { IconNodeEnd } from "@consta/uikit/IconNodeEnd";
import { IconLink } from "@consta/uikit/IconLink";
import { IconEdit } from "@consta/uikit/IconEdit";
import { IconTrash } from "@consta/uikit/IconTrash";
import { useDispatch, useSelector } from "react-redux";
import { stepsIds } from "../../components/Personal/Onboarding/Onboarding";
import { useTranslation } from "react-i18next";

const ActiveLocations = ({ columnsDef, desc }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [isQrCodeModalVisible, setIsQrCodeModalVisible] = useState(false);
  const [qrCodeLocationId, setQrCodeLocationId] = useState(null);
  const [paymentLocationId, setPaymentLocationId] = useState(null);
  const [editLocationValues, setEditLocationValues] = useState({});

  const { accountType } = useSelector(({ auth }) => auth);
  const { loadedLocations, filteredLocations, isSkeleton } = useSelector(
    ({ locations }) => locations.active
  );

  const onElementEdit = (elementIndex) => {
    setIsEditModalVisible(true);
    setEditLocationValues(filteredLocations[elementIndex]);
  };

  const onElementDelete = async (elementIndex) => {
    await dispatch(deleteLocation(filteredLocations[elementIndex]._id));
    dispatch(loadLocations(null, "active"));
  };

  const onElementPay = (elementIndex) => {
    setIsPaymentModalVisible(true);
    setPaymentLocationId(filteredLocations[elementIndex]._id);
  };

  const onElementQrCode = (elementIndex) => {
    setIsQrCodeModalVisible(true);
    setQrCodeLocationId(filteredLocations[elementIndex]._id);
  };

  return (
    <>
      <CreateLocationModal
        isOpen={isCreateModalVisible}
        close={() => setIsCreateModalVisible(false)}
      />
      <UpdateLocationModal
        isOpen={isEditModalVisible}
        close={() => setIsEditModalVisible(false)}
        values={editLocationValues}
      />
      <PaymentLocationModal
        isOpen={isPaymentModalVisible}
        close={() => setIsPaymentModalVisible(false)}
        locationId={paymentLocationId}
      />
      <LocationQrCodeModal
        close={() => setIsQrCodeModalVisible(false)}
        id={qrCodeLocationId}
        isOpen={isQrCodeModalVisible}
      />
      <LocationsStyled.ContentTop>
        <PerStyled.WorkspaceTitle>
          <h2>{desc}</h2>
          {accountType === accountTypes.OWNER && isSkeleton ? (
            <Button
              round={true}
              text={t("views.locations.btn_opn_create_location_modal")}
              onClick={() => setIsCreateModalVisible(true)}
            />
          ) : (
            <TourWrapper tourId={stepsIds[3]}>
              <Button
                round={true}
                text={t("views.locations.btn_opn_create_location_modal")}
                onClick={() => setIsCreateModalVisible(true)}
              />
            </TourWrapper>
          )}
        </PerStyled.WorkspaceTitle>
      </LocationsStyled.ContentTop>
      <LocationsStyled.ContentBottom>
        <SearchBar
          onChange={(value) => dispatch(search(value, "active"))}
          onEnter={() => true}
          placeholder={t("views.locations.modal_fields_placeholders.search")}
        />
        {!!filteredLocations.length || isSkeleton ? (
          <LocationsStyled.TableBlock>
            <Table
              data={[...filteredLocations]}
              cols={columnsDef}
              isSkeleton={isSkeleton}
              editable
              contextMenu={[
                {
                  name: t("views.locations.context_menu_labels.pay"),
                  icon: <IconNodeEnd />,
                  group: 1,
                  onClick: onElementPay,
                },
                {
                  name: t("views.locations.context_menu_labels.get_qr"),
                  icon: <IconLink />,
                  group: 2,
                  onClick: onElementQrCode,
                },
                {
                  name: t("views.locations.context_menu_labels.edit"),
                  icon: <IconEdit />,
                  group: 3,
                  onClick: onElementEdit,
                },
                {
                  name: t("views.locations.context_menu_labels.delete"),
                  icon: <IconTrash />,
                  group: 3,
                  onClick: onElementDelete,
                },
              ]}
            />
          </LocationsStyled.TableBlock>
        ) : (
          <>
            {!!loadedLocations.length ? (
              <EmptyPage
                variant={"NotFound"}
                header={t("views.locations.empty_page_not_found_header")}
                description={t("views.locations.empty_page_not_found_desc")}
              />
            ) : (
              <EmptyPage
                variant={"NotExists"}
                header={t("views.locations.empty_page_not_exists_header")}
                description={t("views.locations.empty_page_not_exists_desc")}
              />
            )}
          </>
        )}
      </LocationsStyled.ContentBottom>
    </>
  );
};

ActiveLocations.defaultProps = {
  desc: "Локации",
};

ActiveLocations.propTypes = {
  columnsDef: PropTypes.array.isRequired,
  desc: PropTypes.string,
};

export default ActiveLocations;

const roles = [
  {
    labelId: "core.user_roles.manager",
    id: 1,
  },
  {
    labelId: "core.user_roles.worker",
    id: 2,
  },
];

const getRoleNameById = (id, t) =>
  t(roles.find((role) => id === role.id).labelId);
const getRoleIdByName = (name, t) =>
  roles.find((role) => name?.toLowerCase() === t(role.labelId)?.toLowerCase())
    ?.id;

export { roles, getRoleNameById, getRoleIdByName };

import { apiService } from "../../utils";
import {
  DEL_AUTH_DATA,
  SET_AUTH_DATA,
  SET_LOADING,
  CONFIRM,
  SET_TOUR,
  SET_LANG_FETCHING,
  SET_AUTH_FETCHING,
} from "./types";
import i18n from "../../localization/i18n";
import getUserLanguage from "../../utils/getUserLanguage";

const setLoading = (isLoading) => ({ type: SET_LOADING, payload: isLoading });

const setLangFetching = (isFetched) => ({
  type: SET_LANG_FETCHING,
  payload: isFetched,
});

const setAuthFetching = (isFetched) => ({
  type: SET_AUTH_FETCHING,
  payload: isFetched,
});

export const register =
  ({ email, phone, password }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const { data } = await apiService.post("/user/signup", {
        email,
        phone,
        password,
      });
      dispatch({
        type: SET_AUTH_DATA,
        payload: data,
      });
      await apiService.put("/chain", {
        language: i18n.language,
      });
      dispatch(setLoading(false));
      return { result: true };
    } catch (e) {
      dispatch(setLoading(false));
      if (e.isAxiosError) {
        switch (e.response?.status) {
          case 400:
            return {
              result: false,
              msg: i18n.t("core.actions_msgs.email_already_exists"),
            };
          default:
            return {
              result: false,
              msg: i18n.t("core.actions_msgs.server_error"),
            };
        }
      }
    }
  };

export const login =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await apiService.post("/user/signin", {
        email,
        password,
      });
      dispatch({
        type: SET_AUTH_DATA,
        payload: data,
      });
      if (data.confirmed) dispatch(setTour(!data.onboarded));
      dispatch(setLoading(false));
      return { result: true };
    } catch (e) {
      dispatch(setLoading(false));
      if (e.isAxiosError) {
        switch (e.response?.status) {
          case 400:
            return {
              result: false,
              msg: i18n.t("core.actions_msgs.wrong_credentials"),
            };
          default:
            return {
              result: false,
              msg: i18n.t("core.actions_msgs.server_error"),
            };
        }
      }
    }
  };

export const logout = () => {
  return { type: DEL_AUTH_DATA };
};

export const sendConfirmationCode = () => async (dispatch, getState) => {
  const userId = getState().auth.id;
  await apiService.post("/user/confirmation/send", { userId });
};

export const confirm = () => async (dispatch) => {
  try {
    const { data } = await apiService.get("/user/current");
    dispatch({ type: CONFIRM, payload: data.type });
    dispatch(setTour(!data.onboarded));
  } catch (e) {}
};

export const checkConfirmationCode = (code) => async (dispatch, getState) => {
  const userId = getState().auth.id;
  dispatch(setLoading(true));
  try {
    const { data } = await apiService.post("/user/confirmation/check", {
      userId,
      code,
    });
    if (data.result) dispatch(confirm());
    dispatch(setLoading(false));
    return data.result;
  } catch (e) {
    dispatch(setLoading(false));
    return false;
  }
};

export const sendNewPassword = (email) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await apiService.post("/user/reset", { email });
    dispatch(setLoading(false));
    return { result: true };
  } catch (e) {
    dispatch(setLoading(false));
    if (e.isAxiosError) {
      switch (e.response?.status) {
        case 400:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.email_not_registered"),
          };
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const checkConfirmed = () => async (dispatch, getState) => {
  const { jwt } = getState().auth;
  if (jwt) {
    try {
      dispatch(setLoading(true));
      dispatch(setAuthFetching(true));
      const { data } = await apiService.get("/user/confirmed");
      dispatch(setLoading(false));
      if (data.result) {
        const { data } = await apiService.get("/user/current");
        dispatch({
          type: SET_AUTH_DATA,
          payload: {
            id: data._id,
            jwt,
            confirmed: true,
            type: data.type,
          },
        });
        dispatch(setTour(!data.onboarded));
      } else {
        dispatch(logout());
      }
      dispatch(setAuthFetching(false));
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(setAuthFetching(false));
      dispatch(logout());
    }
  }
};

export const setTour = (isTour) => {
  return { type: SET_TOUR, payload: isTour };
};

export const completeOnboarding = () => (dispatch, getState) => {
  const { id } = getState().auth;
  apiService.put(`/user/${id}`, { onboarded: true });
};

export const setAppLanguage = (isAuthorized) => async (dispatch) => {
  if (isAuthorized) {
    try {
      dispatch(setLangFetching(true));
      const { data } = await apiService.get("/chain");
      await i18n.changeLanguage(data.language);
      dispatch(setLangFetching(false));
    } catch (e) {
      dispatch(setLangFetching(false));
    }
  } else {
    await i18n.changeLanguage(getUserLanguage()[0]);
  }
};

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Styled from "./styled";
import * as PerStyled from "../styled";
import {
  loadLocations,
  updateLocation,
} from "../../../store/locations/actions";
import { useDispatch, useSelector } from "react-redux";
import { validation } from "../../../utils";
import { addApiMessage } from "../../../store/messageBar/actions";
import PropTypes from "prop-types";
import { Map, TextField, Button, Modal } from "@6tamp/uikit";
import { useTranslation } from "react-i18next";
import { useMetrika } from "yandex-metrika-react";

const fieldTypes = Object.freeze({
  Single: Symbol("single"),
  Double: Symbol("double"),
});

const UpdateLocationModal = ({ isOpen, close, values }) => {
  const ym = useMetrika();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(({ locations }) => locations.isLoading);
  const userId = useSelector(({ auth }) => auth?.id);

  const fields = [
    {
      type: fieldTypes.Single,
      id: "title",
      placeholder: t("views.locations.modal_fields_placeholders.title"),
    },
    {
      type: fieldTypes.Single,
      id: "address",
      placeholder: t("views.locations.modal_fields_placeholders.address"),
    },
    {
      type: fieldTypes.Double,
      left: {
        id: "from",
        placeholder: t("views.locations.modal_fields_placeholders.from"),
      },
      right: {
        id: "to",
        placeholder: t("views.locations.modal_fields_placeholders.to"),
      },
    },
    {
      type: fieldTypes.Single,
      id: "geoPushMessage",
      placeholder: t(
        "views.locations.modal_fields_placeholders.geo_push_message"
      ),
    },
    {
      type: fieldTypes.Double,
      left: {
        id: "latitude",
        placeholder: t("views.locations.modal_fields_placeholders.latitude"),
      },
      right: {
        id: "longitude",
        placeholder: t("views.locations.modal_fields_placeholders.longitude"),
      },
    },
  ];

  const [point, setPoint] = useState([]);

  const formik = useFormik({
    initialValues: {
      title: "",
      address: "",
      from: "",
      to: "",
      geoPushMessage: "",
      latitude: "",
      longitude: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t("core.validation.is_required")),
      address: Yup.string().required(t("core.validation.is_required")),
      from: Yup.string(),
      to: Yup.string(),
      geoPushMessage: Yup.string(),
      latitude: Yup.number(t("core.validation.coordinates.invalid_value"))
        .typeError(t("core.validation.coordinates.invalid_type"))
        .min(-90, t("core.validation.coordinates.invalid_value"))
        .max(90, t("core.validation.coordinates.invalid_value")),
      longitude: Yup.number(t("core.validation.coordinates.invalid_value"))
        .typeError(t("core.validation.coordinates.invalid_type"))
        .min(-180, t("core.validation.coordinates.invalid_value"))
        .max(180, t("core.validation.coordinates.invalid_value")),
    }),
    onSubmit: async (newValues) => {
      const { title, address, from, to, geoPushMessage, latitude, longitude } =
        newValues;

      const request = {
        title,
        geoPushMessage,
        address,
        position: {
          latitude,
          longitude,
        },
        workingHours: {
          from,
          to,
        },
      };
      const { result, msg } = await dispatch(
        updateLocation(values._id, request)
      );
      dispatch(addApiMessage(result ? "success" : "alert", msg));
      if (result) {
        dispatch(loadLocations());
        close();
      }
      if (request.geoPushMessage) {
        ym("reachGoal", "geoPush", { userId });
      }
    },
  });

  useEffect(() => {
    const { title, address, geoPushMessage } = values;
    const initialValues = {
      title,
      address,
      geoPushMessage,
    };
    if (values.workingHours) {
      const { from, to } = values.workingHours;
      initialValues.from = from;
      initialValues.to = to;
    } else {
      initialValues.from = "";
      initialValues.to = "";
    }
    if (values.position) {
      const { latitude, longitude } = values.position;
      initialValues.latitude = latitude;
      initialValues.longitude = longitude;
      setPoint([latitude, longitude]);
    } else {
      initialValues.latitude = "";
      initialValues.longitude = "";
    }
    Object.keys(initialValues).forEach((key) =>
      formik.setFieldValue(key, initialValues[key])
    );
  }, [values]);

  const onMapClick = async ([latitude, longitude]) => {
    setPoint([latitude, longitude]);
    await Promise.all([
      formik.setFieldValue("latitude", latitude.toString()),
      formik.setFieldValue("longitude", longitude.toString()),
    ]);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      title={t("views.locations.update_location_modal_title")}
    >
      <Styled.Form
        onSubmit={formik.handleSubmit}
        onChange={formik.handleChange}
      >
        <PerStyled.LocationInputsContainer>
          {fields.map((field) => {
            if (field.type === fieldTypes.Single) {
              return (
                <TextField
                  key={field.id}
                  id={field.id}
                  name={field.id}
                  placeholder={field.placeholder}
                  value={formik.values[field.id]}
                  width={"full"}
                  height={40}
                  state={validation.isError(formik, field.id) ? "error" : ""}
                  errorText={formik.errors[field.id]}
                />
              );
            } else {
              return (
                <PerStyled.DoubledInputContainer
                  key={`${field.left.id}_${field.right.id}`}
                >
                  <TextField
                    id={field.left.id}
                    name={field.left.id}
                    placeholder={field.left.placeholder}
                    value={formik.values[field.left.id]}
                    width={"full"}
                    height={40}
                    state={
                      validation.isError(formik, field.left.id) ? "error" : ""
                    }
                    errorText={formik.errors[field.left.id]}
                  />
                  <TextField
                    id={field.right.id}
                    name={field.right.id}
                    placeholder={field.right.placeholder}
                    value={formik.values[field.right.id]}
                    width={"full"}
                    height={40}
                    state={
                      validation.isError(formik, field.right.id) ? "error" : ""
                    }
                    errorText={formik.errors[field.right.id]}
                  />
                </PerStyled.DoubledInputContainer>
              );
            }
          })}
        </PerStyled.LocationInputsContainer>
        <Map
          onGetCoords={onMapClick}
          defaultCoords={point}
          points={[point]}
          height={"22vh"}
          defaultZoom={point[0] && point[1] ? 16 : 11}
        />
        <PerStyled.ModalButtonWrapper>
          <Button
            width={"full"}
            round={true}
            type={"submit"}
            text={t("views.locations.btn_update_location")}
            isLoading={isLoading}
          />
        </PerStyled.ModalButtonWrapper>
      </Styled.Form>
    </Modal>
  );
};

export default UpdateLocationModal;

UpdateLocationModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  values: PropTypes.object.isRequired,
};

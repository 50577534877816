import { SET_SKELETON } from "./types";
import produce from "immer";

const initialState = {
  card: {
    isSkeleton: true,
  },
};

const settingsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  return produce(state, (draft) => {
    switch (type) {
      case SET_SKELETON:
        draft.card.isSkeleton = payload;
        return draft;

      default:
        return draft;
    }
  });
};

export default settingsReducer;

import {
  FILTER_CLIENTS,
  CLEAR_FILTER,
  WRAP_CLIENTS,
  GET_INFO,
  SET_LOADING,
  PREFILTER_CLIENTS,
  SET_SKELETON,
} from "./types";
import { apiService } from "../../utils";
import i18n from "../../localization/i18n";

const setSkeleton = (isSkeleton) => {
  return { type: SET_SKELETON, payload: isSkeleton };
};

const setLoading = (isLoading) => {
  return { type: SET_LOADING, payload: isLoading };
};

export const sendPush = (clients, message) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const { data } = await apiService.post("/client/push", {
        ids: clients,
        message,
      });
      dispatch(setLoading(false));
      return {
        result: data.result,
        msg: data.result
          ? i18n.t("core.actions_msgs.push_sent")
          : i18n.t("core.actions_msgs.push_not_sent"),
      };
    } catch (e) {
      dispatch(setLoading(false));
      return { result: false, msg: i18n.t("core.actions_msgs.server_error") };
    }
  };
};

export const getClientStat =
  (dateFrom, dateTo, type, locationId) => async (dispatch) => {
    if (!dateFrom) {
      return {};
    }
    try {
      dispatch(setLoading(true));
      dispatch(setSkeleton(true));

      const { data } =
        type === 3
          ? await apiService.get(
              `/client/stat/count?dateFrom=${dateFrom}&dateTo=${dateTo}${
                !!locationId ? `&location=${locationId}` : ""
              }`
            )
          : await apiService.get(
              `/client/stat?dateFrom=${dateFrom}&dateTo=${dateTo}&type=${type}${
                !!locationId ? `&location=${locationId}` : ""
              }`
            );

      dispatch(setLoading(false));
      dispatch(setSkeleton(false));

      return data;
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(setSkeleton(false));
    }
  };

export const loadClients = () => {
  return async (dispatch) => {
    try {
      dispatch(setSkeleton(true));

      const { data } = await apiService.get("/client");
      dispatch({ type: WRAP_CLIENTS, payload: data });
      dispatch({ type: GET_INFO });

      dispatch(setSkeleton(false));
      return { result: true, msg: i18n.t("core.actions_msgs.clients_fetched") };
    } catch (e) {
      dispatch(setSkeleton(false));
      return { result: false, msg: i18n.t("core.actions_msgs.server_error") };
    }
  };
};

export const filterClients = () => {
  return { type: FILTER_CLIENTS };
};

export const prefilterClients = (obj) => {
  return { type: PREFILTER_CLIENTS, payload: obj };
};

export const clearFilter = () => {
  return { type: CLEAR_FILTER };
};

import React from "react";
import * as Styled from "./styled";
import PropTypes from "prop-types";

const SidebarLink = ({ icon, label, route, onClick }) => {
  const [isActive, setIsActive] = React.useState(false);

  React.useEffect(() => {
    setIsActive(window.location.pathname === route);
  });

  return (
    <Styled.SidebarLink
      to={route}
      active={isActive ? "true" : ""}
      onClick={onClick}
    >
      <Styled.Icon iconLeft={icon} />
      <Styled.Text>{label}</Styled.Text>
    </Styled.SidebarLink>
  );
};

export default SidebarLink;

SidebarLink.propTypes = {
  icon: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

import styled from "styled-components";
import web from "../../../assets/img/web_icon.svg";

export const LanguageSelector = styled.div`
  height: 24px;

  white-space: nowrap;

  display: grid;
  grid-template-columns: 16px 85px;
  gap: 0 8px;
  align-items: center;

  /*
    Селектор из consta не умеет находиться по центру, поэтому двигаем иконку чуть выше
    todo: Убрать при появлении собственного селектора
   */
  button {
    margin: 0 0 3px;
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  /*
    Селектор из consta не умеет находиться по центру, поэтому двигаем иконку чуть выше
    todo: Убрать при появлении собственного селектора
   */
  padding: 0 0 3px;
`;

export const WebIcon = styled.div`
  width: 14px;
  height: 14px;

  background-image: url(${web});
  background-size: contain;
  background-repeat: no-repeat;
`;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as auth from "../store/auth/actions";
import { routePaths } from "./useRoutes";

function useAuthWall() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { jwt, confirmed } = useSelector(({ auth }) => auth);
  const isAuthorized = jwt && confirmed;

  //Установка локализации браузера вне authwall, бэка внутри
  useEffect(() => {
    dispatch(auth.setAppLanguage(isAuthorized));
  }, [isAuthorized]);

  //При незаконченной регистрации перекидывание на верификацию
  useEffect(() => {
    if (jwt && !confirmed && typeof confirmed === "boolean") {
      history.push(routePaths.signUpVerification);
    }
  }, [confirmed, jwt]);

  //При каждом обновлении всей страницы проверка авторизации
  useEffect(() => {
    dispatch(auth.checkConfirmed());
  }, []);
}

export default useAuthWall;

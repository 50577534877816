import React, { useCallback, useState } from "react";
import * as Styled from "./styled";
import PropTypes from "prop-types";

const SearchBar = ({ onChange, onEnter, placeholder }) => {
  const [value, setValue] = useState("");

  const _onChange = useCallback(
    ({ value }) => {
      setValue(value);
      onChange(value);
    },
    [onChange]
  );
  const _onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onEnter(e);
    },
    [onEnter]
  );

  return (
    <form onSubmit={_onSubmit}>
      <Styled.Input
        onChange={_onChange}
        value={value}
        placeholder={placeholder}
      />
    </form>
  );
};

SearchBar.propTypes = {
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  placeholder: PropTypes.string,
};

SearchBar.defaultProps = {
  placeholder: "Введите данные для поиска",
};

export default SearchBar;

import styled from "styled-components";

export const IconCount = styled.div`
  margin: 0 0 0 10px;

  width: 24px;
  height: 24px;

  border-radius: 50%;

  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.white.primary};

  background-color: ${({ theme }) => theme.main["100%"]};
`;

export const Count = styled.div`
  margin: 0 auto;
`;

import styled from "styled-components";
import logo from "../../../assets/img/logo.svg";

import { Col } from "../styled";

export const Sidebar = styled(Col)`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  position: relative;

  background-color: ${(props) => props.theme.main["100%"]};

  overflow: hidden;
`;

export const Content = styled.div`
  margin: 6.5% 9% 2.75% 10%;
  height: 91.25%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContentHead = styled.div`
  width: 100%;
  min-height: 300px;
`;

export const LinkBlock = styled.div`
  margin: 10vh auto 0;

  width: 100%;
`;

export const Logo = styled.div`
  width: 84px;
  height: 32px;

  z-index: 4;

  background: url(${logo});

  cursor: pointer;
`;

export const ContentFooter = styled.div`
  width: 100%;
  height: 24px;

  color: ${(props) => props.theme.white.secondary};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

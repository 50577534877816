import styled from "styled-components";

export const Header = styled.div`
  margin: 0 0 40px;

  width: 100%;
  height: auto;

  font-weight: 500;
  font-size: 24px;
`;

export const LocationSelectWrapper = styled.div`
  margin: 40px 0 0;
  width: 218px;
  height: auto;
`;

export const Content = styled.div``;

export const PeriodWrapper = styled.div`
  margin: 35px 0;
`;

export const TabsWrapper = styled.div`
  margin: 35px 0 40px;
`;

export const InfoCardsWrapper = styled.div`
  margin: 40px 0 35px;
  width: 264px;
`;

export const EmptyPageWrapper = styled.div`
  margin: 9% 0 0;
`;

import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import { Theme } from "@6tamp/uikit";

const GlobalStyle = createGlobalStyle`
  ${normalize}

  body {
    color:${Theme.typo.primary};

    background-color: ${Theme.white.bg};
    
    // Из-за зависимости центрирования иконки галочки от размера шрифта - для всех строк таблицы это свойство должно быть одинаковым
    .Checkbox-Input {
      font-size: 14px;

      &:checked {
        background-color: ${Theme.main["100%"]};
        border-color: ${Theme.main["100%"]};

        &:hover {
          background-color: ${Theme.main.hover} !important;
          border-color: ${Theme.main.hover} !important;
        }
      }
    }

    // Отключение видимости DragNDrop ошибок
    .Tooltip_status_alert {
      display: none;
    }

    // При сдвоенном инпуте красить border между ними в нужный цвет
    .TextField_view_default.TextField_focus.TextField_form_clearDefault,
    .TextField_view_default.TextField_focus.TextField_form_clearBrick,
    .TextField_view_default.TextField_focus.TextField_form_clearRound {
      box-shadow: -1px 0 ${Theme.main["100%"]};
    }
    
    // Правки дизайна dropdown
    .SelectDropdown {
      border: 1px solid ${Theme.main["100%"]};
    }
    .SelectItem_hovered {
      background-color: ${Theme.main["15%"]};
    }
    .SelectItem_active {
      color: ${Theme.main["100%"]};
      background-color: inherit;
    }
    .SelectItem:not(.SelectItem_multiple).SelectItem_active::before {
      background-color: ${Theme.main["100%"]};
    }
    
    // Скрытие элемента reactour
    .reactour__helper {
      span {
        display: none;
      }
    }
    
    // Стили для модального окна тура
    .tour {
      max-height: 90vh;
      overflow: auto;
      
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }
    
    // Для отображения модалок поверх модалок тура
    .Modal {
      z-index: 4 !important;
    }
    
    // Меняем стили тура
    #___reactour {
      // цвет фона одинаков с цветом фона консты
      div:first-child{
        color: #002033;
        opacity: 0.85;
        z-index: 3;
      }
      
      // возвращаем стили модалке
      .reactour__helper {
        opacity: 1 !important;
        div:first-child {
          opacity: 1;
        }
      }
    }
  }
`;

export { GlobalStyle };

import { compareAsc } from "date-fns";
import { fieldTypes } from "../store/filter/reducer";

const MAX_DATE = new Date(8640000000000000);
const MIN_DATE = new Date(-8640000000000000);

function isValid(v) {
  return !(v === "" || v === null || v === undefined || Number.isNaN(v));
}

function isValueInRange(
  left = Number.NEGATIVE_INFINITY,
  value,
  right = Number.POSITIVE_INFINITY
) {
  return left <= value && value <= right;
}

function isDateInRange(leftDate = MIN_DATE, value, rightDate = MAX_DATE) {
  return !!(
    compareAsc(leftDate, value) <= 0 && compareAsc(value, rightDate) <= 0
  );
}

function doIfValidParam(param, callback) {
  if (isValid(param)) {
    return callback();
  } else {
    throw new TypeError();
  }
}

function isParamInRange(mode) {
  switch (mode) {
    case fieldTypes.Value:
      return (firstRangeValue, clientValue, lastRangeValue) =>
        doIfValidParam(clientValue, () =>
          isValueInRange(firstRangeValue, clientValue, lastRangeValue)
        );

    case fieldTypes.Date:
      return (firstRangeDate, clientDate, lastRangeDate) =>
        doIfValidParam(clientDate, () =>
          isDateInRange(firstRangeDate, clientDate, lastRangeDate)
        );

    default:
      throw new TypeError();
  }
}

const filterClients = (allClients, fields) => {
  return allClients.filter((obj) => {
    let result = true;
    for (const x in fields) {
      const { type, range } = fields[x];
      const firstRangeParam =
        range[0] === 0 || !!range[0] ? range[0] : undefined;
      const lastRangeParam =
        range[1] === 0 || !!range[1] ? range[1] : undefined;

      result =
        result && isParamInRange(type)(firstRangeParam, obj[x], lastRangeParam);
    }
    return result;
  });
};

export default filterClients;

/* IMPORT */
import messageBarReducer from "./messageBar/reducer";
import locationsReducer from "./locations/reducer";
import workersReducer from "./workers/reducer";
import settingsReducer from "./settings/reducer";
import authReducer from "./auth/reducer";
import clientsReducer from "./clients/reducer";
import filterReducer from "./filter/reducer";

export default {
  /* EXPORT */
  messageBar: messageBarReducer,
  locations: locationsReducer,
  workers: workersReducer,
  settings: settingsReducer,
  auth: authReducer,
  filter: filterReducer,
  clients: clientsReducer,
};

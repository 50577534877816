import React, { useEffect, useState } from "react";
import * as Styled from "./styled";
import { UploadButton } from "./styled";
import PropTypes from "prop-types";
import { getDimensions, validateDimensions } from "../../../utils";
import { useTranslation } from "react-i18next";

const UploadedImage = ({
  image,
  dimensions,
  maxWidth,
  maxHeight,
  removeImage,
}) => {
  return (
    <Styled.Image
      src={URL.createObjectURL(image)}
      width={dimensions[0]}
      height={dimensions[1]}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
    >
      <Styled.Close onClick={removeImage} />
    </Styled.Image>
  );
};

const DropZone = ({ extensions, onUpload, t, validWidth, validHeight }) => {
  return (
    <Styled.DragNDrop
      multiple={false}
      accept={extensions.map((ex) => `.${ex.toLowerCase()}`)}
      onDropFiles={onUpload}
    >
      {({ openFileDialog }) => (
        <>
          <Styled.DragTitle>
            {`${t("views.settings.zone_drag")} ${t("core.prepositions.or")} `}
            <UploadButton onClick={openFileDialog}>
              {t("views.settings.zone_upload")}
            </UploadButton>
          </Styled.DragTitle>
          <Styled.DragDescription>
            {t("views.settings.zone_formats") + " "}
            {extensions.reduce(
              (acc, cur) => `${acc}${acc ? "," : ""}${cur}`,
              ""
            )}
            ,{" "}
            {typeof validWidth === "number"
              ? `${validWidth}x${validHeight}px`
              : `${t("core.prepositions.from")} ${validWidth[0]}x${
                  validHeight[0]
                }px ${t("core.prepositions.to")} ${validWidth[1]}x${
                  validHeight[1]
                }px`}
          </Styled.DragDescription>
        </>
      )}
    </Styled.DragNDrop>
  );
};

const UploadField = ({
  image,
  setImage,
  setError,
  state,
  extensions,
  validWidth,
  validHeight,
  maxWidth,
  maxHeight,
}) => {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);

  const [dimensions, setDimensions] = useState([0, 0]);

  const onUpload = async (array) => {
    if (await validateDimensions(array[0], validWidth, validHeight)) {
      setImage(array[0]);
      setIsLoaded(true);
      setDimensions(await getDimensions(array[0]));
    } else {
      setError();
    }
  };

  const removeImage = () => {
    setIsLoaded(false);
    setImage(null);
  };

  useEffect(() => {
    const setData = async () => {
      image && setIsLoaded(true);
      image && setDimensions(await getDimensions(image));
    };
    setData();
  }, [image]);

  return (
    <Styled.UploadField state={state}>
      {isLoaded ? (
        <UploadedImage
          image={image}
          dimensions={dimensions}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          removeImage={removeImage}
        />
      ) : (
        <DropZone
          extensions={extensions}
          onUpload={onUpload}
          t={t}
          validWidth={validWidth}
          validHeight={validHeight}
        />
      )}
    </Styled.UploadField>
  );
};

export default UploadField;

UploadField.propTypes = {
  image: PropTypes.object,
  setImage: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  state: PropTypes.string,
  extensions: PropTypes.arrayOf(PropTypes.string).isRequired,
  validWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]).isRequired,
  validHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]).isRequired,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
};

UploadField.defaultProps = {
  state: "",
  maxWidth: 260,
  maxHeight: 100,
};

import { SET_PRESET, SET_FIELD, CLEAR_FIELDS } from "./types";
import { FILTER_CLIENTS, PREFILTER_CLIENTS } from "../clients/types";

export const setPreset = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_PRESET, payload: value });
    dispatch({ type: PREFILTER_CLIENTS, payload: value });
    dispatch({ type: FILTER_CLIENTS });
  };
};

export const setField = (name, values) => {
  return (dispatch) => {
    dispatch({ type: SET_FIELD, payload: { name, values } });
  };
};

export const clearFields = () => {
  return { type: CLEAR_FIELDS };
};

import styled from "styled-components";

export const PhoneField = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 5fr 8fr;
  gap: 0 10px;

  .Select {
    height: 40px;
  }
`;

import styled from "styled-components";

export const BackWrapper = styled.div`
  width: 77px;
  height: 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: ${({ theme }) => theme.main["100%"]};
  &:hover {
    color: ${({ theme }) => theme.main.hover};
  }

  cursor: pointer;
`;

export const Header = styled.div`
  margin: 40px 0 0;

  width: 100%;
  height: auto;

  font-weight: 500;
  font-size: 24px;
`;

export const PeriodWrapper = styled.div`
  margin: 40px 0 0;
`;

export const Widgets = styled.div`
  margin: 35px 0 0;

  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const InfoCardWrapper = styled.div`
  margin-right: 20px;
  width: 264px;
`;

export const ChartsWrapper = styled.div`
  margin: 45px 0 0;
  position: absolute;
  left: -20px;

  width: 80%;
  max-width: 1300px;
  height: auto;

  display: flex;
  justify-content: space-between;
`;

import apiService from "../../utils/apiService";
import { SET_SKELETON } from "./types";
import i18n from "../../localization/i18n";

const setSkeleton = (isSkeleton) => {
  return { type: SET_SKELETON, payload: isSkeleton };
};

export const getUserData = async (token) => {
  try {
    const { data } = await apiService.get(`/user/current`, {
      cancelToken: token,
    });
    return { result: true, fields: data };
  } catch (e) {
    return { result: false, msg: i18n.t("core.actions_msgs.server_error") };
  }
};

export const updateUserData = async (newData) => {
  try {
    const { data } = await apiService.get("/user/current");
    await apiService.put(`/user/${data["_id"]}`, newData);
    return { result: true, msg: i18n.t("core.actions_msgs.data_saved") };
  } catch (e) {
    return { result: false, msg: i18n.t("core.actions_msgs.server_error") };
  }
};

export const checkPassword = async (candidate) => {
  try {
    const { data } = await apiService.post("/user/checkpassword", {
      candidate,
    });
    const msg = !data.result && i18n.t("core.actions_msgs.wrong_password");
    return { result: data.result, msg };
  } catch (e) {
    return { result: false, msg: i18n.t("core.actions_msgs.server_error") };
  }
};

export const getChainData = async (token) => {
  try {
    const { data } = await apiService.get("/chain", { cancelToken: token });
    return { result: true, fields: data };
  } catch (e) {
    return { result: false, msg: i18n.t("core.actions_msgs.server_error") };
  }
};

export const updateChainData = async (newData) => {
  try {
    await apiService.put("/chain", newData);
    return { result: true, msg: i18n.t("core.actions_msgs.data_saved") };
  } catch (e) {
    return { result: false, msg: i18n.t("core.actions_msgs.server_error") };
  }
};

export const getCardData = (token) => async (dispatch) => {
  try {
    dispatch(setSkeleton(true));
    const { data } = await apiService.get("/card/template", {
      cancelToken: token,
    });
    dispatch(setSkeleton(false));
    return { result: true, fields: data };
  } catch (e) {
    dispatch(setSkeleton(false));
    return { result: false, msg: i18n.t("core.actions_msgs.server_error") };
  }
};

export const updateCardData = async (data) => {
  try {
    await apiService.post("card/template", data);
    return { result: true, msg: i18n.t("core.actions_msgs.data_saved") };
  } catch (e) {
    return { result: false, msg: i18n.t("core.actions_msgs.server_error") };
  }
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as PerStyled from "../styled";
import { toDataURL } from "qrcode";
import { Modal } from "@6tamp/uikit";
import { CopyBtn, LinkWrapper, QrCodeDownloadLink, Url } from "./styled";
import { useTranslation } from "react-i18next";

const LocationQrCodeModal = ({ id, close, isOpen }) => {
  const { t } = useTranslation();
  const [qrBase64, setQrBase64] = useState("");
  const [copyClicked, setCopyClicked] = useState(false);
  const url =
    process.env.REACT_APP_TYPE === "PROD"
      ? `https://reg.6tamp.ru/?id=${id}`
      : `https://reg.lip1.ru/?id=${id}`;

  useEffect(() => {
    toDataURL(url, { type: "png" }, (err, url) => setQrBase64(url));
  }, [id]);

  const onCopyBtnClick = () => {
    navigator.clipboard.writeText(url);
    setCopyClicked(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      title={t("views.locations.qr_code_location_modal_title")}
      description={t("views.locations.qr_code_location_modal_desc")}
    >
      <LinkWrapper>
        <Url>{url}</Url>
        <br />
        <br />
        <CopyBtn
          onClick={onCopyBtnClick}
          label={
            !copyClicked
              ? t("views.locations.qr_code_location_modal_copy")
              : t("views.locations.qr_code_location_modal_copied")
          }
        />
      </LinkWrapper>
      <PerStyled.ModalButtonWrapper>
        <QrCodeDownloadLink download={"qr.png"} href={qrBase64}>
          {t("views.locations.qr_code_location_modal_download")}
        </QrCodeDownloadLink>
      </PerStyled.ModalButtonWrapper>
    </Modal>
  );
};

LocationQrCodeModal.propTypes = {
  id: PropTypes.string,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
export default LocationQrCodeModal;

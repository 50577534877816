import styled from "styled-components";
import EmptyBoxImage from "./EmptyBox.svg";

export const Container = styled.main`
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.main.bg};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: grid;
  gap: 0 24px;
`;

export const Message = styled.div`
  justify-self: center;
  width: 351px;
  height: auto;
  display: grid;
  gap: 0 8px;
  justify-content: center;
`;

export const BoxImage = styled.div`
  width: 420px;
  height: 266px;
  background-image: url(${EmptyBoxImage});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  line-height: 28.8px;
  text-align: center;
`;

export const Description = styled.span`
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

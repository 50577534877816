import styled from "styled-components";

export const AgreementWrapper = styled.div`
  margin: 20px 0;

  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;

  color: ${(props) => props.theme.typo.secondary};
`;

export const AgreementLink = styled.a.attrs({
  target: "_blank",
  href: "https://6tamp.ru/policy",
})`
  text-decoration: underline;
  color: ${(props) => props.theme.typo.secondary};

  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.accent.hover};
  }
`;

export const InputWrapper = styled.div`
  margin: 20px 0 0;
`;

export const Form = styled.form`
  display: grid;
  grid-row-gap: 30px;
`;

export const FormInputsBlockWrapper = styled.div`
  display: grid;
  grid-row-gap: 20px;
`;

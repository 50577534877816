import React from "react";
import * as Styled from "./styled";
import * as PerStyled from "../styled";

const LanguageSelector = ({ lang, setLang, items, placeholder }) => {
  return (
    <Styled.LanguageSelector>
      <Styled.IconWrapper>
        <Styled.WebIcon />
      </Styled.IconWrapper>
      <PerStyled.Select
        view={"clear"}
        role={"secondary"}
        size={"xs"}
        value={lang}
        items={items}
        placeholder={placeholder}
        onChange={setLang}
      />
    </Styled.LanguageSelector>
  );
};

export default LanguageSelector;

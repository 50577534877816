import styled from "styled-components";

export const Icon = styled.div`
  min-width: 20px;
  min-height: 20px;

  border-radius: 5px;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  background-image: ${({ pic }) => `url(${pic})`};
`;

import React, { useEffect, useState } from "react";
import * as Styled from "./styled";
import { format } from "date-fns";
import { useClickOutside } from "../../../hooks";
import { Calendar, Button } from "@6tamp/uikit";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import i18n from "../../../localization/i18n";

function datesToString(dates) {
  return !!dates
    ? [
        !!dates[0] ? format(dates[0], "dd.MM.yyyy") : "",
        !!dates[1] ? format(dates[1], "dd.MM.yyyy") : "",
      ]
    : ["", ""];
}

const PeriodSelect = ({
  presets,
  selectedPreset,
  setSelectedPreset,
  dates,
  setDates,
}) => {
  const { t } = useTranslation();
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [ref, isClickedOutside] = useClickOutside();
  const [formattedDates, setFormattedDates] = useState(datesToString(dates));

  // Скрытие календаря при клике вне
  useEffect(() => {
    if (isClickedOutside) setIsCalendarVisible(false);
  }, [isClickedOutside]);

  // При внешнем изменении дат, менять значения дат ввиде строк
  useEffect(() => {
    setFormattedDates(datesToString(dates));
  }, [dates]);

  // При выборе только начальной даты, выбирать текущую как конечную дату
  useEffect(() => {
    if (!isCalendarVisible && !!dates[0] && !dates[1]) {
      setDates([dates[0], new Date()]);
    }
  }, [isCalendarVisible]);

  return (
    <Styled.PeriodSelect>
      <Styled.PresetsContainer>
        {presets.map((preset, index) => (
          <Button
            key={`${preset.id}_${index}`}
            name={preset.id}
            text={preset.label}
            onClick={() => {
              setDates([]);
              setSelectedPreset(preset);
            }}
            view={"soft"}
            size={"s"}
            isPressed={preset.id === selectedPreset.id}
          />
        ))}
      </Styled.PresetsContainer>
      <Styled.OR>{t("core.prepositions.or")}</Styled.OR>
      <Styled.DatePick ref={ref}>
        <Styled.CalendarPeriod
          onClick={() => {
            setIsCalendarVisible((value) => !value);
            setSelectedPreset({});
          }}
        >
          {(() => {
            if (!!formattedDates[0] || !!formattedDates[1]) {
              return (
                <span>
                  {formattedDates[0]} — {formattedDates[1]}
                </span>
              );
            } else {
              return <span>{t("views.statistics.period_select_btn")}</span>;
            }
          })()}
        </Styled.CalendarPeriod>
        {isCalendarVisible && (
          <Styled.CalendarWrapper>
            <Calendar
              dates={dates}
              setDates={setDates}
              locale={i18n.language}
              placeholder={t("core.inputs_defs.date.placeholder_default")}
            />
          </Styled.CalendarWrapper>
        )}
      </Styled.DatePick>
    </Styled.PeriodSelect>
  );
};

export default PeriodSelect;

PeriodSelect.propTypes = {
  presets: PropTypes.array.isRequired,
  selectedPreset: PropTypes.object,
  setSelectedPreset: PropTypes.func,
  dates: PropTypes.array,
  setDates: PropTypes.func,
};

import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as AuthStyled from "../../components/Authorization/styled";
import * as Styled from "../../components/Authorization/SignIn/styled";
import { routePaths } from "../../hooks";
import { AuthorizationPageWrapper } from "../../components";
import * as auth from "../../store/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { validation } from "../../utils";
import { TextField, Button, Link } from "@6tamp/uikit";
import { useTranslation } from "react-i18next";

const SignIn = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  const [loginResult, setLoginResult] = useState({ result: true });

  const isLoading = useSelector(({ auth }) => auth.isLoading);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("core.validation.email.invalid"))
        .required(t("core.validation.is_required")),
      password: Yup.string().required(t("core.validation.is_required")),
    }),
    onSubmit: async (values) => {
      const result = await dispatch(auth.login(values));
      !result.result && setLoginResult(result);
    },
  });

  const onFormChange = (e) => {
    setLoginResult({ result: true });
    formik.handleChange(e);
  };

  const isInvalidInput = (fieldName) =>
    validation.isError(formik, fieldName) || !loginResult.result ? "error" : "";

  const apiOrValidError = (fieldName) =>
    !loginResult.result ? loginResult.msg : formik.errors[fieldName];

  return (
    <AuthorizationPageWrapper>
      <AuthStyled.ContentWrapper>
        <div>
          <AuthStyled.ContentHead>
            <h2>{t("views.sign_in.form_title")}</h2>
          </AuthStyled.ContentHead>
          <AuthStyled.ContentBody>
            <Styled.Form onSubmit={formik.handleSubmit} onChange={onFormChange}>
              <Styled.FormInputsBlockWrapper>
                <TextField
                  id={"email"}
                  name={"email"}
                  value={formik.values.email}
                  placeholder={t("core.inputs_defs.email.label")}
                  width={"full"}
                  height={40}
                  state={isInvalidInput("email")}
                  errorText={!!formik.errors.email ? formik.errors.email : ""}
                />
                <TextField
                  id={"password"}
                  name={"password"}
                  width={"full"}
                  height={40}
                  password={true}
                  value={formik.values.password}
                  placeholder={t("core.inputs_defs.password.label")}
                  state={isInvalidInput("password")}
                  errorText={apiOrValidError("password")}
                />
                <Link
                  text={t("views.sign_in.btn_pass_reset")}
                  view={"secondary"}
                  width={"full"}
                  onClick={() => history.push(routePaths.recovery)}
                  behavior={"no_reload"}
                />
              </Styled.FormInputsBlockWrapper>
              <Button
                text={t("views.sign_in.btn_signin")}
                type={"submit"}
                width={"full"}
                round={true}
                isLoading={isLoading}
              />
            </Styled.Form>
          </AuthStyled.ContentBody>
        </div>
        <AuthStyled.Footer>
          <AuthStyled.FooterTitle>
            {t("views.sign_in.label_no_account")}
          </AuthStyled.FooterTitle>
          <Link
            text={t("views.sign_in.btn_signup")}
            behavior={"no_reload"}
            onClick={() => history.push(routePaths.signUp)}
          />
        </AuthStyled.Footer>
      </AuthStyled.ContentWrapper>
    </AuthorizationPageWrapper>
  );
};

export default SignIn;

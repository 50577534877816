import React from "react";
import { useRoutes, useAuthWall } from "./hooks";
import { Router, useHistory } from "react-router-dom";
import { LoadingOverlap } from "@6tamp/uikit";
import { Onboarding } from "./components";
import { useSelector } from "react-redux";
import "./localization/i18n";
import { AppErrorBoundary } from "./containers";

function App() {
  useAuthWall();
  const isReloadingPage = useSelector(
    ({ auth }) => auth.isLangFetching || auth.isAuthFetching
  );
  const routes = useRoutes();
  const history = useHistory();

  return (
    <AppErrorBoundary>
      {isReloadingPage ? (
        <LoadingOverlap />
      ) : (
        <Router history={history}>
          {routes}
          <Onboarding history={history} />
        </Router>
      )}
    </AppErrorBoundary>
  );
}

export default App;

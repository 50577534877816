import { ADD_API_MESSAGE, REMOVE_ITEM } from "./types";

export const removeItem = (key) => {
  return { type: REMOVE_ITEM, payload: key };
};

export const addApiMessage = (status, message) => {
  return (dispatch, getState) => {
    const key = getState().messageBar.items.length;
    const onClose = () => dispatch(removeItem(key));

    const newItem = {
      key,
      status,
      message,
      showProgress: undefined,
      autoClose: 5,
      onClose,
    };

    dispatch({ type: ADD_API_MESSAGE, payload: newItem });
  };
};

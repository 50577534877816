import styled from "styled-components";
import { Sidebar as UISidebar } from "@consta/uikit/__internal__/src/components/Sidebar/Sidebar";

export const FilterBar = styled(UISidebar).attrs({
  position: "right",
  hasOverlay: false,
})`
  width: 22vw;
  min-width: 360px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  padding: 32px 30px 50px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  filter: drop-shadow(0px 8px 8px rgba(0, 32, 51, 0.04))
    drop-shadow(0px 12px 28px rgba(0, 32, 51, 0.12));

  box-sizing: border-box;

  overflow: auto;
`;

export const ContentHeader = styled.div`
  margin: 0 0 40px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
`;

export const ContentBody = styled.div``;

export const FilterWrapper = styled.div`
  margin: 0 0 30px;
`;

export const FilterTitle = styled.div`
  margin: 0 0 15px;

  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  color: ${({ theme }) => theme.typo.secondary};
`;

export const FilterInputWrapper = styled.div`
  width: 80%;
`;

export const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

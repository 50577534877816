import React from "react";
import { useFormik } from "formik";

export function haveFieldError(field) {
  if (this.errors[field]) {
    return this.dirty
      ? this.touched.hasOwnProperty(field) && this.touched[field]
      : this.touched.hasOwnProperty(field);
  }
  return false;
}

function useForm(initialValues, validationSchema, onSubmit) {
  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  form.haveFieldError = haveFieldError;
  return form;
}

export default useForm;

import React, { useState } from "react";
import * as Styled from "../../components/Personal/Settings/styled";
import * as PerStyled from "../../components/Personal/styled";
import * as SettingsStyled from "../../components/Personal/Settings/styled";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateUserData, checkPassword } from "../../store/settings/actions";
import { addApiMessage } from "../../store/messageBar/actions";
import { useDispatch } from "react-redux";
import { validation } from "../../utils";
import { TextField, Button } from "@6tamp/uikit";
import { useTranslation } from "react-i18next";

const ChangePassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState();

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .nullable()
        .required(t("core.validation.is_required")),
      newPassword: Yup.string()
        .nullable()
        .min(8, t("core.validation.password.short"))
        .max(32, t("core.validation.password.long"))
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
          t("core.validation.password.invalid_hard")
        )
        .required(t("core.validation.is_required")),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const checkData = await checkPassword(values.oldPassword);
      if (checkData.result) {
        const updateData = await updateUserData({
          password: values.newPassword,
        });
        dispatch(
          addApiMessage(updateData.result ? "success" : "alert", updateData.msg)
        );
      } else {
        dispatch(addApiMessage("alert", checkData.msg));
      }
      setLoading(false);
    },
  });

  return (
    <Styled.Form onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
      <SettingsStyled.FormTitle>
        <PerStyled.WorkspaceTitle>
          <h2>{t("views.settings.navbar_labels.change_password")}</h2>
        </PerStyled.WorkspaceTitle>
      </SettingsStyled.FormTitle>
      <Styled.PasswordInputBlockWrapper>
        <TextField
          id={"oldPassword"}
          name={"oldPassword"}
          placeholder={t("core.inputs_defs.password.label_old")}
          width={"full"}
          value={formik.values.oldPassword}
          height={40}
          state={validation.isError(formik, "oldPassword") ? "error" : ""}
          password={true}
          errorText={formik.errors.oldPassword}
        />
        <TextField
          id={"newPassword"}
          name={"newPassword"}
          placeholder={t("core.inputs_defs.password.label_new")}
          width={"full"}
          value={formik.values.newPassword}
          height={40}
          state={validation.isError(formik, "newPassword") ? "error" : ""}
          password={true}
          errorText={formik.errors.newPassword}
        />
      </Styled.PasswordInputBlockWrapper>
      <SettingsStyled.ButtonWrapper>
        <Button
          text={t("views.settings.btn_submit")}
          type={"submit"}
          width={"full"}
          round={true}
          isLoading={isLoading}
          disabled={!formik.dirty}
        />
      </SettingsStyled.ButtonWrapper>
    </Styled.Form>
  );
};

export default ChangePassword;

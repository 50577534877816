import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getWorkerStat, getWorkerInfo } from "../../store/workers/actions";
import * as Styled from "../../components/Personal/WorkerStat/styled";
import * as PerStyled from "../../components/Personal/styled";
import { PageWrapper, PeriodSelect } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { routePaths } from "../../hooks";
import { IconBackward } from "@consta/uikit/IconBackward";
import { InfoCard, Chart } from "@6tamp/uikit";
import { format, isValid, parse, sub } from "date-fns";
import { useTranslation } from "react-i18next";
import { useMetrika } from "yandex-metrika-react";

const form = (date) => {
  return isValid(date) ? format(date, "yyyy-MM-dd") : "";
};

const wrapData = (fetchedData, key) => {
  const wrappedData = [];
  for (let x in fetchedData) {
    const day = {};
    day.date = format(parse(x, "yyyy-MM-dd", new Date()), "dd.MM");
    day[key] = fetchedData[x];
    wrappedData.push(day);
  }
  return wrappedData;
};

const WorkerStat = () => {
  const ym = useMetrika();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { name, lastName, isSkeleton } = useSelector(
    ({ workers }) => workers.worker
  );
  const userId = useSelector(({ auth }) => auth?.id);

  const periodPresets = [
    {
      id: "week",
      label: t("views.statistics.presets_labels.week"),
      dateFrom: form(sub(new Date(), { days: 7 })),
    },
    {
      id: "month",
      label: t("views.statistics.presets_labels.month"),
      dateFrom: form(sub(new Date(), { days: 30 })),
    },
    {
      id: "threeMonths",
      label: t("views.statistics.presets_labels.threeMonths"),
      dateFrom: form(sub(new Date(), { days: 90 })),
    },
  ];

  const [selectedPreset, setSelectedPreset] = useState(periodPresets[0]);
  const [stampsData, setStampsData] = useState([{ date: "01.01", stamps: 0 }]);
  const [giftsData, setGiftsData] = useState([{ date: "01.01", gifts: 0 }]);
  const [accruedStamps, setAccruedStamps] = useState(0);
  const [issuedGifts, setIssuedGifts] = useState(0);
  const [dates, setDates] = useState([null, null]);

  useEffect(() => {
    const fetch = async () => {
      await dispatch(getWorkerInfo(id));
    };
    fetch();

    ym("reachGoal", "empStat", { userId });
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const [fetchedStampsData, fetchedGiftsData] = await dispatch(
        !!Object.keys(selectedPreset).length
          ? getWorkerStat(id, selectedPreset.dateFrom, form(new Date()))
          : !!dates[1]
          ? getWorkerStat(
              id,
              form(dates[0], "dd.MM.yyyy", new Date()),
              form(dates[1], "dd.MM.yyyy", new Date())
            )
          : {}
      );
      setStampsData(wrapData(fetchedStampsData, "stamps"));
      setGiftsData(wrapData(fetchedGiftsData, "gifts"));
      setAccruedStamps(
        Object.values(fetchedStampsData).reduce((acc, cur) => acc + cur, 0)
      );
      setIssuedGifts(
        Object.values(fetchedGiftsData).reduce((acc, cur) => acc + cur, 0)
      );
    };
    fetch();
  }, [selectedPreset, dates]);

  return (
    <PageWrapper>
      <PerStyled.Workspace>
        <Styled.BackWrapper onClick={() => history.push(routePaths.workers)}>
          <IconBackward />
          <div>{t("views.workers.btn_go_back")}</div>
        </Styled.BackWrapper>
        <Styled.Header>
          {`${t("views.workers.worker_stat_header")} ${lastName} ${name}:`}
        </Styled.Header>
        <Styled.PeriodWrapper>
          <PeriodSelect
            presets={periodPresets}
            selectedPreset={selectedPreset}
            setSelectedPreset={setSelectedPreset}
            dates={dates}
            setDates={setDates}
          />
        </Styled.PeriodWrapper>
        <Styled.Widgets>
          <Styled.InfoCardWrapper>
            <InfoCard
              value={accruedStamps}
              title={t("views.workers.worker_stat_accrued_stamps")}
              isSkeleton={isSkeleton}
            />
          </Styled.InfoCardWrapper>
          <Styled.InfoCardWrapper>
            <InfoCard
              value={issuedGifts}
              title={t("views.workers.worker_stat_issued_gifts")}
              isSkeleton={isSkeleton}
            />
          </Styled.InfoCardWrapper>
        </Styled.Widgets>
        <Styled.ChartsWrapper>
          <Chart
            data={stampsData}
            xKey={"date"}
            yKey={"stamps"}
            type={"monotone"}
            header={t("views.workers.worker_stat_charts.stamps.title")}
            valueDesc={t("views.workers.worker_stat_charts.stamps.value_label")}
            width={568}
            isSkeleton={isSkeleton}
          />
          <Chart
            data={giftsData}
            xKey={"date"}
            yKey={"gifts"}
            type={"monotone"}
            header={t("views.workers.worker_stat_charts.gifts.title")}
            valueDesc={t("views.workers.worker_stat_charts.gifts.value_label")}
            width={568}
            isSkeleton={isSkeleton}
          />
        </Styled.ChartsWrapper>
      </PerStyled.Workspace>
    </PageWrapper>
  );
};

WorkerStat.propTypes = {
  close: PropTypes.func,
};

export default WorkerStat;

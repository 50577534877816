import React from "react";
import * as Styled from "./styled";
import { useTranslation } from "react-i18next";

const ErrorHandler = () => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Wrapper>
          <Styled.BoxImage />
          <Styled.Message>
            <Styled.Title>{t("views.error_handler.title")}</Styled.Title>
            <Styled.Description>
              {t("views.error_handler.desc")}
            </Styled.Description>
          </Styled.Message>
        </Styled.Wrapper>
      </Styled.Content>
    </Styled.Container>
  );
};

export default ErrorHandler;

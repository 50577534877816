import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import * as SettingsStyled from "../../components/Personal/Settings/styled";
import PropTypes from "prop-types";
import { updateUserData } from "../../store/settings/actions";
import { addApiMessage } from "../../store/messageBar/actions";
import { useDispatch } from "react-redux";
import { addMaskSymbols, separatePhone } from "../../utils";
import * as PerStyled from "../../components/Personal/styled";
import { TextField, Button } from "@6tamp/uikit";
import { useTranslation } from "react-i18next";
import { PhoneField } from "../../components";
import { useForm } from "../../hooks";
import {
  countryPhonesDictionary,
  getMaskByCode,
} from "../../localization/i18n";

const codeItems = Object.values(countryPhonesDictionary).map(
  ({ label, code, mask }, i) => ({
    id: i + 1,
    label: `${label} ${code}`,
    code,
  })
);

function useAccountSettingsForm(onSubmit) {
  const { t } = useTranslation();
  const validPhoneLengthRef = useRef(0);

  const validationRefs = {
    phone: {
      length: validPhoneLengthRef,
    },
  };

  const form = useForm(
    {
      email: "",
      code: "",
      phone: "",
    },
    Yup.object({
      code: Yup.string().required(t("core.validation.is_required")),
      email: Yup.string()
        .nullable()
        .email(t("core.validation.email.invalid"))
        .max(30, t("core.validation.email.long"))
        .required(t("core.validation.is_required")),
      phone: Yup.string()
        .nullable()
        .length(
          validationRefs.phone.length.current,
          t("core.validation.phone.invalid")
        )
        .required(t("core.validation.is_required")),
    }),
    onSubmit
  );
  form.setFieldValidation = function (field, validationField, newValidValue) {
    validationRefs[field][validationField].current = newValidValue;
  };

  return form;
}

const AccountSettings = ({ data, updateData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async ({ email, code, phone }) => {
    const newData = {
      email: email,
      phone: `${code} ${phone}`.replace(/\D/g, ""),
    };
    setLoading(true);
    const data = await updateUserData(newData);
    setLoading(false);
    data.result
      ? dispatch(addApiMessage("success", data.msg))
      : dispatch(addApiMessage("alert", data.msg));
    updateData();
  };

  const form = useAccountSettingsForm(onSubmit);

  useEffect(() => {
    const [code, phone] = separatePhone(data?.phone);
    form.setFieldValue("email", data?.email ?? "", false);
    form.setFieldValue("code", code ?? "", false);
    form.setFieldValue(
      "phone",
      addMaskSymbols(phone ?? "", getMaskByCode(code)),
      false
    );
    form.setFieldValidation("phone", "length", getMaskByCode(code).length);
  }, [data]);

  return (
    <SettingsStyled.Form onSubmit={form.handleSubmit}>
      <SettingsStyled.FormTitle>
        <PerStyled.WorkspaceTitle>
          <h2>{t("views.settings.navbar_labels.account_settings")}</h2>
        </PerStyled.WorkspaceTitle>
      </SettingsStyled.FormTitle>
      <SettingsStyled.FormContent>
        <SettingsStyled.InputItem>
          <SettingsStyled.InputLabel>
            {t("core.inputs_defs.email.label")}
          </SettingsStyled.InputLabel>
          <TextField
            name={"email"}
            placeholder={t("core.inputs_defs.email.label")}
            width={"full"}
            height={40}
            value={form.values.email}
            state={form.haveFieldError("email") ? "error" : ""}
            errorText={form.errors.email}
            onChange={(e) => form.setFieldValue("email", e.target.value)}
          />
        </SettingsStyled.InputItem>
        <SettingsStyled.InputItem>
          <SettingsStyled.InputLabel>
            {t("core.inputs_defs.phone.label")}
          </SettingsStyled.InputLabel>
          <PhoneField
            name={"phone"}
            codeItems={codeItems}
            maskWithoutCode={getMaskByCode(form.values.code)}
            phone={{
              code: form.values.code,
              maskedValue: form.values.phone,
            }}
            setPhone={({ code, maskedValue }) => {
              const newValidLength = getMaskByCode(code).length;
              if (code.length !== form.values.length) {
                form.setFieldValidation("phone", "length", newValidLength);
              }
              form.setFieldValue("code", code);
              form.setFieldValue("phone", maskedValue.slice(0, newValidLength));
            }}
            placeholder={t("core.inputs_defs.phone.label")}
            errorText={form.errors.phone}
            state={form.haveFieldError("phone") ? "error" : ""}
          />
        </SettingsStyled.InputItem>
        <SettingsStyled.ButtonWrapper>
          <Button
            text={t("views.settings.btn_submit")}
            type={"submit"}
            width={"full"}
            isLoading={isLoading}
            round={true}
            disabled={!form.values.email && !form.values.phone}
          />
        </SettingsStyled.ButtonWrapper>
      </SettingsStyled.FormContent>
    </SettingsStyled.Form>
  );
};

export default AccountSettings;

AccountSettings.propTypes = {
  data: PropTypes.object,
  updateData: PropTypes.func.isRequired,
};

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, Global } from "@6tamp/uikit";
import { GlobalStyle } from "./style";
import { Provider } from "react-redux";
import store from "./store";
import { YandexMetrika } from "yandex-metrika-react";

// На некоторых устройствах(ios & safari :)) неправильно высчитывалась высота экрана, поэтому создаём собственную переменную
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

// We listen to the resize event
window.addEventListener("resize", () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

ReactDOM.render(
  <YandexMetrika
    counterId={process.env.REACT_APP_TYPE === "PROD" ? 87270948 : 87271055}
    options={{
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      triggerEvent: true,
    }}
  >
    <Provider store={store}>
      <Router>
        <ThemeProvider>
          <GlobalStyle />
          <Global />
          <App />
        </ThemeProvider>
      </Router>
    </Provider>
  </YandexMetrika>,
  document.getElementById("root")
);

import { useEffect, useState } from "react";
import * as auth from "../store/auth/actions";
import { useDispatch } from "react-redux";

const useResendVerification = () => {
  const timeout = 60;
  const dispatch = useDispatch();
  const [available, setAvailable] = useState(false);
  const [timerValue, setTimerValue] = useState(timeout);

  useEffect(() => {
    let timeoutId;
    if (!available) {
      timeoutId = setTimeout(() => {
        setAvailable(true);
      }, timeout * 1000);
    }

    return () => clearTimeout(timeoutId);
  }, [available]);

  useEffect(() => {
    const intId = setInterval(() => setTimerValue(timerValue - 1), 1000);
    return () => clearInterval(intId);
  }, [timerValue]);

  const reset = async () => {
    setTimerValue(timeout);
    setAvailable(false);
    await dispatch(auth.sendConfirmationCode());
  };

  return { available, reset, timerValue };
};

export default useResendVerification;

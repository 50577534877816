import styled from "styled-components";
import { Container } from "react-grid-system";

import { Row, Col } from "../styled";

export const PageWrapper = styled(Container).attrs({
  fluid: true,
})`
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  padding-left: 0 !important;
  padding-right: 0 !important;

  background-color: ${(props) => props.theme.white.bg};
  position: relative;

  box-sizing: border-box;
  overflow: hidden;

  h2 {
    margin: 0;

    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28.8px;

    color: ${({ theme }) => theme.typo.primary};
  }

  .SnackBar-Item_status_success {
    background-color: ${({ theme }) => theme.success};
  }

  .SnackBar-Item_status_alert {
    background-color: ${({ theme }) => theme.error};
  }
`;

export const PageRow = styled(Row)`
  width: 100%;
  height: 100%;
`;

export const Workspace = styled(Col).attrs({
  sm: 10,
})`
  width: 100%;
  height: 100%;

  overflow-y: auto;

  padding: 3.9vh 2.08vw 3.5vh !important;
`;

import React, { useEffect, useState } from "react";
import {
  CreateLocationModal,
  CreateUserModal,
  PageWrapper,
  TourWrapper,
} from "../../components";
import * as Styled from "../../components/Personal/Workers/styled";
import * as PerStyled from "../../components/Personal/styled";
import { ActiveUsers, TrashUsers } from "../index";
import axios from "axios";
import { loadUsers } from "../../store/workers/actions";
import { useDispatch, useSelector } from "react-redux";
import { EmptyPage, Button } from "@6tamp/uikit";
import { loadLocations } from "../../store/locations/actions";
import { stepsIds } from "../../components/Personal/Onboarding/Onboarding";
import { useTranslation } from "react-i18next";

const navIds = Object.freeze({
  ACTIVE: Symbol("views.workers.navbar_active_workers"),
  TRASH: Symbol("views.workers.navbar_trash_workers"),
});

const Workers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const columnsDef = [
    {
      key: "type",
      label: t("views.workers.columns_labels.type"),
      order: 1,
    },
    {
      key: "name",
      label: t("views.workers.columns_labels.name"),
      order: 2,
    },
    {
      key: "lastName",
      label: t("views.workers.columns_labels.last_name"),
      order: 3,
    },
    {
      key: "email",
      label: t("core.inputs_defs.email.label"),
      order: 4,
    },
    {
      key: "locationName",
      label: t("views.workers.columns_labels.location_name"),
      order: 5,
    },
  ];

  const [addFirstUserModalVisible, setAddFirstUserModalVisible] =
    useState(false);
  const [addFirstLocationModalVisible, setAddFirstLocationModalVisible] =
    useState(false);
  const [nav, setNav] = useState(navIds.ACTIVE);

  const {
    isLoading: isLocationsLoading,
    active: { loadedLocations: activeLocations },
  } = useSelector(({ locations }) => locations);

  const {
    isLoading: isUsersLoading,
    active: { loadedUsers: loadedActiveUsers },
    trash: { loadedUsers: loadedTrashUsers },
  } = useSelector(({ workers }) => workers);

  useEffect(() => {
    const sourceLocations = axios.CancelToken.source();
    const sourceUsers = axios.CancelToken.source();
    dispatch(loadLocations(sourceLocations.token));
    dispatch(loadUsers(sourceUsers.token));
    // Отмена запроса при смене роута для избежания memory leak
    return () => {
      sourceLocations.cancel();
      sourceUsers.cancel();
    };
  }, []);

  const workersDictionary = {
    [navIds.ACTIVE]: (
      <ActiveUsers
        desc={t(navIds.ACTIVE.description)}
        columnsDef={columnsDef}
      />
    ),
    [navIds.TRASH]: (
      <TrashUsers desc={t(navIds.TRASH.description)} columnsDef={columnsDef} />
    ),
  };

  if (
    !isUsersLoading &&
    !loadedTrashUsers.length &&
    !loadedActiveUsers.length
  ) {
    if (!isLocationsLoading && !activeLocations.length) {
      return (
        <PageWrapper>
          <PerStyled.Workspace>
            <PerStyled.BackgroundContent>
              <EmptyPage
                variant={"NotCreated"}
                header={t("views.workers.empty_page_not_created_header")}
                description={t("views.workers.empty_page_not_created_desc")}
              />
              <TourWrapper tourId={stepsIds[4]}>
                <Button
                  width={"full"}
                  round={true}
                  text={t("views.workers.btn_create_location")}
                  onClick={() => {
                    setAddFirstLocationModalVisible(true);
                  }}
                />
              </TourWrapper>
              <CreateLocationModal
                isOpen={addFirstLocationModalVisible}
                close={() => {
                  setAddFirstLocationModalVisible(false);
                }}
              />
            </PerStyled.BackgroundContent>
          </PerStyled.Workspace>
        </PageWrapper>
      );
    } else {
      return (
        <PageWrapper>
          <PerStyled.Workspace>
            <PerStyled.BackgroundContent>
              <EmptyPage
                variant={"NotExists"}
                header={t("views.workers.empty_page_not_exists_header")}
                description={""}
              />
              <TourWrapper tourId={stepsIds[4]}>
                <Button
                  width={"full"}
                  round={true}
                  text={t("views.workers.btn_create_worker")}
                  onClick={() => setAddFirstUserModalVisible(true)}
                />
              </TourWrapper>
              <CreateUserModal
                isOpen={addFirstUserModalVisible}
                close={() => setAddFirstUserModalVisible(false)}
              />
            </PerStyled.BackgroundContent>
          </PerStyled.Workspace>
        </PageWrapper>
      );
    }
  }

  return (
    <PageWrapper>
      <PerStyled.Workspace>
        <Styled.Tabs
          value={nav}
          onChange={({ value }) => setNav(value)}
          items={Object.values(navIds)}
          getLabel={(item) => t(item.description)}
        />
        {workersDictionary[nav]}
      </PerStyled.Workspace>
    </PageWrapper>
  );
};

export default Workers;

import React, { useEffect, useState } from "react";
import * as Styled from "../../components/Personal/Locations/styled";
import * as PerStyled from "../../components/Personal/styled";
import {
  CreateLocationModal,
  PageWrapper,
  TourWrapper,
} from "../../components";
import { wrapLocationPaidDate } from "../../utils";
import { ActiveLocations, TrashLocations } from "../index";
import axios from "axios";
import { loadLocations } from "../../store/locations/actions";
import { useDispatch, useSelector } from "react-redux";
import { EmptyPage, Button } from "@6tamp/uikit";
import { stepsIds } from "../../components/Personal/Onboarding/Onboarding";
import { useTranslation } from "react-i18next";

const navIds = Object.freeze({
  ACTIVE: Symbol("views.locations.navbar_active_locations"),
  TRASH: Symbol("views.locations.navbar_trash_locations"),
});

const Locations = () => {
  const { t } = useTranslation();

  const columnsDef = [
    {
      key: "title",
      label: t("views.locations.columns_labels.title"),
      order: 1,
    },
    {
      key: "address",
      label: t("views.locations.columns_labels.address"),
      order: 2,
    },
    {
      key: "paidUntil",
      label: t("views.locations.columns_labels.paid_until"),
      order: 3,
      modifier: wrapLocationPaidDate,
    },
  ];

  const [addFirstLocationModalVisible, setAddFirstLocationModalVisible] =
    useState(false);
  const [nav, setNav] = useState(navIds.ACTIVE);
  const dispatch = useDispatch();

  const {
    isLoading,
    active: { loadedLocations: loadedActiveLocations },
    trash: { loadedLocations: loadedTrashLocations },
  } = useSelector(({ locations }) => locations);

  useEffect(() => {
    const source = axios.CancelToken.source();
    dispatch(loadLocations(source.token));
    return () => {
      source.cancel();
    };
  }, []);

  if (
    !isLoading &&
    !loadedActiveLocations.length &&
    !loadedTrashLocations.length
  ) {
    return (
      <PageWrapper>
        <PerStyled.Workspace>
          <PerStyled.BackgroundContent>
            <EmptyPage
              variant={"NotExists"}
              header={t("views.locations.empty_page_header")}
              description={""}
            />
            <TourWrapper tourId={stepsIds[3]}>
              <Button
                width={"full"}
                text={t("views.locations.btn_opn_create_location_modal")}
                onClick={() => setAddFirstLocationModalVisible(true)}
              />
            </TourWrapper>
            <CreateLocationModal
              isOpen={addFirstLocationModalVisible}
              close={() => setAddFirstLocationModalVisible(false)}
            />
          </PerStyled.BackgroundContent>
        </PerStyled.Workspace>
      </PageWrapper>
    );
  }

  const locationsDictionary = {
    [navIds.ACTIVE]: (
      <ActiveLocations
        desc={t(navIds.ACTIVE.description)}
        columnsDef={columnsDef}
      />
    ),
    [navIds.TRASH]: (
      <TrashLocations
        desc={t(navIds.TRASH.description)}
        columnsDef={columnsDef}
      />
    ),
  };

  return (
    <PageWrapper>
      <PerStyled.Workspace>
        <Styled.Tabs
          value={nav}
          onChange={({ value }) => setNav(value)}
          items={Object.values(navIds)}
          getLabel={(item) => t(item.description)}
        />
        {locationsDictionary[nav]}
      </PerStyled.Workspace>
    </PageWrapper>
  );
};

export default Locations;

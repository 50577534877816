import produce from "immer";
import {
  RESET_FILTERED_LIST,
  SET_FILTERED_LIST,
  LOAD_LOCATIONS,
  SET_LOADING,
  SET_SKELETON,
} from "./types";

const getSearchIndex = (data) => {
  const result = {};
  for (let key in data) {
    const { title, address } = data[key];
    result[`${title.toLowerCase()} ${address.toLowerCase()}`] = key;
    result[`${address.toLowerCase()} ${title.toLowerCase()}`] = key;
  }
  return result;
};

const initialState = {
  isLoading: false,
  active: {
    isSkeleton: false,
    loadedLocations: [],
    filteredLocations: [],
    searchIndex: getSearchIndex([]),
  },
  trash: {
    isSkeleton: false,
    loadedLocations: [],
    filteredLocations: [],
    searchIndex: getSearchIndex([]),
  },
};

const locationsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  return produce(state, (draft) => {
    switch (type) {
      case SET_FILTERED_LIST:
        draft[payload.mode].filteredLocations = payload.data;
        return draft;

      case RESET_FILTERED_LIST:
        draft[payload.mode].filteredLocations =
          state[payload.mode].loadedLocations;
        return draft;

      case LOAD_LOCATIONS:
        draft[payload.mode].loadedLocations = payload.data;
        draft[payload.mode].filteredLocations = payload.data;
        draft[payload.mode].searchIndex = getSearchIndex(payload.data);
        return draft;

      case SET_LOADING:
        draft.isLoading = payload;
        return draft;

      case SET_SKELETON:
        draft[payload.mode].isSkeleton = payload.value;
        return draft;

      default:
        return state;
    }
  });
};

export default locationsReducer;

import styled from "styled-components";

export const FormWrapper = styled.div`
  margin: 0 0 50px;

  position: absolute;
  left: 51%;
  top: calc((93vh - min(65vh, 642px)) / 2);

  width: 37.5vw;
  height: 65vh;

  max-width: 541px;
  max-height: 642px;

  border-radius: 4px;

  box-shadow: 0 2px 2px rgba(0, 32, 51, 0.02), 0 2px 8px rgba(0, 32, 51, 0.16);
  -moz-box-shadow: 0 2px 2px rgba(0, 32, 51, 0.02),
    0 2px 8px rgba(0, 32, 51, 0.16);
  -webkit-box-shadow: 0 2px 2px rgba(0, 32, 51, 0.02),
    0 2px 8px rgba(0, 32, 51, 0.16);

  background-color: ${(props) => props.theme.white.primary};

  padding: 70px 0 58px;

  z-index: 4;

  h2 {
    margin: 0 auto;

    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
  }
  h3 {
    margin: 30px auto 0;

    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    color: ${(props) => props.theme.typo.secondary};
  }
`;

export const ContentWrapper = styled.div`
  height: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContentHead = styled.div`
  width: calc(min(541px, 37.5vw) - 40px);
  margin: 0 auto 40px;
`;

export const ContentBody = styled.div`
  margin: 20px auto 0;
  width: 60%;

  background-color: inherit;
`;

export const Footer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const FooterTitle = styled.div`
  margin: 10px 0 8px;

  color: ${(props) => props.theme.typo.secondary};
`;

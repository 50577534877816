import React from "react";
import * as PerStyled from "../styled";

import { IconClose } from "@consta/uikit/IconClose";
import PropTypes from "prop-types";

const TagIconCancel = ({ onClick }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <div>
      <PerStyled.TagIcon>
        <IconClose size={"s"} onClick={handleClick} />
      </PerStyled.TagIcon>
    </div>
  );
};

export default TagIconCancel;

TagIconCancel.propTypes = {
  onClick: PropTypes.func,
};

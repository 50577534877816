import styled from "styled-components";

export const RecoveryLinkWrapper = styled.div`
  margin: 0 auto 15px;
`;

export const Form = styled.form`
  display: grid;
  grid-row-gap: 40px;
`;

export const FormInputsBlockWrapper = styled.div`
  display: grid;
  grid-row-gap: 20px;
`;

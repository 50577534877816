import styled from "styled-components";
import { Button } from "@consta/uikit/Button";

export const Url = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

export const CopyBtn = styled(Button).attrs({
  view: "clear",
})`
  font-weight: bold;
  font-size: 16px;
  color: ${({ theme }) => theme.main["100%"]};

  :hover {
    background: none;
    color: ${({ theme }) => theme.main.hover};
  }

  &:focus {
    box-shadow: none !important;
  }
`;

export const LinkWrapper = styled.div`
  text-align: center;
  padding: 10px 0;
  border: 2px dashed ${({ theme }) => theme.border.default};
`;

export const QrCodeDownloadLink = styled.a`
  &:hover {
    background-color: ${(props) => props.theme.accent.hover} !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  background-color: ${(props) => props.theme.accent.default};
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};

  text-decoration: none;
  color: white;
  padding: 11px 0;
  border-radius: 99px;
  font-size: 16px;
  margin: auto 0;
  text-align: center;
  display: block;

  width: 100%;
`;

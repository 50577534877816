import { countryPhonesDictionary } from "../localization/i18n";

export const addMaskSymbols = (phone, mask) => {
  if (mask) {
    let pos = 0;
    return mask
      .split("")
      .map((el) => (el === "9" ? phone[pos++] ?? 0 : el))
      .join("");
  } else {
    return phone;
  }
};

export function separatePhone(phone) {
  phone = phone ?? "";
  const codes = Object.values(countryPhonesDictionary).map((obj) =>
    obj.code.replace(/\D/g, "")
  );
  for (let code of codes) {
    if (phone.slice(0, code.length) === code) {
      return [`+${phone.slice(0, code.length)}`, phone.slice(code.length)];
    }
  }
  return [undefined, undefined];
}

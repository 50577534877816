import React, { useState, useRef } from "react";
import * as Yup from "yup";
import * as Styled from "../../components/Authorization/SignUp/styled";
import * as AuthStyled from "../../components/Authorization/styled";
import * as auth from "../../store/auth/actions";
import { routePaths } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AuthorizationPageWrapper, PhoneField } from "../../components";
import { validation } from "../../utils";
import { TextField, Button, Link } from "@6tamp/uikit";
import { useTranslation } from "react-i18next";
import { useForm } from "../../hooks";
import getUserLanguage from "../../utils/getUserLanguage";
import {
  countryPhonesDictionary,
  getMaskByCode,
} from "../../localization/i18n";

const codeItems = Object.values(countryPhonesDictionary).map(
  ({ label, code, mask }, i) => ({
    id: i + 1,
    label: `${label} ${code}`,
    code,
  })
);

function useSignUpForm(onSubmit) {
  const { t } = useTranslation();
  const validPhoneLengthRef = useRef(
    countryPhonesDictionary[getUserLanguage()[0]].mask.length
  );
  const validRefs = {
    phone: {
      length: validPhoneLengthRef,
    },
  };
  const form = useForm(
    {
      email: "",
      code: countryPhonesDictionary[getUserLanguage()[0]].code,
      phone: "",
      password: "",
    },
    Yup.object({
      email: Yup.string()
        .email(t("core.validation.email.invalid"))
        .required(t("core.validation.is_required")),
      phone: Yup.string()
        .length(
          validRefs.phone.length.current,
          t("core.validation.phone.invalid")
        )
        .required(t("core.validation.is_required")),
      password: Yup.string()
        .min(8, t("core.validation.password.short"))
        .max(32, t("core.validation.password.long"))
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
          t("core.validation.password.invalid_hard")
        )
        .required(t("core.validation.is_required")),
    }),
    onSubmit
  );
  form.setFieldValidation = function (field, validationField, value) {
    validRefs[field][validationField].current = value;
  };

  return form;
}

const SignUp = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [registerResult, setRegisterResult] = useState({ result: true });
  const isLoading = useSelector(({ auth }) => auth.isLoading);

  const onSubmit = async (values) => {
    const { email, code, phone, password } = values;
    const result = await dispatch(
      auth.register({
        email,
        phone: `${code} ${phone}`.replace(/\D/g, ""),
        password,
      })
    );
    !result.result && setRegisterResult(result);
  };

  const form = useSignUpForm(onSubmit);

  const onFormChange = (e) => {
    setRegisterResult({ result: true });
    form.handleChange(e);
  };

  const apiOrValidErrorText = (fieldName) =>
    !registerResult.result ? registerResult.msg : form.errors[fieldName];

  return (
    <AuthorizationPageWrapper>
      <AuthStyled.ContentWrapper>
        <div>
          <AuthStyled.ContentHead>
            <h2>{t("views.sign_up.form_title")}</h2>
          </AuthStyled.ContentHead>
          <AuthStyled.ContentBody>
            <Styled.Form onSubmit={form.handleSubmit} onChange={onFormChange}>
              <Styled.FormInputsBlockWrapper>
                <TextField
                  id={"email"}
                  name={"email"}
                  type={"text"}
                  width={"full"}
                  height={40}
                  value={form.values.email}
                  placeholder={t("core.inputs_defs.email.label")}
                  autoComplete={"new-password"}
                  state={
                    validation.isError(form, "email") || !registerResult.result
                      ? "error"
                      : ""
                  }
                  errorText={apiOrValidErrorText("email")}
                />
                <PhoneField
                  codeItems={codeItems}
                  maskWithoutCode={getMaskByCode(form.values.code)}
                  phone={{
                    code: form.values.code,
                    maskedValue: form.values.phone,
                  }}
                  setPhone={({ code, maskedValue }) => {
                    const newValidLength = getMaskByCode(code).length;
                    if (code.length !== form.values.length) {
                      form.setFieldValidation(
                        "phone",
                        "length",
                        newValidLength
                      );
                    }
                    form.setFieldValue("code", code);
                    form.setFieldValue(
                      "phone",
                      maskedValue.slice(0, newValidLength)
                    );
                  }}
                  placeholder={t("core.inputs_defs.phone.label")}
                  state={form.haveFieldError("phone") ? "error" : ""}
                  errorText={form.errors.phone}
                />
                <TextField
                  id={"password"}
                  name={"password"}
                  value={form.values.password}
                  autoComplete={"new-password"}
                  password={true}
                  width={"full"}
                  height={40}
                  placeholder={t("core.inputs_defs.password.label")}
                  state={validation.isError(form, "password") ? "error" : ""}
                  errorText={form.errors.password}
                />
              </Styled.FormInputsBlockWrapper>
              <Button
                text={t("views.sign_up.btn_signup")}
                round={true}
                width={"full"}
                type={"submit"}
                isLoading={isLoading}
              />
            </Styled.Form>
            <Styled.AgreementWrapper>
              {t("views.sign_up.text_agreement_pre")}{" "}
              <Styled.AgreementLink>
                {t("views.sign_up.text_agreement_link")}
              </Styled.AgreementLink>
            </Styled.AgreementWrapper>
          </AuthStyled.ContentBody>
        </div>
        <AuthStyled.Footer>
          <AuthStyled.FooterTitle>
            {t("views.sign_up.text_already_reg")}
          </AuthStyled.FooterTitle>
          <Link
            text={t("views.sign_up.btn_signin")}
            behavior={"no_reload"}
            onClick={() => history.push(routePaths.signIn)}
          />
        </AuthStyled.Footer>
      </AuthStyled.ContentWrapper>
    </AuthorizationPageWrapper>
  );
};

export default SignUp;

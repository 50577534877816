import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Styled from "../../components/Authorization/Recovery/styled";
import * as AuthStyled from "../../components/Authorization/styled";
import * as auth from "../../store/auth/actions";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../hooks";
import { AuthorizationPageWrapper } from "../../components";
import { validation } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Button, Link } from "@6tamp/uikit";
import { useTranslation } from "react-i18next";

const Recovery = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isSent, setIsSent] = useState(false);
  const [resetResult, setResetResult] = useState({ result: true });
  const isLoading = useSelector(({ auth }) => auth.isLoading);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("core.validation.email.invalid"))
        .required(t("core.validation.is_required")),
    }),
    onSubmit: async ({ email }) => {
      const { result, msg } = await dispatch(auth.sendNewPassword(email));
      setResetResult({ result, msg });
      setIsSent(result);
    },
  });

  const onFormChange = (e) => {
    setResetResult({ result: true });
    formik.handleChange(e);
  };

  return (
    <AuthorizationPageWrapper>
      <AuthStyled.ContentWrapper>
        {isSent ? (
          <div>
            <AuthStyled.ContentHead>
              <h2>{t("views.recovery.text_send_title")}</h2>
              <h3>{t("views.recovery.text_send_description")}</h3>
            </AuthStyled.ContentHead>
            <AuthStyled.ContentBody>
              <Button
                width={"full"}
                round={true}
                text={t("views.recovery.btn_go_back")}
                onClick={() => history.push(routePaths.signIn)}
              />
            </AuthStyled.ContentBody>
          </div>
        ) : (
          <div>
            <AuthStyled.ContentHead>
              <h2>{t("views.recovery.form_title")}</h2>
              <h3>{t("views.recovery.form_description")}</h3>
            </AuthStyled.ContentHead>
            <AuthStyled.ContentBody>
              <Styled.Form
                onSubmit={formik.handleSubmit}
                onChange={onFormChange}
              >
                <TextField
                  id={"email"}
                  name={"email"}
                  value={formik.values.email}
                  placeholder={t("core.inputs_defs.email.label")}
                  width={"full"}
                  height={40}
                  state={
                    validation.isError(formik, "email") || !resetResult.result
                      ? "error"
                      : ""
                  }
                  errorText={
                    !resetResult.result ? resetResult.msg : formik.errors.email
                  }
                />
                <Button
                  width={"full"}
                  round={true}
                  text={!isLoading && t("views.recovery.btn_send")}
                  type={"submit"}
                  isLoading={isLoading}
                />
              </Styled.Form>
            </AuthStyled.ContentBody>
          </div>
        )}
        {!isSent && (
          <AuthStyled.Footer>
            <Link
              text={t("views.recovery.btn_go_back")}
              view={"secondary"}
              onClick={() => history.push(routePaths.signIn)}
              behavior={"no_reload"}
            />
          </AuthStyled.Footer>
        )}
      </AuthStyled.ContentWrapper>
    </AuthorizationPageWrapper>
  );
};

export default Recovery;

import styled from "styled-components";
import { TextField } from "@consta/uikit/TextField";
import { IconSearch } from "@consta/uikit/IconSearch";

export const Input = styled(TextField).attrs({
  leftSide: IconSearch,
})`
  width: 100%;
  border: none;
  border-radius: 4px;
`;

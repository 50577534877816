import styled from "styled-components";

export const Content = styled.div`
  padding: 0 0 40px;
  box-sizing: border-box;
`;

export const FilterBlock = styled.div`
  margin: 3.9vh 0 0;

  width: 100%;
  height: auto;
`;

export const TagBlock = styled.div`
  margin: 20px 0 30px;

  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  gap: 15px;
`;

export const TableBlock = styled.div`
  margin: 20px 0 0;

  width: 100%;
`;

import styled from "styled-components";

import logo from "../../../assets/img/logo.svg";
import blue from "../../../assets/img/fig_blue.svg";
import orange from "../../../assets/img/fig_orange.svg";
import violet from "../../../assets/img/fig_violet.svg";
import yellow from "../../../assets/img/fig_yellow.svg";

export const Background = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: space-between;
`;

export const LeftSide = styled.div`
  width: 58%;
  height: 100%;

  background: ${(props) => props.theme.main["100%"]};

  position: relative;

  overflow: hidden;
`;

export const RightSide = styled.div`
  width: 42%;
  height: 100%;

  background: inherit;

  box-sizing: border-box;
  padding: 23px 50px;

  position: relative;
`;

export const RightSideInner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Welcome = styled.div`
  max-width: 541px;

  position: absolute;
  left: 6%;
  bottom: calc((119vh - 541px) / 2);

  h1 {
    margin: 30px 0;
    font-weight: 600;
    font-size: 70px;

    color: ${(props) => props.theme.white.primary};
  }
  h3 {
    margin: 30px 0;
    font-weight: 400;
    font-size: 16px;

    color: ${(props) => props.theme.white.secondary};
  }
`;

export const Logo = styled.div`
  width: 84px;
  height: 32px;

  position: absolute;
  left: 6%;
  top: 2%;

  z-index: 4;

  background: url(${logo});
  background-repeat: no-repeat;

  cursor: pointer;
`;

export const BlueFigure = styled.div`
  width: 164px;
  height: 339px;

  position: absolute;
  right: 0;
  top: 0;

  background: url(${blue});
  background-repeat: no-repeat;
`;
export const OrangeFigure = styled.div`
  width: 81px;
  height: 453px;

  position: absolute;
  left: 0;
  bottom: 50%;

  background: url(${orange});
  background-repeat: no-repeat;
`;
export const VioletFigure = styled.div`
  width: 477px;
  height: 130px;

  position: absolute;
  left: 0;
  bottom: 0;

  background: url(${violet});
  background-repeat: no-repeat;
`;
export const YellowFigure = styled.div`
  width: 634px;
  height: 127px;

  position: absolute;
  left: 5%;
  bottom: 0;

  background: url(${yellow});
`;

export const LanguageSelectorWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

import styled from "styled-components";

export const CardSettings = styled.div`
  margin: 40px 0 0;

  width: 100%;

  display: flex;
  justify-content: start;

  padding: 0 0 75px;
  box-sizing: content-box;

  @media screen and (max-device-width: 1050px) {
    justify-content: space-between;
  }

  .ColorPicker {
    overflow: visible !important;
  }
`;

export const DisplayBlock = styled.div`
  margin-right: min(17%, 300px);

  //width: 30%;
  width: auto;
  min-width: 390px;
  height: auto;
  max-height: 850px;

  @media screen and (max-device-width: 1050px) {
    margin-right: 0;
  }
`;

export const EditBlock = styled.form`
  width: 264px;
  min-height: 850px;
  height: auto;
`;

export const ParameterBlock = styled.div`
  margin: 0 0 25px;

  width: 100%;
  max-height: 160px;
`;

export const ParameterBlockTitle = styled.div`
  margin: 0 9px 0 0;

  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  color: ${({ theme }) => theme.typo.secondary};
`;

export const ParameterBlockContent = styled.div`
  width: 100%;
  height: auto;
`;

export const ParameterBlockHeader = styled.div`
  margin: 0 0 15px;

  display: flex;
  align-items: center;
`;

import styled from "styled-components";
import { DragNDropField } from "@consta/uikit/DragNDropField";
import { IconClose } from "@consta/uikit/IconClose";

export const UploadField = styled.div`
  width: 100%;
  min-height: 100px;
  height: auto;

  .DragNDropField {
    min-height: 0;
    height: 100px;

    padding: 9px;
    box-sizing: border-box;

    border-color: ${({ state, theme }) =>
      state === "alert" ? theme.error : theme.border.default};
  }

  overflow: hidden;
`;

export const Image = styled.div`
  position: relative;

  width: ${({ maxWidth, width }) => `${maxWidth > width ? width : maxWidth}px`};

  height: ${({ maxHeight, height }) =>
    `${maxHeight > height ? height : maxHeight}px`};

  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  background-image: ${({ src }) => `url(${src})`};
`;

export const Close = styled(IconClose).attrs({
  size: "s",
})`
  position: absolute;
  top: 6px;
  right: 5px;

  z-index: 0;

  cursor: pointer;

  .Icon-Svg {
    fill: ${({ theme }) => theme.typo.secondary};
  }
`;

export const DragNDrop = styled(DragNDropField)`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;

  overflow: hidden;
`;

export const DragTitle = styled.div`
  margin: 0 auto 4px;

  font-weight: 500;
  font-size: 14px;
  line-height: 150%;

  color: ${({ theme }) => theme.typo.secondary};

  cursor: auto;
`;

export const DragDescription = styled.div`
  margin: 4px auto 0;

  text-align: center;

  font-weight: 400;
  font-size: 12px;
  line-height: 130%;

  color: ${({ theme }) => theme.typo.ghost};

  cursor: auto;
`;

export const UploadButton = styled.span`
  color: ${({ theme }) => theme.main["100%"]};

  cursor: pointer;

  input[type="file"] {
    display: none;
  }
`;

import React, { useEffect, useRef, useState, useCallback } from "react";

const useClickOutside = () => {
  const ref = useRef();
  const [clickedOutside, setClickedOutside] = useState(false);

  const isClickOutside = useCallback((e) => {
    const path = e.path || (e.composedPath && e.composedPath());
    setClickedOutside(!path.includes(ref.current));
  }, []);

  useEffect(() => {
    document.body.addEventListener("click", isClickOutside);
    return () => {
      document.body.removeEventListener("click", isClickOutside);
    };
  }, []);

  return [ref, clickedOutside];
};

export default useClickOutside;

import produce from "immer";
import {
  DEL_AUTH_DATA,
  SET_AUTH_DATA,
  SET_LOADING,
  CONFIRM,
  SET_TOUR,
  SET_AUTH_FETCHING,
  SET_LANG_FETCHING,
} from "./types";

const initialState = {
  isLoading: false,
  jwt: window.localStorage.getItem("apiToken"),
  id: "",
  confirmed: null,
  accountType: null,
  isTour: false,
  isLangFetching: false,
  isAuthFetching: false,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  return produce(state, (draft) => {
    switch (type) {
      case SET_AUTH_DATA: {
        const { jwt, id, confirmed, type } = payload;
        draft.id = id;
        draft.jwt = jwt;
        draft.confirmed = confirmed;
        draft.accountType = type;
        window.localStorage.setItem("apiToken", jwt);
        return draft;
      }

      case DEL_AUTH_DATA: {
        window.localStorage.removeItem("apiToken");
        return initialState;
      }

      case SET_LOADING: {
        draft.isLoading = payload;
        return draft;
      }

      case SET_AUTH_FETCHING: {
        draft.isAuthFetching = payload;
        return draft;
      }

      case SET_LANG_FETCHING: {
        draft.isLangFetching = payload;
        return draft;
      }

      case CONFIRM: {
        draft.confirmed = true;
        draft.accountType = payload;
        return draft;
      }

      case SET_TOUR: {
        draft.isTour = payload;
        return draft;
      }

      default:
        return draft;
    }
  });
};

export default authReducer;

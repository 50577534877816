import React, { useRef, useState } from "react";
import * as PerStyled from "../styled";

import { IconQuestion } from "@consta/uikit/IconQuestion";
import PropTypes from "prop-types";

// todo: Отступ тултипа от якоря
const TagIconQuestion = ({ hintText, role }) => {
  const ref = useRef();

  const [isVisible, setIsVisible] = useState(false);

  return (
    <PerStyled.TagIcon
      ref={ref}
      role={role}
      onClick={(e) => e.stopPropagation()}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <IconQuestion size={"s"} />
      {isVisible && (
        <PerStyled.Tooltip anchorRef={ref}>{hintText}</PerStyled.Tooltip>
      )}
    </PerStyled.TagIcon>
  );
};

export default TagIconQuestion;

TagIconQuestion.defaultProps = {
  hintText: "6tamp",
  role: "primary",
};

TagIconQuestion.propTypes = {
  hintText: PropTypes.string,
  role: PropTypes.oneOf(["primary", "secondary"]),
};

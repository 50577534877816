import { apiService } from "../../utils";
import {
  RESET_FILTERED_LIST,
  SET_FILTERED_LIST,
  LOAD_LOCATIONS,
  SET_LOADING,
  SET_SKELETON,
} from "./types";
import i18n from "../../localization/i18n";

const setSkeleton = (isSkeleton, mode) => {
  return { type: SET_SKELETON, payload: { mode, value: isSkeleton } };
};

const setLoading = (isLoading) => {
  return { type: SET_LOADING, payload: isLoading };
};

export const search = (value, mode) => {
  return (dispatch, getState) => {
    if (value) {
      const { loadedLocations, searchIndex } = getState().locations[mode];
      const indexVariants = Object.keys(searchIndex)
        .filter((v) => v.includes(value.toLowerCase()))
        .map((filteredIndex) => searchIndex[filteredIndex]);
      const uniqueIndexes = [...new Set(indexVariants)];
      dispatch({
        type: SET_FILTERED_LIST,
        payload: {
          mode,
          data: uniqueIndexes.map((index) => loadedLocations[index]),
        },
      });
    } else {
      dispatch({ type: RESET_FILTERED_LIST, payload: { mode } });
    }
  };
};

export const loadLocations = (token) => async (dispatch) => {
  try {
    dispatch(setSkeleton(true, "active"));
    dispatch(setSkeleton(true, "trash"));
    dispatch(setLoading(true));

    await Promise.all([
      apiService.get("/location", { cancelToken: token }).then(({ data }) => {
        dispatch({
          type: LOAD_LOCATIONS,
          payload: { mode: "active", data },
        });
        dispatch(setSkeleton(false, "active"));
      }),
      apiService
        .get("/location?zombie=true", { cancelToken: token })
        .then(({ data }) => {
          dispatch({
            type: LOAD_LOCATIONS,
            payload: { mode: "trash", data },
          });
          dispatch(setSkeleton(false, "trash"));
        }),
    ]);
    dispatch(setLoading(false));

    return { result: true };
  } catch (e) {
    dispatch(setSkeleton(false, "active"));
    dispatch(setSkeleton(false, "trash"));
    dispatch(setLoading(false));

    if (e?.constructor.name === "Cancel") {
      return { result: false, msg: e.message };
    } else if (e.isAxiosError) {
      switch (e.response?.status) {
        case 400:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.locations_fetch_error"),
          };
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const createLocation = (fields) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await apiService.post("/location", fields);
    dispatch(setLoading(false));
    return { result: true, msg: i18n.t("core.actions_msgs.location_created") };
  } catch (e) {
    dispatch(setLoading(false));
    if (e.isAxiosError) {
      switch (e.response?.status) {
        case 400:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.location_not_created"),
          };
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const deleteLocation = (locationId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await apiService.delete(`/location/${locationId}`);
    dispatch(setLoading(false));
    return { result: true };
  } catch (e) {
    dispatch(setLoading(false));
    if (e.isAxiosError) {
      switch (e.response?.status) {
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const updateLocation = (locationId, fields) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await apiService.put(`/location/${locationId}`, fields);
    dispatch(setLoading(false));
    return { result: true, msg: i18n.t("core.actions_msgs.location_updated") };
  } catch (e) {
    dispatch(setLoading(false));
    if (e.isAxiosError) {
      switch (e.response?.status) {
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const createBill = (locationId, variant) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data } = await apiService.post("/billing/bill/create", {
      variant,
      location: locationId,
    });
    dispatch(setLoading(false));
    return data;
  } catch (e) {
    dispatch(setLoading(false));
    if (e.isAxiosError) {
      switch (e.response?.status) {
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const restoreLocations = (locationIds) => async (dispatch) => {
  const success =
    locationIds.length === 1
      ? i18n.t("core.actions_msgs.location_restored")
      : i18n.t("core.actions_msgs.locations_restored");
  try {
    dispatch(setLoading(true));
    const { data } = await apiService.post("/location/trash/restore", {
      locationIds,
    });
    dispatch(setLoading(false));
    return {
      result: data.result,
      msg: data.result ? success : i18n.t("core.actions_msgs.server_error"),
    };
  } catch (e) {
    dispatch(setLoading(false));
    return { result: false, msg: i18n.t("core.actions_msgs.server_error") };
  }
};

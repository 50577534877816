import { sub } from "date-fns";
import { fieldTypes } from "../../../store/filter/reducer";

export const presets = {
  accidental: {
    firstVisit: {
      type: fieldTypes.Date,
      range: [null, null],
    },
    lastVisit: {
      type: fieldTypes.Date,
      range: [null, sub(new Date(), { days: 90 })],
    },
    curGifts: {
      type: fieldTypes.Value,
      range: [null, null],
    },
    receivedGifts: {
      type: fieldTypes.Value,
      range: [null, null],
    },
    totalStamps: {
      type: fieldTypes.Value,
      range: [1, 3],
    },
  },
  regular: {
    firstVisit: {
      type: fieldTypes.Date,
      range: [null, null],
    },
    lastVisit: {
      type: fieldTypes.Date,
      range: [sub(new Date(), { days: 3 }), new Date()],
    },
    curGifts: {
      type: fieldTypes.Value,
      range: [null, null],
    },
    receivedGifts: {
      type: fieldTypes.Value,
      range: [2, null],
    },
    totalStamps: {
      type: fieldTypes.Value,
      range: [5, null],
    },
  },
  new: {
    firstVisit: {
      type: fieldTypes.Date,
      range: [sub(new Date(), { days: 30 }), new Date()],
    },
    lastVisit: {
      type: fieldTypes.Date,
      range: [null, null],
    },
    curGifts: {
      type: fieldTypes.Value,
      range: [null, null],
    },
    receivedGifts: {
      type: fieldTypes.Value,
      range: [null, null],
    },
    totalStamps: {
      type: fieldTypes.Value,
      range: [2, null],
    },
  },
  loyal: {
    firstVisit: {
      type: fieldTypes.Date,
      range: [null, null],
    },
    lastVisit: {
      type: fieldTypes.Date,
      range: [sub(new Date(), { days: 7 }), new Date()],
    },
    curGifts: {
      type: fieldTypes.Value,
      range: [null, null],
    },
    receivedGifts: {
      type: fieldTypes.Value,
      range: [1, null],
    },
    totalStamps: {
      type: fieldTypes.Value,
      range: [null, null],
    },
  },
};

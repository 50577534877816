import React, { useEffect, useState, memo } from "react";
import * as Styled from "./styled";
import { dataURLtoFile } from "../../../utils";

const Icon = memo(({ base64 }) => {
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    const toFile = async () => {
      !!base64 &&
        setIcon(
          await dataURLtoFile(`data:image/png;base64,${base64}`, "icon.png")
        );
    };
    toFile();
  }, [base64]);

  return <Styled.Icon pic={!!icon ? URL.createObjectURL(icon) : null} />;
});

export default Icon;

import styled from "styled-components";
import * as PerStyled from "../styled";

export const CheckboxWrapper = styled.div`
  display: flex;
`;

export const ButtonWrapper = styled.div`
  min-width: 170px;
  width: 40%;
  max-width: 300px;
`;

export const CheckboxLabel = styled.div`
  margin: 0 10px;

  color: ${({ theme }) => theme.typo.secondary};
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Form = styled.form`
  margin: 40px 0 0;

  width: 320px;
`;

export const FormTitle = styled.div`
  margin: 0 0 40px;
`;

export const FormContent = styled.div`
  display: grid;
  grid-row-gap: 30px;
`;

export const InputLabelWrapper = styled.div`
  display: flex;
  gap: 9px;
`;

export const InputItem = styled.div`
  display: grid;
  grid-row-gap: 15px;
`;

export const InputLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  color: ${({ theme }) => theme.typo.secondary};
`;

export const PasswordInputBlockWrapper = styled.div`
  margin: 0 0 30px;

  display: grid;
  grid-row-gap: 20px;
`;

export const PasswordInputWrapper = styled.div`
  margin: 0 0 20px;
`;

export const Tabs = styled(PerStyled.Tabs)`
  margin: 0 0 40px;
`;

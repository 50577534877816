import React from "react";
import { TextField } from "@6tamp/uikit";
import InputMask from "react-input-mask";
import * as Styled from "./styled";
import * as PerStyled from "../styled";

const PhoneField = ({
  phone,
  setPhone,
  codeItems,
  maskWithoutCode,
  name,
  errorText,
  state,
  placeholder,
  onFocus,
}) => {
  const setPhoneHandler = (arg) => {
    const obj = arg instanceof Function ? arg(phone) : arg;
    setPhone(obj);
  };
  return (
    <Styled.PhoneField>
      <PerStyled.Select
        name={name}
        items={codeItems}
        value={codeItems.find((el) => el.code === phone.code)}
        onChange={({ value }) =>
          setPhoneHandler((p) => ({ ...p, code: value.code }))
        }
      />
      <InputMask
        mask={maskWithoutCode}
        maskChar={""}
        value={phone.maskedValue}
        onChange={(e) =>
          setPhoneHandler((p) => ({ ...p, maskedValue: e.target.value }))
        }
        name={name}
        width={"full"}
        height={40}
        placeholder={placeholder}
        state={state}
        errorText={errorText}
        onFocus={onFocus}
      >
        {(inputProps) => <TextField {...inputProps} />}
      </InputMask>
    </Styled.PhoneField>
  );
};

export default PhoneField;

import React, { useState } from "react";
import PropTypes from "prop-types";
import * as WorkersStyled from "../../components/Personal/Workers/styled";
import * as PerStyled from "../../components/Personal/styled";
import { SearchBar } from "../../components";
import { loadUsers, search, restoreUsers } from "../../store/workers/actions";
import { EmptyPage, Table, Button } from "@6tamp/uikit";
import { IconRevert } from "@consta/uikit/IconRevert";
import { useDispatch, useSelector } from "react-redux";
import { addApiMessage } from "../../store/messageBar/actions";
import { useTranslation } from "react-i18next";

const TrashUsers = ({ columnsDef, desc }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tableIndexes, setTableIndexes] = useState([]);

  const { loadedUsers, filteredUsers, isSkeleton } = useSelector(
    ({ workers }) => workers.trash
  );

  const onRestore = async (mode, elementIndex) => {
    const userIds = filteredUsers
      .filter((u, i) =>
        mode === "checkbox" ? tableIndexes.includes(i) : i === elementIndex
      )
      .map((u) => u.id);
    const { result, msg } = await dispatch(restoreUsers(userIds));
    setTableIndexes([]);
    dispatch(addApiMessage(result ? "success" : "alert", msg));
    await dispatch(loadUsers(null, "trash"));
  };

  return (
    <>
      <WorkersStyled.ContentTop>
        <PerStyled.WorkspaceTitle>
          <h2>{desc}</h2>
          <Button
            text={t("views.workers.btn_restore_worker")}
            round={true}
            disabled={tableIndexes.length === 0}
            onClick={() => onRestore("checkbox")}
          />
        </PerStyled.WorkspaceTitle>
      </WorkersStyled.ContentTop>
      <WorkersStyled.ContentBottom>
        <SearchBar
          onChange={(value) => dispatch(search(value, "trash"))}
          onEnter={() => true}
          placeholder={t("views.workers.fields_placeholders.search")}
        />
        {!!filteredUsers.length || isSkeleton ? (
          <WorkersStyled.TableBlock>
            <Table
              data={[...filteredUsers]}
              cols={columnsDef}
              isSkeleton={isSkeleton}
              checkable
              editable
              checkedRows={tableIndexes}
              setCheckedRows={setTableIndexes}
              contextMenu={[
                {
                  name: t("views.workers.context_menu_labels.restore"),
                  icon: <IconRevert />,
                  group: 1,
                  onClick: (elementIndex) => onRestore("element", elementIndex),
                },
              ]}
            />
          </WorkersStyled.TableBlock>
        ) : (
          <>
            {!!loadedUsers.length ? (
              <EmptyPage
                variant={"NotFound"}
                header={t("views.workers.empty_page_not_found_header")}
                description={t("views.workers.empty_page_not_found_desc")}
              />
            ) : (
              <EmptyPage
                variant={"NotExists"}
                header={t("views.workers.empty_page_not_exists_header")}
                description={t("views.workers.empty_page_not_exists_desc")}
              />
            )}
          </>
        )}
      </WorkersStyled.ContentBottom>
    </>
  );
};

TrashUsers.defaultProps = {
  desc: "Удалённые сотрудники",
};

TrashUsers.propTypes = {
  columnsDef: PropTypes.array.isRequired,
  desc: PropTypes.string,
};

export default TrashUsers;

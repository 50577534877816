import React, { useEffect, useState, useRef } from "react";
import * as Styled from "../../components/Personal/Clients/styled";
import * as PerStyled from "../../components/Personal/styled";
import { useDispatch, useSelector } from "react-redux";
import {
  PageWrapper,
  TagIconCancel,
  TagIconQuestion,
  IconCount,
  FilterBar,
  PushModal,
} from "../../components";
import { presets } from "../../components/Personal/FilterBar/presets";
import { setPreset, clearFields } from "../../store/filter/actions";
import {
  loadClients,
  clearFilter,
  prefilterClients,
} from "../../store/clients/actions";
import { EmptyPage, Table, Button } from "@6tamp/uikit";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const Clients = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef();

  const columnsDef = [
    {
      key: "phone",
      label: t("views.clients.columns_labels.phone"),
      order: 1,
    },
    {
      key: "firstVisit",
      label: t("views.clients.columns_labels.first_visit"),
      order: 2,
    },
    {
      key: "lastVisit",
      label: t("views.clients.columns_labels.last_visit"),
      order: 3,
    },
    {
      key: "curStamps",
      label: t("views.clients.columns_labels.cur_stamps"),
      order: 4,
    },
    {
      key: "receivedGifts",
      label: t("views.clients.columns_labels.received_gifts"),
      order: 5,
    },
    {
      key: "curGifts",
      label: t("views.clients.columns_labels.cur_gifts"),
      order: 6,
    },
    {
      key: "totalStamps",
      label: t("views.clients.columns_labels.total_stamps"),
      order: 8,
    },
  ];
  const presetDefinitions = {
    accidental: {
      name: t("views.clients.filters_presets_defs.accidental.name"),
      description: t("views.clients.filters_presets_defs.accidental.desc"),
    },
    regular: {
      name: t("views.clients.filters_presets_defs.regular.name"),
      description: t("views.clients.filters_presets_defs.regular.desc"),
    },
    new: {
      name: t("views.clients.filters_presets_defs.new.name"),
      description: t("views.clients.filters_presets_defs.new.desc"),
    },
    loyal: {
      name: t("views.clients.filters_presets_defs.loyal.name"),
      description: t("views.clients.filters_presets_defs.loyal.desc"),
    },
  };

  const [tableIndexes, setTableIndexes] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFilterBarOpen, setIsFilterBarOpen] = useState(false);
  const [selectedForPush, setSelectedForPush] = useState([]);
  const [tag, setTag] = useState("");
  const [isPushTooltipVisible, setIsPushTooltipVisible] = useState(false);
  const [isPushButtonDisabled, setIsPushButtonDisabled] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  const countFilters = Object.values(useSelector(({ filter }) => filter.fields))
    .map((el) => el.range)
    .reduce((acc, cur) => (!cur[0] && !cur[1] ? acc + 0 : acc + 1), 0);
  const { filteredClients, loadedClients, isSkeleton } = useSelector(
    ({ clients }) => clients
  );
  const isPreset = useSelector(({ filter }) => filter.isPreset);

  // Добавление всех пользователей в prefilter при маунте
  useEffect(() => {
    const fetch = async () => {
      await dispatch(loadClients());
      dispatch(prefilterClients());
    };
    fetch();
  }, []);

  useEffect(() => {
    !isPreset && setTag("");
  }, [isPreset]);

  useEffect(() => {
    setSelectedForPush(
      filteredClients
        .filter((obj, key) => tableIndexes.includes(key))
        .map((client) => client.id)
    );
  }, [filteredClients, tableIndexes]);

  const handleClickTag = (value) => {
    setTag(value);
    !!value && dispatch(setPreset(presets[value]));
  };

  const toggleIcon = (value) => {
    return tag === value
      ? () =>
          TagIconCancel({
            onClick: () => {
              handleClickTag("");
              dispatch(clearFilter());
              dispatch(clearFields());
            },
          })
      : () =>
          TagIconQuestion({
            hintText: presetDefinitions[value].description,
          });
  };

  useEffect(() => {
    const isDisabled = !selectedForPush?.length;
    setIsPushButtonDisabled(isDisabled);
    if (!isDisabled) setIsPushTooltipVisible(false);
  }, [selectedForPush]);

  return (
    <PageWrapper>
      {!loadedClients.length && !isSkeleton ? (
        <PerStyled.BackgroundContent>
          <EmptyPage
            variant={"NotExists"}
            header={t("views.clients.empty_page_not_exists_header")}
            description={t("views.clients.empty_page_not_exists_desc")}
          />
        </PerStyled.BackgroundContent>
      ) : (
        <PerStyled.Workspace>
          <PushModal
            data={selectedForPush}
            clearSelected={() => setTableIndexes([])}
            isOpen={isModalVisible}
            close={() => setIsModalVisible(false)}
          />
          <FilterBar
            isOpen={isFilterBarOpen}
            close={() => setIsFilterBarOpen((value) => !value)}
          />

          <Styled.Content>
            <PerStyled.WorkspaceTitle>
              <h2>{t("views.clients.text_header")}</h2>
              <div
                ref={ref}
                onMouseEnter={
                  isPushButtonDisabled
                    ? () => setIsPushTooltipVisible(true)
                    : null
                }
                onMouseLeave={
                  isPushButtonDisabled
                    ? () => setIsPushTooltipVisible(false)
                    : null
                }
              >
                <Button
                  disabled={isPushButtonDisabled}
                  width={"full"}
                  round={true}
                  text={t("views.clients.btn_send_push")}
                  onClick={() => setIsModalVisible(true)}
                />
                {isPushTooltipVisible && (
                  <PerStyled.Tooltip anchorRef={ref}>
                    {t("views.clients.btn_send_push_tooltip_desc")}
                  </PerStyled.Tooltip>
                )}
              </div>
            </PerStyled.WorkspaceTitle>

            <Styled.FilterBlock>
              <Button
                round={true}
                isPressed={isFilterBarOpen}
                customElement={
                  countFilters
                    ? () => <IconCount count={countFilters} />
                    : undefined
                }
                view={"flat"}
                onClick={() => setIsFilterBarOpen((value) => !value)}
                text={t("views.clients.btn_open_filter_bar")}
              />
              <Styled.TagBlock>
                {Object.keys(presets).map((code) => (
                  <Button
                    key={code}
                    name={code}
                    onClick={() => handleClickTag(code)}
                    text={presetDefinitions[code].name}
                    isPressed={tag === code}
                    alignElement={"right"}
                    view={"soft"}
                    size={"s"}
                    customElement={toggleIcon(code)}
                  />
                ))}
              </Styled.TagBlock>
            </Styled.FilterBlock>

            <Styled.TableBlock>
              {!!filteredClients.length || isSkeleton ? (
                <Table
                  data={[
                    ...filteredClients.map((obj) => {
                      const newObj = Object.assign({}, obj);
                      newObj.firstVisit = format(obj.firstVisit, "dd.MM.yyyy");
                      newObj.lastVisit = format(obj.lastVisit, "dd.MM.yyyy");
                      return newObj;
                    }),
                  ]}
                  cols={columnsDef}
                  checkable={true}
                  checkedRows={tableIndexes}
                  setCheckedRows={setTableIndexes}
                  isSkeleton={isSkeleton}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : (
                <EmptyPage
                  variant={"NotFound"}
                  header={t("views.clients.empty_page_not_found_header")}
                  description={t("views.clients.empty_page_not_found_desc")}
                />
              )}
            </Styled.TableBlock>
          </Styled.Content>
        </PerStyled.Workspace>
      )}
    </PageWrapper>
  );
};

export default Clients;

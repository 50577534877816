import React, { useEffect, useState } from "react";
import * as Styled from "../../components/Authorization/SignUpVerification/styled";
import * as AuthStyled from "../../components/Authorization/styled";
import { AuthorizationPageWrapper } from "../../components";
import { Theme as theme } from "@6tamp/uikit";
import { routePaths } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as auth from "../../store/auth/actions";
import { useResendVerification } from "../../hooks";
import { useTranslation } from "react-i18next";
import { Button, Link } from "@6tamp/uikit";

const SignUpVerification = () => {
  const { t } = useTranslation();
  const [invalid, setInvalid] = useState(false);
  const { id, confirmed, isLoading } = useSelector(({ auth }) => auth);
  const [code, setCode] = useState("");
  const { available, reset, timerValue } = useResendVerification();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChangeCode = (value) => {
    setCode(value);
    setInvalid(false);
  };

  const handleClickAccept = async () => {
    const result = await dispatch(auth.checkConfirmationCode(code));
    if (!result) {
      setInvalid(true);
    }
  };

  useEffect(() => {
    if (id && !confirmed) {
      dispatch(auth.sendConfirmationCode());
    } else {
      history.push(routePaths.default);
    }
  }, []);

  return (
    <AuthorizationPageWrapper>
      <AuthStyled.ContentWrapper>
        <form>
          <AuthStyled.ContentHead>
            <h2>{t("views.verification.form_title")}</h2>
            <h3>{t("views.verification.form_desc")}</h3>
            <Styled.CodeInput
              value={code}
              onChange={handleChangeCode}
              autoComplete={"one-time-code"}
              inputStyle={{
                width: "15.5%",
                fontFamily: "inherit",
                fontSize: "24px",
                lineHeight: "36px",
                border: "none",
                borderBottom: `1px solid ${invalid ? theme.error : "#B8CAD5"}`,
                textAlign: "center",
                fontWeight: 400,
                margin: "4%",
                marginBottom: "10px",
                outline: "none",
                backgroundColor: `${theme.white}`,
              }}
            />
            {invalid && (
              <Styled.VerificationErrorMessage>
                {t("core.validation.verification_code.wrong")}
              </Styled.VerificationErrorMessage>
            )}
          </AuthStyled.ContentHead>
          <AuthStyled.ContentBody>
            <Button
              text={t("views.verification.btn_submit")}
              onClick={handleClickAccept}
              type={"submit"}
              round={true}
              width={"full"}
              isLoading={isLoading}
            />
            {available ? (
              <Button
                onClick={() => reset()}
                width={"full"}
                text={t("views.verification.btn_resend")}
                view={"flat"}
                hasBorder={false}
              />
            ) : (
              <Styled.VerificationTimer>{`${t(
                "views.verification.code_timer.pre"
              )} ${timerValue} ${t(
                "views.verification.code_timer.post"
              )}`}</Styled.VerificationTimer>
            )}
          </AuthStyled.ContentBody>
        </form>
        <AuthStyled.Footer>
          <Link
            text={t("views.verification.btn_go_back")}
            onClick={() => history.push(routePaths.signUp)}
            view={"secondary"}
            behavior={"no_reload"}
          />
        </AuthStyled.Footer>
      </AuthStyled.ContentWrapper>
    </AuthorizationPageWrapper>
  );
};

export default SignUpVerification;

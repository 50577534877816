import styled from "styled-components";
import ReactCodeInput from "react-code-input";
import * as GStyled from "../../styled";

export const CodeInput = styled(ReactCodeInput).attrs({
  inputMode: "numeric",
  name: "code",
  type: "number",
  fields: 4,
})`
  margin: 22px 0 0;

  width: 72%;

  // disable input arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  padding: 0 0 0 14%;

  display: flex;
  align-items: center;
  flex-direction: column;

  white-space: nowrap;

  input:hover {
    border-color: ${(props) => props.theme.border.hover} !important;
  }

  input:focus-within {
    border-color: ${(props) => props.theme.main["100%"]} !important;
  }
`;

export const VerificationErrorMessage = styled(GStyled.ErrorMessage)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const VerificationTimer = styled.h3`
  margin-top: 20px !important;
`;

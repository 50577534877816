import React, { useState, useEffect } from "react";
import * as PerStyled from "../../components/Personal/styled";
import * as Styled from "../../components/Personal/Settings/styled";

import { PageWrapper } from "../../components";
import {
  AccountSettings,
  ChangePassword,
  ChainSettings,
  CardSettings,
} from "../index";
import {
  getUserData,
  getChainData,
  getCardData,
} from "../../store/settings/actions";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const navbarItems = [
  "views.settings.navbar_labels.account_settings",
  "views.settings.navbar_labels.change_password",
  "views.settings.navbar_labels.chain_settings",
  "views.settings.navbar_labels.card_settings",
];

const Settings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const source = axios.CancelToken.source();

  const [userData, setUserData] = useState(null);
  const [chainData, setChainData] = useState(null);
  const [cardData, setCardData] = useState(null);

  const [nav, setNav] = useState(navbarItems[0]);

  const updateData = async (dataName, token) => {
    switch (dataName) {
      case "user": {
        const data = await getUserData(token);
        data.result && setUserData(data.fields);
        return data.result;
      }

      case "chain": {
        const chainData = await getChainData(token);
        chainData.result && setChainData(chainData.fields);
        /*
          Нужно обновлять так же данные карточки,
          тк при обновлении названия сети в предпросмотре название компании тоже должно меняться
        */
        const cardData = await dispatch(getCardData(token));
        cardData.result && setCardData(cardData.fields);
        return chainData.result && cardData.result;
      }

      case "card": {
        const data = await dispatch(getCardData(token));
        data.result && setCardData(data.fields);
        return data.result;
      }

      default:
        return false;
    }
  };

  useEffect(() => {
    async function fetchData() {
      await updateData("user", source.token);
      await updateData("chain", source.token);
      await updateData("card", source.token);
    }

    fetchData();

    return () => {
      source.cancel();
    };
  }, []);

  const settingsDictionary = {
    [navbarItems[0]]: (
      <AccountSettings
        data={userData}
        updateData={async () => await updateData("user", source.token)}
      />
    ),
    [navbarItems[1]]: <ChangePassword />,
    [navbarItems[2]]: (
      <ChainSettings
        data={chainData}
        updateData={async () => await updateData("chain", source.token)}
      />
    ),
    [navbarItems[3]]: (
      <CardSettings
        data={cardData}
        updateData={async () => await updateData("card", source.token)}
        maxStamps={chainData?.maxStamps ? chainData.maxStamps : null}
      />
    ),
  };

  return (
    <PageWrapper>
      <PerStyled.Workspace>
        <Styled.Tabs
          value={t(nav)}
          onChange={({ value }) =>
            setNav(navbarItems.find((el) => t(el) === value))
          }
          items={navbarItems.map((langId) => t(langId))}
          getLabel={(item) => item}
          fitmode={"scroll"}
        />
        {settingsDictionary[nav]}
      </PerStyled.Workspace>
    </PageWrapper>
  );
};

export default Settings;

import React, { useState } from "react";
import PropTypes from "prop-types";
import * as LocationsStyled from "../../components/Personal/Locations/styled";
import * as PerStyled from "../../components/Personal/styled";
import { accountTypes } from "../../utils";
import { SearchBar } from "../../components";
import {
  loadLocations,
  restoreLocations,
  search,
} from "../../store/locations/actions";
import { EmptyPage, Table, Button } from "@6tamp/uikit";
import { IconRevert } from "@consta/uikit/IconRevert";
import { useDispatch, useSelector } from "react-redux";
import { addApiMessage } from "../../store/messageBar/actions";
import { useTranslation } from "react-i18next";

const TrashLocations = ({ columnsDef, desc }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tableIndexes, setTableIndexes] = useState([]);

  const { accountType } = useSelector(({ auth }) => auth);
  const { loadedLocations, filteredLocations, isSkeleton } = useSelector(
    ({ locations }) => locations.trash
  );

  const onRestore = async (mode, elementIndex) => {
    const locationIds = filteredLocations
      .filter((l, i) =>
        mode === "checkbox" ? tableIndexes.includes(i) : i === elementIndex
      )
      .map((l) => l._id);
    const { result, msg } = await dispatch(restoreLocations(locationIds));
    setTableIndexes([]);
    dispatch(addApiMessage(result ? "success" : "alert", msg));
    await dispatch(loadLocations(null, "trash"));
  };

  return (
    <>
      <LocationsStyled.ContentTop>
        <PerStyled.WorkspaceTitle>
          <h2>{desc}</h2>
          {accountType === accountTypes.OWNER && (
            <Button
              round={true}
              disabled={tableIndexes.length === 0}
              text={t("views.locations.btn_restore_locations")}
              onClick={() => onRestore("checkbox")}
            />
          )}
        </PerStyled.WorkspaceTitle>
      </LocationsStyled.ContentTop>
      <LocationsStyled.ContentBottom>
        <SearchBar
          onChange={(value) => dispatch(search(value, "trash"))}
          onEnter={() => true}
          placeholder={t("views.locations.modal_fields_placeholders.search")}
        />
        {!!filteredLocations.length || isSkeleton ? (
          <LocationsStyled.TableBlock>
            <Table
              data={[...filteredLocations]}
              cols={columnsDef}
              isSkeleton={isSkeleton}
              checkable
              editable
              checkedRows={tableIndexes}
              setCheckedRows={setTableIndexes}
              contextMenu={[
                {
                  name: t("views.locations.context_menu_labels.restore"),
                  icon: <IconRevert />,
                  group: 1,
                  onClick: (elementIndex) => onRestore("element", elementIndex),
                },
              ]}
            />
          </LocationsStyled.TableBlock>
        ) : (
          <>
            {!!loadedLocations.length ? (
              <EmptyPage
                variant={"NotFound"}
                header={t("views.locations.empty_page_not_found_header")}
                description={t("views.locations.empty_page_not_found_desc")}
              />
            ) : (
              <EmptyPage
                variant={"NotExists"}
                header={t("views.locations.empty_page_not_exists_header")}
                description={t("views.locations.empty_page_not_exists_desc")}
              />
            )}
          </>
        )}
      </LocationsStyled.ContentBottom>
    </>
  );
};

TrashLocations.defaultProps = {
  desc: "Удалённые локации",
};

TrashLocations.propTypes = {
  columnsDef: PropTypes.array.isRequired,
  desc: PropTypes.string,
};

export default TrashLocations;

import React from "react";
import Tour from "reactour";
import * as Styled from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { completeOnboarding, setTour } from "../../../store/auth/actions";
import { useLocation } from "react-router-dom";
import { addApiMessage } from "../../../store/messageBar/actions";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useTranslation } from "react-i18next";
import { Button } from "@6tamp/uikit";

const getElementBySelectorAndText = (selector, text) =>
  new Promise((resolve) =>
    setTimeout(() => {
      const elementsLike = document.querySelectorAll(selector);
      resolve(
        Array.prototype.find.call(
          elementsLike,
          (el) => el.innerText.toLowerCase() === text.toLowerCase()
        )
      );
    }, 0)
  );

export const stepsIds = {
  1: "chain_settings",
  2: "card_settings",
  3: "create_location",
  4: "create_worker",
};

const Onboarding = ({ history }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  // Обновлять компонент при loading
  useSelector(
    ({ locations, workers }) => locations.isLoading || workers.isLoading
  );
  const dispatch = useDispatch();
  const isTour = useSelector(({ auth }) => auth.isTour);
  const isLocationsExist = useSelector(
    ({ locations }) =>
      !!locations.active.loadedLocations.length ||
      !!locations.trash.loadedLocations.length
  );

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const tourConfig = [
    // 0
    {
      action: () => dispatch(completeOnboarding()),
      content: ({ goTo }) => (
        <Styled.Content>
          <Styled.Title>{t("onboarding.intro.title")}</Styled.Title>
          <Styled.Description align={"center"}>
            {t("onboarding.intro.desc")}
          </Styled.Description>
          <Button
            text={t("onboarding.intro.btn_next_step")}
            round={true}
            onClick={async () => {
              history.push("/account_settings");
              const tabCompany = await getElementBySelectorAndText(
                "button.TabsTab",
                t("views.settings.navbar_labels.chain_settings")
              );
              tabCompany.click();
              goTo(1);
            }}
          />
        </Styled.Content>
      ),
    },
    // 1
    {
      content: ({ goTo }) => (
        <Styled.Content>
          <Styled.Title>{t("onboarding.chain_settings.title")}</Styled.Title>
          <Styled.Description align={"left"}>
            {t("onboarding.chain_settings.desc")}
          </Styled.Description>
          <Button
            text={t("onboarding.chain_settings.btn_next_step")}
            round={true}
            onClick={async () => {
              history.push("/account_settings");
              const tabCard = await getElementBySelectorAndText(
                "button.TabsTab",
                t("views.settings.navbar_labels.card_settings")
              );
              tabCard.click();
              goTo(2);
            }}
          />
        </Styled.Content>
      ),
      selector: `[data-tour="${stepsIds[1]}"]`,
    },
    // 2
    {
      content: ({ goTo }) => (
        <Styled.Content>
          <Styled.Title>{t("onboarding.card_settings.title")}</Styled.Title>
          <Styled.Description align={"left"}>
            {t("onboarding.card_settings.desc")}
          </Styled.Description>
          <Button
            text={t("onboarding.card_settings.btn_next_step")}
            round={true}
            onClick={async () => {
              history.push("/locations");
              goTo(3);
            }}
          />
        </Styled.Content>
      ),
      selector: `[data-tour="${stepsIds[2]}"]`,
    },
    // 3
    {
      content: ({ goTo }) => (
        <Styled.Content>
          <Styled.Title>{t("onboarding.locations.title")}</Styled.Title>
          <Styled.Description align={"left"}>
            {t("onboarding.locations.desc")}
          </Styled.Description>
          <Button
            text={t("onboarding.locations.btn_next_step")}
            round={true}
            onClick={async () => {
              history.push("/workers");
              goTo(4);
            }}
          />
        </Styled.Content>
      ),
      selector: `[data-tour="${stepsIds[3]}"]`,
    },
    // 4
    {
      content: ({ goTo }) => (
        <Styled.Content>
          <Styled.Title>{t("onboarding.workers.title")}</Styled.Title>
          {isLocationsExist ? (
            <Styled.Description align={"left"}>
              {t("onboarding.workers.desc")}
            </Styled.Description>
          ) : (
            <Styled.Description>
              {t("onboarding.workers.desc_not_created")}
            </Styled.Description>
          )}
          <Button
            text={t("onboarding.workers.btn_next_step")}
            round={true}
            onClick={() => {
              dispatch(setTour(false));
              dispatch(addApiMessage("success", t("onboarding.success")));
            }}
          />
        </Styled.Content>
      ),
      selector: `[data-tour="${stepsIds[4]}"]`,
    },
  ];
  return (
    <Tour
      onRequestClose={() => dispatch(setTour(false))}
      isOpen={isTour}
      steps={tourConfig}
      update={pathname}
      showButtons={false}
      showNavigation={false}
      showNavigationNumber={false}
      className={"tour"}
      onAfterOpen={disableBody}
      onBeforeClose={enableBody}
      closeWithMask={false}
    />
  );
};

export default Onboarding;

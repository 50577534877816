import styled from "styled-components";
import { Button } from "@consta/uikit/Button";
import { Link } from "react-router-dom";

const textWidth = "165px";
const buttonHeight = "24px";

export const SidebarLink = styled(Link)`
  margin: 0 0 44px;

  width: 100%;
  min-height: ${buttonHeight};

  display: flex;
  justify-content: flex-start;

  cursor: pointer;
  text-decoration: none;

  button,
  span {
    transition: color 0.2s ease-out;
    color: ${({ active, theme }) => {
      return active ? theme.white.primary : theme.white.secondary;
    }};

    font-weight: ${({ active }) => (active ? 500 : 400)};
    font-size: 16px;
  }

  :hover {
    button,
    span {
      color: ${(props) => props.theme.white.primary};
    }
  }
`;

export const Icon = styled(Button).attrs({
  onlyIcon: true,
  size: "s",
  view: "clear",
})`
  height: ${buttonHeight};

  :focus {
    box-shadow: none !important;
  }

  pointer-events: none;
`;

export const Text = styled.span`
  margin: 0 6px 0 0;

  max-width: ${textWidth};
  min-height: ${buttonHeight};
  line-height: ${buttonHeight};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

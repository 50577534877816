import React from "react";
import PropTypes from "prop-types";

const TourWrapper = ({ tourId, children }) => {
  return <div data-tour={tourId}>{children}</div>;
};

TourWrapper.propTypes = {
  tourId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
};

export default TourWrapper;

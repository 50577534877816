import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import {
  CreateUserModal,
  SearchBar,
  TourWrapper,
  UpdateUserModal,
} from "../../components";
import * as WorkersStyled from "../../components/Personal/Workers/styled";
import * as PerStyled from "../../components/Personal/styled";
import { deleteUser, loadUsers, search } from "../../store/workers/actions";
import { EmptyPage, Table, Button } from "@6tamp/uikit";
import { IconEdit } from "@consta/uikit/IconEdit";
import { IconTrash } from "@consta/uikit/IconTrash";
import { IconLineAndBarChart } from "@consta/uikit/IconLineAndBarChart";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../hooks";
import { stepsIds } from "../../components/Personal/Onboarding/Onboarding";
import { useTranslation } from "react-i18next";

const ActiveUsers = ({ columnsDef, desc }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editWorkerValues, setEditWorkerValues] = useState({});

  const { loadedUsers, filteredUsers, isSkeleton } = useSelector(
    ({ workers }) => workers.active
  );

  const onElementOpenStat = (elementIndex) => {
    history.push(`${routePaths.workers}/${filteredUsers[elementIndex].id}`);
  };

  const onElementEdit = useCallback(
    (elementIndex) => {
      setIsEditModalVisible(true);
      setEditWorkerValues(filteredUsers[elementIndex]);
    },
    [filteredUsers]
  );

  const onElementDelete = useCallback(
    async (elementIndex) => {
      await dispatch(deleteUser(filteredUsers[elementIndex].id));
      dispatch(loadUsers(null, "active"));
    },
    [filteredUsers]
  );

  return (
    <>
      <CreateUserModal
        close={() => setIsCreateModalVisible(false)}
        isOpen={isCreateModalVisible}
      />
      <UpdateUserModal
        close={() => setIsEditModalVisible(false)}
        isOpen={isEditModalVisible}
        values={editWorkerValues}
      />
      <WorkersStyled.ContentTop>
        <PerStyled.WorkspaceTitle>
          <h2>{desc}</h2>
          {isSkeleton ? (
            <Button
              round={true}
              text={t("views.workers.btn_create_worker")}
              onClick={() => setIsCreateModalVisible(true)}
            />
          ) : (
            <TourWrapper tourId={stepsIds[4]}>
              <Button
                round={true}
                text={t("views.workers.btn_create_worker")}
                onClick={() => setIsCreateModalVisible(true)}
              />
            </TourWrapper>
          )}
        </PerStyled.WorkspaceTitle>
      </WorkersStyled.ContentTop>
      <WorkersStyled.ContentBottom>
        <SearchBar
          onChange={(value) => dispatch(search(value, "active"))}
          onEnter={() => true}
          placeholder={t("views.workers.fields_placeholders.search")}
        />
        {!!filteredUsers.length || isSkeleton ? (
          <WorkersStyled.TableBlock>
            <Table
              data={[...filteredUsers]}
              cols={columnsDef}
              isSkeleton={isSkeleton}
              editable
              contextMenu={[
                {
                  name: t("views.workers.context_menu_labels.statistics"),
                  icon: <IconLineAndBarChart />,
                  group: 1,
                  onClick: onElementOpenStat,
                },
                {
                  name: t("views.workers.context_menu_labels.edit"),
                  icon: <IconEdit />,
                  group: 1,
                  onClick: onElementEdit,
                },
                {
                  name: t("views.workers.context_menu_labels.delete"),
                  icon: <IconTrash />,
                  group: 1,
                  onClick: onElementDelete,
                },
              ]}
            />
          </WorkersStyled.TableBlock>
        ) : (
          <>
            {!!loadedUsers.length ? (
              <EmptyPage
                variant={"NotFound"}
                header={t("views.workers.empty_page_not_found_header")}
                description={t("views.workers.empty_page_not_found_desc")}
              />
            ) : (
              <EmptyPage
                variant={"NotExists"}
                header={t("views.workers.empty_page_not_exists_header")}
                description={t("views.workers.empty_page_not_exists_desc")}
              />
            )}
          </>
        )}
      </WorkersStyled.ContentBottom>
    </>
  );
};

ActiveUsers.defaultProps = {
  desc: "Сотрудники",
};

ActiveUsers.propTypes = {
  columnsDef: PropTypes.array.isRequired,
  desc: PropTypes.string,
};

export default ActiveUsers;

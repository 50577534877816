import styled from "styled-components";
import { ModalButtonWrapper } from "../styled";

export const ModalPushSuccessWrapper = styled(ModalButtonWrapper)`
  margin: 50px auto 0;
`;

export const PushDisplayWrapper = styled.div`
  margin: 30px 0;
`;

import styled from "styled-components";

import IphoneX from "../../../assets/img/IphoneX.svg";
import qrCode from "../../../assets/img/qr.svg";

const cardWidth = "300px";

export const Display = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
`;

export const PhoneForm = styled.div`
  width: 389px;
  height: 757px;

  margin-left: 0.5px;

  padding: 30px;
  box-sizing: border-box;

  background: url(${IphoneX});
`;

export const SkeletonCardWrapper = styled.div`
  margin: 90px auto 0;
  width: 300px;
  border-radius: 10px;
`;

export const Card = styled.div`
  margin: 90px auto 0;

  border-radius: 10px;

  width: ${cardWidth};
  height: 465px;

  padding: 10px;
  box-sizing: border-box;

  background-color: ${({ bgColor, theme }) =>
    bgColor ? bgColor : theme.main["100%"]};

  color: ${({ textColor, theme }) =>
    textColor ? textColor : theme.white.primary};

  position: relative;
`;

export const Header = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogoPicture = styled.div`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};

  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  background-image: ${({ logo }) => `url(${logo})`};
`;

export const Name = styled.div`
  width: auto;
  height: auto;

  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: flex-end;

  font-weight: 400;
  font-size: 14px;
  line-height: 120%;

  span {
    font-weight: 300;
    font-size: 16px;
  }
`;

export const MainPicture = styled.div`
  position: absolute;
  top: 17%;
  left: 0;

  width: 100%;
  height: ${({ height }) => `${height}px`};

  background-size: ${({ height }) => `${cardWidth} ${height}px`};
  background-position: center center;
  background-repeat: no-repeat;

  background-image: ${({ main }) => `url(${main})`};
`;

export const StampsNGiftsBlock = styled.div`
  position: absolute;
  top: 42.5%;
  left: 0;

  width: 100%;
  height: 100px;

  display: flex;
  justify-content: space-between;
`;

export const StampsNGifts = styled.div`
  width: 100px;
  height: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StampsNGiftsTitle = styled.div`
  margin: 0 0 2.5px;

  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
`;

export const StampsNGiftsCount = styled.div`
  margin: 2.5px 0 0;

  font-weight: 500;
  font-size: 36px;
  line-height: 120%;
`;

export const QrCode = styled.div`
  position: absolute;
  bottom: 3.5%;
  left: 31.5%;

  width: 125px;
  height: 125px;

  background: url(${qrCode});
`;

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as SettingsStyled from "../../components/Personal/Settings/styled";
import * as PerStyled from "../../components/Personal/styled";
import PropTypes from "prop-types";
import { updateChainData } from "../../store/settings/actions";
import { addApiMessage } from "../../store/messageBar/actions";
import { useDispatch } from "react-redux";
import { validation } from "../../utils";
import { TagIconQuestion, TourWrapper } from "../../components";
import { stepsIds } from "../../components/Personal/Onboarding/Onboarding";
import { TextField, Button } from "@6tamp/uikit";
import * as GStyled from "../../components/styled";
import { useTranslation } from "react-i18next";
import i18n, { resources, IETFDictionary } from "../../localization/i18n";

const selectorItems = Object.keys(resources).map((tag, index) => ({
  id: index + 1,
  label: IETFDictionary[tag],
  tag,
}));

function getSelectorItem(langTag) {
  return selectorItems.find((el) => el.tag === langTag);
}

const ChainSettings = ({ data, updateData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const defaultLangSelectorItem = getSelectorItem(i18n.language);

  const formik = useFormik({
    initialValues: {
      chainName: "",
      countStamps: null,
      multipleStamps: false,
      instagram: "",
      language: defaultLangSelectorItem,
      language_label: defaultLangSelectorItem.label,
    },
    validationSchema: Yup.object({
      chainName: Yup.string()
        .nullable()
        .min(3, t("core.validation.chain.short"))
        .max(20, t("core.validation.chain.long"))
        .required(t("core.validation.is_required")),
      countStamps: Yup.number()
        .min(1, t("core.validation.min_value"))
        .max(1000, t("core.validation.max_value"))
        .nullable()
        .required(t("core.validation.is_required")),
      instagram: Yup.string()
        .min(2, t("core.validation.instagram.invalid"))
        .max(40, t("core.validation.instagram.invalid"))
        .nullable()
        .required(t("core.validation.is_required")),
      language: Yup.object({}).required(t("core.validation.is_required")),
      language_label: Yup.string().required(t("core.validation.is_required")),
    }),
    onSubmit: async (values) => {
      const newData = {};

      if (!!values.chainName) newData.title = values.chainName;
      if (!!values.countStamps) newData.maxStamps = values.countStamps;
      if (!!values.language) newData.language = values.language.tag;
      if (!!values.instagram) {
        let instagramURL = values.instagram.includes("https://")
          ? values.instagram
          : `https://www.instagram/${values.instagram}`;
        newData.instagram = new URL(instagramURL).pathname
          .split("/")
          .filter((v) => !!v)
          .pop();
      }
      newData.multipleStamps = values.multipleStamps;

      setLoading(true);
      const data = await updateChainData(newData);
      setLoading(false);
      data.result
        ? dispatch(addApiMessage("success", data.msg))
        : dispatch(addApiMessage("alert", data.msg));
      await updateData();
    },
  });

  useEffect(() => {
    formik.setFieldValue("chainName", data?.title ? data.title : "", false);
    formik.setFieldValue(
      "countStamps",
      data?.maxStamps ? data.maxStamps : "",
      false
    );
    formik.setFieldValue("multipleStamps", !!data?.multipleStamps);
    formik.setFieldValue(
      "instagram",
      data?.instagram ? data.instagram : "",
      false
    );
  }, [data]);

  useEffect(() => {
    i18n.changeLanguage(formik.values.language.tag);
  }, [formik.values.language]);

  return (
    <SettingsStyled.Form
      onSubmit={formik.handleSubmit}
      onChange={formik.handleChange}
    >
      <TourWrapper tourId={stepsIds[1]}>
        <SettingsStyled.FormTitle>
          <PerStyled.WorkspaceTitle>
            <h2>{t("views.settings.navbar_labels.chain_settings")}</h2>
          </PerStyled.WorkspaceTitle>
        </SettingsStyled.FormTitle>
        <SettingsStyled.FormContent>
          <SettingsStyled.InputItem>
            <SettingsStyled.InputLabel>
              {t("views.settings.fields_labels.chain_name")}
            </SettingsStyled.InputLabel>
            <TextField
              id={"chainName"}
              name={"chainName"}
              placeholder={t("views.settings.fields_labels.chain_name")}
              width={"full"}
              height={40}
              value={formik.values.chainName}
              state={validation.isError(formik, "chainName") ? "error" : ""}
              errorText={formik.errors.chainName}
            />
          </SettingsStyled.InputItem>
          <SettingsStyled.InputItem>
            <SettingsStyled.InputLabelWrapper>
              <SettingsStyled.InputLabel>
                {t("views.settings.fields_labels.count_stamps")}
              </SettingsStyled.InputLabel>
              <TagIconQuestion
                hintText={t("views.settings.hints.count_stamps")}
                role={"secondary"}
              />
            </SettingsStyled.InputLabelWrapper>
            <TextField
              id={"countStamps"}
              name={"countStamps"}
              placeholder={t("views.settings.fields_labels.count_stamps")}
              width={"full"}
              height={40}
              value={formik.values.countStamps}
              type={"number"}
              state={validation.isError(formik, "countStamps") ? "error" : ""}
              errorText={formik.errors.countStamps}
            />
          </SettingsStyled.InputItem>
          <div>
            <SettingsStyled.CheckboxWrapper>
              <PerStyled.Checkbox
                checked={formik.values.multipleStamps}
                onClick={() =>
                  formik.setFieldValue(
                    "multipleStamps",
                    !formik.values.multipleStamps,
                    false
                  )
                }
              />
              <SettingsStyled.CheckboxLabel>
                {t("views.settings.multiple_stamps")}
              </SettingsStyled.CheckboxLabel>
              <TagIconQuestion
                role={"secondary"}
                hintText={t("views.settings.hints.multiple_stamps")}
              />
            </SettingsStyled.CheckboxWrapper>
          </div>
          <SettingsStyled.InputItem>
            <SettingsStyled.InputLabelWrapper>
              <SettingsStyled.InputLabel>
                {t("core.inputs_defs.language.label")}
              </SettingsStyled.InputLabel>
              <TagIconQuestion
                hintText={t("views.settings.hints.language")}
                role={"secondary"}
              />
            </SettingsStyled.InputLabelWrapper>
            <PerStyled.Select
              value={formik.values.language}
              id={"language"}
              name={"language"}
              placeholder={t("core.inputs_defs.language.label")}
              items={selectorItems}
              state={validation.isError(formik, "language") ? "alert" : ""}
              onChange={({ value }) => {
                formik.setFieldValue("language", value, true);
                formik.setFieldValue("language_label", value.label, true);
              }}
            />
            {!!formik.errors.language_label && (
              <GStyled.ErrorMessage>
                {formik.errors.language_label}
              </GStyled.ErrorMessage>
            )}
          </SettingsStyled.InputItem>
          <SettingsStyled.InputItem>
            <SettingsStyled.InputLabel>
              {t("views.settings.fields_labels.instagram")}
            </SettingsStyled.InputLabel>
            <TextField
              id={"instagram"}
              name={"instagram"}
              placeholder={t("views.settings.fields_labels.instagram")}
              width={"full"}
              height={40}
              value={formik.values.instagram}
              state={validation.isError(formik, "instagram") ? "error" : ""}
              errorText={formik.errors.instagram}
            />
          </SettingsStyled.InputItem>
          <SettingsStyled.ButtonWrapper>
            <Button
              round={true}
              text={t("views.settings.btn_submit")}
              width={"full"}
              type={"submit"}
              isLoading={isLoading}
              disabled={!formik.dirty}
            />
          </SettingsStyled.ButtonWrapper>
        </SettingsStyled.FormContent>
      </TourWrapper>
    </SettingsStyled.Form>
  );
};

export default ChainSettings;

ChainSettings.propTypes = {
  data: PropTypes.object,
  updateData: PropTypes.func.isRequired,
};

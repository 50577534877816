export const getDimensions = async (file) => {
  const [fileWidth, fileHeight] = await new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = function() {
        const fileWidth = this.width;
        const fileHeight = this.height;
        resolve([fileWidth, fileHeight]);
      };
    };
  });

  return [fileWidth, fileHeight];
};

export const validateDimensions = async (file, validWidth, validHeight) => {
  const [fileWidth, fileHeight] = await getDimensions(file);

  const isFix = typeof validWidth === "number";

  return isFix
    ? fileWidth === validWidth && fileHeight === validHeight
    : validWidth[0] <= fileWidth &&
        fileWidth <= validWidth[1] &&
        validHeight[0] <= fileHeight &&
        fileHeight <= validHeight[1];
};

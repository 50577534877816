import {
  FILTER_CLIENTS,
  CLEAR_FILTER,
  WRAP_CLIENTS,
  GET_INFO,
  SET_LOADING,
  PREFILTER_CLIENTS,
  SET_SKELETON,
} from "./types";
import { produce } from "immer";
import filterClients from "../../utils/filterClients";
import { parseISO } from "date-fns";
import { addMaskSymbols } from "../../utils";

const initialState = {
  loadedClients: [],
  filteredClients: [],
  prefilteredClients: [],
  totalInfo: {
    stamps: 0,
    clients: 0,
  },
  isLoading: false,
  isSkeleton: false,
};

const clientsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  return produce(state, (draft) => {
    switch (type) {
      case GET_INFO:
        draft.totalInfo.stamps = draft.loadedClients.reduce(
          (sum, client) => sum + client.totalStamps,
          0
        );
        draft.totalInfo.clients = draft.loadedClients.length;
        return draft;

      case SET_LOADING:
        draft.isLoading = payload;
        return draft;

      case SET_SKELETON:
        draft.isSkeleton = payload;
        return draft;

      case FILTER_CLIENTS:
        draft.filteredClients = draft.prefilteredClients;
        return draft;

      case PREFILTER_CLIENTS:
        draft.prefilteredClients = filterClients(draft.loadedClients, payload);
        return draft;

      case CLEAR_FILTER:
        draft.filteredClients = draft.loadedClients;
        return draft;

      case WRAP_CLIENTS:
        draft.loadedClients = payload.map((client) => {
          return {
            id: client._id,
            phone: addMaskSymbols(client.phone),
            firstVisit: parseISO(client.firstVisit),
            lastVisit: parseISO(client.lastVisit),
            curStamps: client.curStamps,
            curGifts: client.curGifts,
            receivedGifts: Math.max(client.totalGifts - client.curGifts, 0),
            totalStamps: client.totalStamps,
          };
        });
        draft.filteredClients = draft.loadedClients;
        return draft;

      default:
        return draft;
    }
  });
};

export default clientsReducer;

import React, { useEffect, useState } from "react";
import * as Styled from "./styled";
import * as PerStyled from "../styled";
import * as GStyled from "../../styled";
import { apiService, getRoleIdByName, roles } from "../../../utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import { loadUsers, updateUser } from "../../../store/workers/actions";
import { useDispatch, useSelector } from "react-redux";
import { validation } from "../../../utils";
import { addApiMessage } from "../../../store/messageBar/actions";
import axios from "axios";
import PropTypes from "prop-types";
import { TextField, Button, Modal } from "@6tamp/uikit";
import { useTranslation } from "react-i18next";

const UpdateUserModal = ({ isOpen, close, values }) => {
  const { t } = useTranslation();
  const [locations, setLocations] = useState([]);
  const dispatch = useDispatch();
  const isLoading = useSelector(({ workers }) => workers.isLoading);

  const loadLocations = async (token) => {
    try {
      const { data } = await apiService.get("/location", {
        cancelToken: token,
      });
      setLocations(data.map(({ title, _id }) => ({ label: title, id: _id })));
    } catch (e) {}
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    loadLocations(source.token);

    return () => {
      source.cancel();
    };
  }, []);
  const formik = useFormik({
    initialValues: {
      type: { label: t(roles[0].labelId), id: roles[0].id },
      name: "",
      lastName: "",
      email: "",
      location: null,
      label: "",
    },
    validationSchema: Yup.object({
      type: Yup.object({})
        .typeError(t("core.validation.is_required"))
        .required(t("core.validation.is_required")),
      name: Yup.string().required(t("core.validation.is_required")),
      lastName: Yup.string().required(t("core.validation.is_required")),
      email: Yup.string()
        .email(t("core.validation.email.invalid"))
        .required(t("core.validation.is_required")),
      location: Yup.object()
        .typeError(t("core.validation.is_required"))
        .required(t("core.validation.is_required")),
      label: Yup.string().required(t("core.validation.is_required")),
    }),
    onSubmit: async (newValues) => {
      const { result, msg } = await dispatch(
        updateUser(values.id, {
          email: newValues.email,
          name: newValues.name,
          lastName: newValues.lastName,
          type: newValues.type.id,
          location: newValues.location.id,
        })
      );
      dispatch(addApiMessage(result ? "success" : "alert", msg));
      if (result) {
        dispatch(loadUsers());
      }
      close();
    },
  });

  useEffect(() => {
    const location = locations.find((v) => v.id === values.locationId);
    const initialValues = {
      type: {
        label: values.type,
        id: getRoleIdByName(values.type ?? roles[0].label, t),
      },
      name: values.name,
      lastName: values.lastName,
      email: values.email,
      location,
      label: location && location.label,
    };
    Object.keys(initialValues).forEach((key) =>
      formik.setFieldValue(key, initialValues[key])
    );
  }, [values, locations]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      title={t("views.workers.update_user_modal_title")}
    >
      <Styled.Form
        onSubmit={formik.handleSubmit}
        onChange={formik.handleChange}
      >
        <Styled.InputsContainer>
          <div>
            <PerStyled.Select
              value={formik.values.type}
              id={"type"}
              name={"type"}
              placeholder={t("views.workers.fields_placeholders.type")}
              items={roles.map((role) => ({
                label: t(role.labelId),
                id: role.id,
              }))}
              state={validation.isError(formik, "type") ? "alert" : ""}
              onChange={({ value }) => formik.setFieldValue("type", value)}
            />
            {validation.isError(formik, "type") && (
              <GStyled.ErrorMessage>{formik.errors.type}</GStyled.ErrorMessage>
            )}
          </div>
          <div>
            <PerStyled.Select
              value={formik.values.location}
              id="location"
              name="location"
              placeholder={t("views.workers.fields_placeholders.location")}
              items={locations}
              state={validation.isError(formik, "label") ? "alert" : ""}
              onChange={({ value }) => {
                formik.setFieldValue("location", value, true);
                formik.setFieldValue("label", value.label, true);
              }}
            />
            {validation.isError(formik, "label") && (
              <GStyled.ErrorMessage>{formik.errors.label}</GStyled.ErrorMessage>
            )}
          </div>
          <TextField
            id={"name"}
            value={formik.values.name}
            name={"name"}
            width={"full"}
            height={40}
            state={validation.isError(formik, "name") ? "error" : ""}
            placeholder={t("views.workers.fields_placeholders.name")}
            errorText={formik.errors.name}
          />
          <TextField
            id={"lastName"}
            value={formik.values.lastName}
            name={"lastName"}
            width={"full"}
            height={40}
            state={validation.isError(formik, "lastName") ? "error" : ""}
            placeholder={t("views.workers.fields_placeholders.last_name")}
            errorText={formik.errors.lastName}
          />
          <TextField
            id={"email"}
            value={formik.values.email}
            name={"email"}
            width={"full"}
            height={40}
            state={validation.isError(formik, "email") ? "error" : ""}
            placeholder={t("core.inputs_defs.email.label")}
            errorText={formik.errors.email}
          />
        </Styled.InputsContainer>
        <PerStyled.ModalButtonWrapper>
          <Button
            type={"submit"}
            width={"full"}
            round={true}
            text={t("views.workers.btn_update_user")}
            isLoading={isLoading}
          />
        </PerStyled.ModalButtonWrapper>
      </Styled.Form>
    </Modal>
  );
};
export default UpdateUserModal;

UpdateUserModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  values: PropTypes.object,
};

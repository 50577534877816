import styled from "styled-components";

export const PushDisplay = styled.div`
  margin: auto;

  width: 100%;
  max-width: 359px;
  height: 103px;

  border-radius: 13px;

  padding: 10px;
  box-sizing: border-box;

  background-color: rgba(245, 245, 245, 0.6);
`;

export const Header = styled.div`
  margin: 0 0 9px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #b6b6b6;

  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.31px;
`;

export const LeftSideWrapper = styled.div`
  max-width: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  margin: 0 0 0 6px;

  text-align: center;

  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  height: 16px;

  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const PushText = styled.div`
  word-break: break-word;

  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
`;

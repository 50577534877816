import { compareAsc, format, parseISO } from "date-fns";
import { Text } from "@consta/uikit/Text";
import i18n from "../localization/i18n";

const wrapLocationPaidDate = (value) => {
  if (value) {
    const date = parseISO(value);
    const text = format(date, "dd.MM.yyyy");
    switch (compareAsc(date, new Date())) {
      case -1:
        return <Text view={"alert"}>{text}</Text>;

      case 1:
        return <Text view={"success"}>{text}</Text>;

      default:
        break;
    }
  } else {
    return <Text view={"warning"}>{i18n.t("views.locations.not_paid")}</Text>;
  }
};

export default wrapLocationPaidDate;

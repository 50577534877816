import React from "react";
import * as Styled from "./styled";
import { routePaths } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import * as auth from "../../../store/auth/actions";

import SidebarLink from "../SidebarLink";

import { IconTeam } from "@consta/uikit/IconTeam";
import { IconSettings } from "@consta/uikit/IconSettings";
import { IconExit } from "@consta/uikit/IconExit";
import { IconGeo } from "@consta/uikit/IconGeo";
import { accountTypes } from "../../../utils";
import { IconLineAndBarChart } from "@consta/uikit/IconLineAndBarChart";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { accountType } = useSelector(({ auth }) => auth);
  const links = [
    {
      route: routePaths.statistics,
      icon: IconLineAndBarChart,
      label: t("links.statistics"),
      availableFor: [accountTypes.MANAGER, accountTypes.OWNER],
    },
    {
      route: routePaths.clients,
      icon: IconTeam,
      label: t("links.clients"),
      availableFor: [accountTypes.MANAGER, accountTypes.OWNER],
    },
    {
      route: routePaths.locations,
      icon: IconGeo,
      label: t("links.locations"),
      availableFor: [accountTypes.OWNER, accountTypes.MANAGER],
    },
    {
      route: routePaths.workers,
      icon: IconTeam,
      label: t("links.workers"),
      availableFor: [accountTypes.MANAGER, accountTypes.OWNER],
    },
    {
      route: routePaths.accountSettings,
      icon: IconSettings,
      label: t("links.settings"),
      availableFor: [accountTypes.OWNER],
    },
  ].filter(({ availableFor }) => availableFor.includes(accountType));

  return (
    <Styled.Sidebar sm={2}>
      <Styled.Content>
        <Styled.ContentHead>
          <Styled.Logo />
          <Styled.LinkBlock>
            {links.map(({ route, icon, label }, key) => (
              <SidebarLink route={route} icon={icon} label={label} key={key} />
            ))}
          </Styled.LinkBlock>
        </Styled.ContentHead>
        <Styled.ContentFooter>
          <SidebarLink
            route={routePaths.logout}
            icon={IconExit}
            label={t("links.logout")}
            onClick={() => dispatch(auth.logout())}
          />
        </Styled.ContentFooter>
      </Styled.Content>
    </Styled.Sidebar>
  );
};

export default Sidebar;
